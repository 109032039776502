

// types
import { DataManagementPage } from '../components/data-management/types';

//	--------------------------------------------------------------------------
//
//	C O N S T A N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

//	------------------------------------------------------------
//
//	when tasks are executed we set their refresh status, to
//	determine whether a refresh will be attempted if a token
//	was found to have expired.
//
//	------------------------------------------------------------

export enum RefreshDirective
{

	NO_REFRESH,
	REFRESH

} // RefreshDirective

//	------------------------------------------------------------
//
//	tasks that require access tokens are stored in a data structure,
//	so that if the tokens need to be refreshed the tasks can
//	be completed using the refreshed tokens.
//
//	------------------------------------------------------------

export enum TaskType
{

	NONE,
	UPDATE_SETTINGS,
	LOAD_SETTINGS,
	UPDATE_USER_SERVICE_TOKEN,
	MOVE_DATA_TO_STORAGE,
	GET_DATA_MANAGEMENT_JOBS,
	DELETE_NOTIFICATIONS,
	MARK_NOTIFICATIONS_READ,
	INITIATE_DM_SEARCH

} // TaskType

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	------------------------------------------------------------
//
//	store details of the current task
//
//	the parameters object can be any type defined within this
//	file, or a standard type. i.e. if the task type is
//	MOVE_DATA_TO_STORAGE, then the parameters object will be of
//	type MoveDataToStorage.
//
//	not all task types have associated parameters. the following
//	list of task types that require parameters should be
//	maintained:
//
//	TaskType			Parameter type
//	--------               	--------------
//
//	INITIATE_DM_SEARCH:		InitiateDMSearch
//	MOVE_DATA_TO_STORAGE:		MoveDataToStorage
//	UPDATE_SETTINGS:		UserSettings
//	UPDATE_USER_SERVICE_TOKEN:	UserServiceToken
//	DELETE_NOTIFICATIONS:		number[]
//	MARK_NOTIFICATIONS_READ:	number[]
//
//	------------------------------------------------------------

export interface CurrentTask
{

	taskType: TaskType;
	parameters?: any;

} // CurrentTask

//	------------------------------------------------------------
//
//	parameters for changing the data-management view. i.e. a
//	user has clicked on a namespace, a dataset, or to view the
//	files in a data-transfer job.
//
//	------------------------------------------------------------

export interface InitiateDMSearch
{

	namespace?: string;
	filename?: string;
	jobID?: string;
	dataset?: string;
	fileType?: string;
	namespaceList?: string[];
	pageDisplayed: DataManagementPage;
	showPage?: boolean;

} // InitiateDMSearch

//	------------------------------------------------------------
//
//	parameters for launching a data-management job, moving
//	data to a storage location.
//
//	------------------------------------------------------------

export interface MoveDataToStorage
{

	toStorageAreaUUID: string;
	toSite: string;
	toStorageIdentifier: string;
	lifetime: number;
	filesToMove: 	{
			namespace: string,
			name: string
			}[]

} // MoveDataToStorage

//	--------------------------------------------------------------------------
//
//	controls what happens when a task finds an expired token.
//
//	--------------------------------------------------------------------------

export interface TaskDirective
{

	refreshDirective: RefreshDirective,
	retryAfterRefresh?: boolean

} // TaskDirective

//	--------------------------------------------------------------------------
//
//	update user settings
//
//	--------------------------------------------------------------------------

export interface UserSettings
{

	darkMode? : boolean;
	language?: string;

} // UserSettings

//	--------------------------------------------------------------------------
//
//	update a user service token
//
//	--------------------------------------------------------------------------

export interface UserServiceToken
{

	serviceID: string;
	usingToken: boolean;
	username: string;
	userToken: string;

} // UserServiceToken
