
export enum DataManagementPage
{

	DataManagement,
	Namespace,
	Dataset,
	JobDetails,
	Collection

} // DataManagementPage
