import React, { useState, useEffect } from 'react';
import useLocalStorage from 'use-local-storage';
import '../../App.css';
import './data-management.css';
import CSS from 'csstype';
import '../../tools/search-results/search-results.css';
import { useTranslation } from "react-i18next";

// icons
import gearsIcon from '../../icons/gears.gif';
import jupyterIcon from '../../icons/jupyter.380.png';
import playIcon from '../../icons/play-square.512.png';
import siteIcon from '../../icons/data-centre.320.png';

// types
import { AccessToken, JobItemType, JobType, SiteStorageAreas, JupyterHUBs } from '../../utils/types';
import { DataManagementPage } from './types';

// types relating to tasks.
import { InitiateDMSearch } from '../../utils/tasks';
import { RefreshDirective } from '../../utils/tasks';
import { TaskType } from '../../utils/tasks';
import { CurrentTask } from '../../utils/tasks';
import { TaskDirective } from '../../utils/tasks';

// functions
import { APIPrefix } from '../../utils/functions';

// classes
import { ToolButtonType } from '../../tools/tool-button';
import ToolButton from '../../tools/tool-button';
import { CheckBox } from '../../tools/controls';
import DataManagementTable from './data-management-table';
import JobStatus from './job-status';
import SearchResultsFooter from '../../tools/search-results/search-results-footer';
import { DisplayFileSize } from '../../utils/functions';

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------
	
// get default user preferences..
const DEFAULT_DARK = window.matchMedia( '(prefers-color-scheme: dark)' ).matches;

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	------------------------------------------------------------
//
//	Displays details of the selected file(s)
//
//	------------------------------------------------------------

function DetailsPane( props:	{
				selectedItems:	{
						namespace: string,
						name: string,
						type: string,
						size: number
						}[],
				refreshTokens: any,
				storageAreas: SiteStorageAreas[],
				initiateComputeSearchEvent: any,
				setSites: any
				} )
{
	
	// storage locations where the data is located.
	const [sLocations, setLocations] = useState<	{
							identifier: string,
							associated_storage_area_id: string,
							replicas: string[]
							}[] >( [] );
							
	const [sRetrievingLocations, setRetrievingLocations] = useState< boolean >( true );
	
	// light/dark mode theme.
	const [sTheme, setTheme] = useLocalStorage( 'gateway_theme', DEFAULT_DARK ? 'dark' : 'light' );

	//	------------------------------------------------------------
	//
	//	An asynchronous function that gets a list of locations
	//	for a particular data item from the data-management API.
	//
	//	------------------------------------------------------------
  	
  	async function getLocations()
  	{

	
		try
		{

			var urlCommand: string = APIPrefix() + '/v1/data_management/locate_data?';
			
			// parameters.
			urlCommand = urlCommand +	'namespace=' + props.selectedItems[ 0 ].namespace + '&' +
							'name=' + props.selectedItems[ 0 ].name;

			try
			{
				
				const apiResult = await fetch( urlCommand,	{
										headers: {'Content-Type': 'application/json'},
										credentials: 'include'
										} );
				if (apiResult.status === 200)
				{
			
					// get storage locations.
					var locations:	{
							identifier: string,
							associated_storage_area_id: string,
							replicas: string[]
							}[] = [];
				
					const returnedJson = await apiResult.json();
					if (returnedJson.locations !== undefined)
						locations = returnedJson.locations;
						
					// get a list of unique sites for these locations.
					var sites: string[] = [];
					for ( var i: number = 0; i < locations.length; i++ )
						sites.push( getStorageLocationSite( { id: locations[ i ].associated_storage_area_id } ) );
					for ( var i: number = sites.length - 1; i >= 0; i-- )
					{
						var alreadyExists: boolean = false;
						for ( var j: number = 0; j < i; j++ )
							if (sites[ i ] === sites[ j ])
								alreadyExists = true;
						if (alreadyExists === true)
							sites.splice( i, 1 );
					}
					for ( var i: number = 0; i < sites.length - 1; i++ )
						for ( var j: number = i + 1; j < sites.length; j++ )
							if (sites[ i ] > sites[ j ])
							{
								var swap: string = sites[ j ];
								sites[ j ] = sites[ i ];
								sites[ i ] = swap;
							}
					props.setSites( sites );
					
					// update the state with the list of returned storage areas.
					setLocations( locations );
					
				}
				
				// if the return code is 401 then either the data-management token or the gateway-backend
				// token has expired. we should renew them.
				else if (apiResult.status === 401)
				{
					const taskDirective: TaskDirective =	{
										refreshDirective: RefreshDirective.REFRESH
										};
					props.refreshTokens( { taskDirective: taskDirective } );
				}
				
				else
				
					// if the return code is 404 then the Rucio DID was not found. if 403 then
					// no token could be found, so either we're not logged in or we're not authorised
					// to the data-management API.
					setLocations( [] );
				
			}
			catch (e)
			{
				console.log( e );
				setLocations( [] );
			}
			
      		}
      		catch (e)
      		{
			console.log(e);
			setLocations( [] );
		}
			
		// locations retrieved.
		setRetrievingLocations( false );
  	
  	} // getLocations

	//	------------------------------------------------------------
	//
	//	search the compute API for compute resources to process
	//	these data.
	//
	//	------------------------------------------------------------

	const initiateComputeSearchHandler = ( event: React.MouseEvent<HTMLButtonElement>, pToSite: string ) =>
	{

		props.initiateComputeSearchEvent(	{
							site: pToSite,
							serviceType: 'jupyterhub'
							} );

	} // initiateComputeSearchHandler

	//	------------------------------------------------------------
	//
	//	Loop through the list of storage areas, and get the
	//	site and storage identifier of the storage ID provided.
	//
	//	------------------------------------------------------------
  	
  	function getStorageLocationName( args:	{
  							id: string
  							} )
  	{
  	
  		var storageText: string = '{' + args.id + '}';
  		
  		for ( var siteID = 0; siteID < props.storageAreas.length; siteID++ )
  			for ( var storageAreaID = 0; storageAreaID < props.storageAreas[ siteID ].storage_areas.length; storageAreaID++ )
  			
  				// check if we've matched the ID, and update the text.
  				if (props.storageAreas[ siteID ].storage_areas[ storageAreaID ].storage_id == args.id)
  					storageText = props.storageAreas[ siteID ].site + ' -> ' +
  							props.storageAreas[ siteID ].storage_areas[ storageAreaID ].identifier;
  	
  		// return something.
  		return storageText;
  	
  	} // getStorageLocationName

	//	------------------------------------------------------------
	//
	//	Loop through the list of storage areas, and get the
	//	site  of the storage ID provided.
	//
	//	------------------------------------------------------------
  	
  	function getStorageLocationSite( args:	{
  							id: string
  							} )
  	{
  	
  		var siteText: string = '';
  		
  		for ( var siteID = 0; siteID < props.storageAreas.length; siteID++ )
  			for ( var storageAreaID = 0; storageAreaID < props.storageAreas[ siteID ].storage_areas.length; storageAreaID++ )
  			
  				// check if we've matched the ID, and update the text.
  				if (props.storageAreas[ siteID ].storage_areas[ storageAreaID ].storage_id == args.id)
  					siteText = props.storageAreas[ siteID ].site;
  	
  		// return something.
  		return siteText;
  	
  	} // getStorageLocationSite

	// add up the files.
	var totalSize: number = 0.0;
	for ( var i = 0; i < props.selectedItems.length; i++ )
		if (props.selectedItems[ i ].size >= 0)
			totalSize = totalSize + props.selectedItems[ i ].size;

	//	--------------------------------------------------------------------------
	//
	//	H O O K S
	//
	//	--------------------------------------------------------------------------

	//	------------------------------------------------------------
	//
	//	get storage locations for the selected data item.
	//
	//	------------------------------------------------------------
  	
  	useEffect	( () =>
		  	{
		  	
		  		// set retrieving flag.
		  		setRetrievingLocations( true );
		  		
		  		// get a list of location where this data item is stored.
		  		if (props.selectedItems.length === 1)
		  			if (props.selectedItems[ 0 ].type.toUpperCase() !== 'NAMESPACE')
				  		getLocations();
			  	else
			  	{
			  		setLocations( [] );
			  		props.setSites( [] );
			  	}
				
			}, [props.selectedItems]
			);

	//	------------------------------------------------------------
	//
	//	C O M P O N E N T   C O D E
	//
	//	------------------------------------------------------------

	return	(
	
		<div	id = 'scrollbox'
			style = {	{
					width: "100%",
					height: "100%",
					overflowY: "auto",
					display: "flex",
					flexDirection: "column"
					} } >
					
			<div	style = {{ flex: '0 0 auto' }} >
			{
				(
				props.selectedItems.length === 0 ?
				'' :
				props.selectedItems.length === 1 ?
				props.selectedItems[ 0 ].name + ':' :
				props.selectedItems.length.toString() + ' items selected:'
				) + ' ' + DisplayFileSize( { bytes: totalSize } )
			}
			</div>
			<div style = {{ marginTop: '10px', marginBottom: '10px', display: props.selectedItems.length === 1 ? 'flex' : 'none' }} >
				Currently located at:
			</div>
			<div style = {{  display: props.selectedItems.length === 1 ? 'flex' : 'none', flexDirection: 'column' }} >
			{
				sRetrievingLocations === false ?
				sLocations.map	(
							(item, index) =>
							(
							
							<div style = {{ marginLeft: '10px', display: 'flex', flexDirection: 'column' }} >
								<div style = {{ flex: '0 0 auto', marginTop: '10px' }} >
								{
									getStorageLocationName( { id: item.associated_storage_area_id } )
								}
								</div>
								<div style = {{ flex: '0 0 auto' }} >
									<button	type = "button"
											className = 'menu-button-text'
											title = "Process data"
											style = {{ color: sTheme === 'dark' ? 'white' : 'black', flex: '0 0', cursor: 'pointer', margin: '0px 0px 0px 20px', backgroundColor: 'transparent' }}
											onClick =	{
													(event) =>
													initiateComputeSearchHandler(	/* event = */ event,
																	/* pToSite = */ getStorageLocationSite( { id: item.associated_storage_area_id } ) )
													} >Process data</button>
								</div>
								<div style = {{ flex: '0 0 10px' }} />
							</div>
							
							)
							
						) :
				<div style = {{ marginLeft: '10px' }} >Retrieving.....</div>
			}
			</div>
			
		</div>
		
		)

} // DetailsPane

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function DataManagement( props:	{
							dataManagementTokenObtained: boolean,
							dataManagementJobs: JobType[],
							storageAreas: SiteStorageAreas[],
							refreshTokens: any,
							taskExecutor: any,
							jobsLoading: boolean,
							tabVisible: boolean,
							setInitiateDMSearch: any,
							initiateComputeSearchEvent: any,
							jupyterHUBs: JupyterHUBs[],
							launchNotebook: any
							} )
{

	// translation function
	const { t } = useTranslation();
	
	const [sNamespaceList, setNamespaceList] = useState< string[] >( [] );

  	// filter values.
  	const [sNamespaceValue, setNamespaceValue] = useState< string >( '' );
  	const [sFilterFilename, setFilterFilename] = useState< string >( '' );
  	
  	// store the filter values for the namespace page and the DID page.
  	const [sFilterFilenameNamespaces, setFilterFilenameNamespaces] = useState< string >( '' );
  	const [sFilterFilenameDIDs, setFilterFilenameDIDs] = useState< string >( '' );
  	
  	// filter the file type (file, dataset, or container).
  	const [sFilterFileType, setFilterFileType] = useState< string >( 'all' );
  	
  	// parameter values.
  	const [sParamsLifetime, setParamsLifetime] = useState< string >( '3600' );
  	
  	// current parameters for the results table.
  	const [sTableNamespace, setTableNamespace] = useState< string | undefined >( undefined );
  	const [sTableFilename, setTableFilename] = useState< string | undefined >( undefined );
  	const [sTableJobID, setTableJobID] = useState< string | undefined >( undefined );
  	const [sTableDataset, setTableDataset] = useState< string | undefined >( undefined );
  	const [sTableParamsLifetime, setTableParamsLifetime] = useState< number >( 3600 );
  	
  	// pop-up boxes maximised, or not?
  	const [sFilterMaximised, setFilterMaximised] = useState< boolean >( true );
  	const [sParametersMaximised, setParametersMaximised] = useState< boolean >( true );
  	const [sDetailsMaximised, setDetailsMaximised] = useState< boolean >( true );
  	const [sComputeMaximised, setComputeMaximised] = useState< boolean >( true );
  	const [sSelectedItems, setSelectedItems] = useState<	{
  								namespace: string,
  								name: string,
  								type: string,
  								size: number
  								}[] >( [] );
  	const [sDisplayDetailsPanel, setDisplayDetailsPanel] = useState< boolean >( false );
  	
  	// which page is currently displayed?
  	const [sPageDisplayed, setPageDisplayed] = useState< DataManagementPage >( DataManagementPage.DataManagement );
	
	// set icon size.
	const [sIconSize, setIconSize] = useState< string >( 'medium' );
	
	// hold the list of sites where a data item is stored.
	const [sDataItemSites, setDataItemSites] = useState< string[] >( [] );

	//	-------------------------------------------------
	//
	//	extract a list of JupyterHUB services for the
	//	given site.
	//
	//	-------------------------------------------------
		
	function getAssociatedServices( args:	{
						site: string,
						jupyterHUBs: JupyterHUBs[]
						} )
	{
	
		var jupyterHUBs:	{
					id: string,
					prefix: string,
					host: string,
					path: string,
					identifier: string
					}[] = [];
					
		// check if this site exists in the supplied list of sites.
		const index = args.jupyterHUBs.findIndex( (element) => element.site === args.site );
		if (index > -1)
			jupyterHUBs = args.jupyterHUBs[ index ].associated_services;
					
		// return something.
		return jupyterHUBs;
	
	} // getAssociatedServices

	//	------------------------------------------------------------
	//
	//	handler for changes to the input boxes.
	//
	//	------------------------------------------------------------
  	
  	const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.target;
  		
  		// update the state for any input boxes that have been changed.
  		if (inputBox.name === 'filename')
  		{
  			setFilterFilename( inputBox.value );
  			if (sPageDisplayed === DataManagementPage.DataManagement)
  				setFilterFilenameNamespaces( inputBox.value );
  			if (sPageDisplayed === DataManagementPage.Namespace)
  				setFilterFilenameDIDs( inputBox.value );
  		}
  		if (inputBox.name === 'lifetime')
  			setParamsLifetime( inputBox.value );
  	
  	} // inputHandler

	//	-------------------------------------------------
	//
	//	function that handles a change in the selected namespace on the search form
	//
	//	-------------------------------------------------
  	
  	const namespaceOnChangeHandler = function( args: { namespace: string } )
  	{
  	
  		setNamespaceValue( args.namespace );
  	
  	} // namespaceOnChangeHandler

	//	------------------------------------------------------------
	//
	//	Restrict input box entry to numbers only, plus . , + -
	//
	//	------------------------------------------------------------
  	
  	const numericKeysOnly = (event: React.KeyboardEvent<HTMLInputElement>) =>
  	{
  	
  		if ((event.charCode < 48 || event.charCode > 57) && (event.charCode < 43 || event.charCode > 46))
  			event.preventDefault();
  	
  	} // numericKeysOnly

	//	------------------------------------------------------------
	//
	//	Handler for lost-focus events on the input boxes.
	//
	//	------------------------------------------------------------
  	
  	const onBlurEvent = (event: React.FocusEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.target;
  	
  		if (inputBox.name === 'lifetime')
  		{
			
			// params.
			let lifetimeNumeric: number = 0.0;
			try
			{
				lifetimeNumeric = Number( sParamsLifetime );
			}
			catch (e)
			{
			}
  			setTableParamsLifetime( lifetimeNumeric );
  			 
  		}
  	
  	} // onBlurEvent

	//	------------------------------------------------------------
	//
	//	Handler for select box onChange event.
	//
	//	------------------------------------------------------------
	
	const onChangeSelectHandler = (event: React.ChangeEvent<HTMLSelectElement>) =>
	{

		const value = event.target.value;
		
		// raise an onChange event.
		namespaceOnChangeHandler( {namespace: value} );
				
	} // onChangeSelectHandler

	//	------------------------------------------------------------
	//
	//	Handler for div clicks.
	//
	//	------------------------------------------------------------
  	
  	const onClickDivHandler = (event: React.MouseEvent<HTMLDivElement>) =>
  	{

		const div: HTMLDivElement = event.currentTarget;
		
		var namespace: string | undefined = undefined;
		var filename: string = '';
		var namespaceList: string[] | undefined = undefined;
		var pageDisplayed: DataManagementPage = DataManagementPage.DataManagement;
			
		if (div.id === 'dataManagement')
		{
			filename = sFilterFilenameNamespaces;
			namespaceList = sNamespaceList;
		}
		if (div.id === 'namespace')
		{
			namespace = sTableNamespace;
			filename = sFilterFilenameDIDs;
			pageDisplayed = DataManagementPage.Namespace;
		}
		
		if (div.id === 'dataManagement' || div.id === 'namespace')
		{
		
			// populate parameters for the DM search.
			const dmQuery: InitiateDMSearch =	{
								namespace: namespace,
								filename: filename,
								fileType: (sPageDisplayed === DataManagementPage.DataManagement ? "all" : sFilterFileType),
								namespaceList: namespaceList,
								pageDisplayed: pageDisplayed
								};
			const currentTask: CurrentTask =	{
								taskType: TaskType.INITIATE_DM_SEARCH,
								parameters: dmQuery
								};
			const taskDirective: TaskDirective =	{
								refreshDirective: RefreshDirective.REFRESH,
								retryAfterRefresh: true
								};
				
			// run the data-management search.
			props.taskExecutor( 	{
						currentTask: currentTask,
						taskDirective: taskDirective
						} );
					
		}
			
		// click to start a JupyterHUB service.
		if (div.id.length > 8)
			if (div.id.slice( 0, 8 ) === 'jupyter_')
			{
			
				// get the site name, by checking for an underscore.
				var site: string = "";
				var identifier: string = div.id.slice( 8 - div.id.length );
				if (identifier.indexOf( '_' ) > -1)
				{
					site = identifier.slice( 0, identifier.indexOf( '_' ) );
					identifier = identifier.slice( identifier.indexOf( '_' ) - identifier.length + 1 );
				}
				else
				{
					site = identifier;
					identifier = '';
				}
				
				// convert the remaining identifier to the numeric hub index.
				var hubIndex: number = -1;
				try
				{
					hubIndex = Number( identifier );
				}
				catch
				{
				}
				
				// find the JupyterHUB item in the array.
				const siteIndex = props.jupyterHUBs.findIndex( (element) => element.site === site );
				if (siteIndex > -1 && hubIndex > -1)
				{
				
					const jupyterHUB:	{
								id: string,
								prefix: string,
								host: string,
								path: string,
								identifier: string,
								port: number
								} = props.jupyterHUBs[ siteIndex ].associated_services[ hubIndex ];
				  	console.log( "jupyterHUB:" );
				  	console.log( jupyterHUB );
  					// open the notebook url in a new tab.
  					var url: string = jupyterHUB.prefix + '://' + jupyterHUB.host;
  					if (jupyterHUB.port > -1)
  						url = url + ':' + jupyterHUB.port;
  					if (jupyterHUB.path !== '' && jupyterHUB.path !== undefined)
  						url = url + jupyterHUB.path;

  					if (jupyterHUB.identifier.toUpperCase().indexOf( '(EMBED)' ) === -1)
	  					window.open( url, '_blank', 'noreferrer')
	  				else
	  					props.launchNotebook( { url: url } );
				
				}
				
			
			}
		
	} // onClickDivHandler

	//	------------------------------------------------------------
	//
	//	Handler for changes to the radio buttons.
	//
	//	------------------------------------------------------------
  	
  	const onClickHandler = (event: React.MouseEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.currentTarget;
			
		// if we click a search button on the search form then open a new results tab.
		if (inputBox.name === "searchData")
		{
		
			// populate parameters for the DM search.
			const dmQuery: InitiateDMSearch =	{
								namespace: sNamespaceValue,
								filename: sFilterFilename,
								fileType: sFilterFileType,
								pageDisplayed: DataManagementPage.Namespace
								};
			const currentTask: CurrentTask =	{
								taskType: TaskType.INITIATE_DM_SEARCH,
								parameters: dmQuery
								};
			const taskDirective: TaskDirective =	{
								refreshDirective: RefreshDirective.REFRESH,
								retryAfterRefresh: true
								};
				
			// run the data-management search.
			props.taskExecutor( 	{
						currentTask: currentTask,
						taskDirective: taskDirective
						} );
						
		}
  	
  	} // onClickHandler

	//	------------------------------------------------------------
	//
	//	Handler for button clicks.
	//
	//	------------------------------------------------------------
  	
  	const onClickButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) =>
  	{

		const button: HTMLButtonElement = event.currentTarget;
			
		// minimise/maximise the filter box if the user clicks minimise/maximise.
		if (button.name === "minimiseFilter")
			setFilterMaximised( false );
		if (button.name === "maximiseFilter")
			setFilterMaximised( true );
			
		// minimise/maximise the parameters box if the user clicks minimise/maximise.
		if (button.name === "minimiseParameters")
			setParametersMaximised( false );
		if (button.name === "maximiseParameters")
			setParametersMaximised( true );
			
		// minimise/maximise the details box if the user clicks minimise/maximise.
		if (button.name === "minimiseDetails")
			setDetailsMaximised( false );
		if (button.name === "maximiseDetails")
			setDetailsMaximised( true );
			
		// minimise/maximise the compute box if the user clicks minimise/maximise.
		if (button.name === "minimiseCompute")
			setComputeMaximised( false );
		if (button.name === "maximiseCompute")
			setComputeMaximised( true );
		
	} // onClickButtonHandler

	//	------------------------------------------------------------
	//
	//	limit the number of characters in a string.
	//
	//	------------------------------------------------------------
	
	function limitChars( args:	{
					value: string,
					chars: number
					} )
	{
	
		if (args.value.length > args.chars)
			return args.value.slice( 0, args.chars ) + '.....';
		else
			return args.value;
	
	} // limitChars

	//	------------------------------------------------------------
	//
	//	An asynchronous function that loads the namespace data
	//	from the data-management API.
	//
	//	------------------------------------------------------------
  	
  	async function loadNamespaces()
  	{
							
		var urlCommand: string = APIPrefix() + '/v1/data_management/list_namespaces';

		try
		{
		
			const apiResult = await fetch( urlCommand,	{
									headers: {'Content-Type': 'application/json'},
									credentials: 'include'
									} );

			// Return code 200 means the API has run.
			if (apiResult.status === 200)
			{

				const returnedJson = await apiResult.json();

				// get namespaces list.
				var namespaceList: any = [];
				if (returnedJson.namespaces !== undefined)
					namespaceList = returnedJson.namespaces;

				// update the state with the list of returned sites.
				setNamespaceList( namespaceList );
			
			}
			
		}
		catch (e)
		{
		}
  	
  	} // loadNamespaces

	//	------------------------------------------------------------
	//
	//	Sets the state
	//
	//	------------------------------------------------------------
	
	function setState( args:	{
					pageDisplayed?: DataManagementPage,
					selectedItems?:	{
  								namespace: string,
  								name: string,
  								type: string,
  								size: number
  								}[],
					displayDetailsPanel?: boolean,
					iconSize?: string,
					tableNamespace?: string,
					tableFilename?: string,
					tableJobID?: string,
					tableDataset?: string,
					filterFilenameNamespaces?: string,
					filterFilenameDIDs?: string,
					filterFileType?: string,
					} )
	{
	
		if (args.pageDisplayed !== undefined)
			setPageDisplayed( args.pageDisplayed );
		if (args.selectedItems !== undefined)
			setSelectedItems( args.selectedItems );
		if (args.displayDetailsPanel !== undefined)
			setDisplayDetailsPanel( args.displayDetailsPanel );
		if (args.iconSize !== undefined)
			setIconSize( args.iconSize );
		if (args.tableNamespace !== undefined)
			setTableNamespace( args.tableNamespace );
		if (args.tableFilename !== undefined)
			setTableFilename( args.tableFilename );
		if (args.tableJobID !== undefined)
			setTableJobID( args.tableJobID );
		if (args.tableDataset !== undefined)
			setTableDataset( args.tableDataset );
		if (args.filterFilenameNamespaces !== undefined)
			setFilterFilenameNamespaces( args.filterFilenameNamespaces );
		if (args.filterFilenameDIDs !== undefined)
			setFilterFilenameDIDs( args.filterFilenameDIDs );
		if (args.filterFileType !== undefined)
			setFilterFileType( args.filterFileType );
	
	} // setState

	//	--------------------------------------------------------------------------
	//
	//	C O M P O N E N T S
	//
	//	--------------------------------------------------------------------------

	//	--------------------------------------------------------------------------
	//
	//	H O O K S
	//
	//	--------------------------------------------------------------------------

	//	------------------------------------------------------------
	//
	//	load namespaces when component loads.
	//
	//	------------------------------------------------------------
	
  	useEffect	( () =>
		  	{
				
				// load the namespace data from the data-management API.
				if (props.dataManagementTokenObtained === true)
					loadNamespaces();
				
			}, [props.dataManagementTokenObtained]
			);

	//	------------------------------------------------------------
	//
	//	populate data once the namespaces have loaded.
	//
	//	------------------------------------------------------------
	
  	useEffect	( () =>
		  	{
				
				// populate parameters for the DM search.
				const dmQuery: InitiateDMSearch =	{
									namespaceList: sNamespaceList,
									pageDisplayed: DataManagementPage.DataManagement
									};
				const currentTask: CurrentTask =	{
									taskType: TaskType.INITIATE_DM_SEARCH,
									parameters: dmQuery
									};
				const taskDirective: TaskDirective =	{
									refreshDirective: RefreshDirective.NO_REFRESH
									};
					
				// run the data-management search.
				props.taskExecutor( 	{
							currentTask: currentTask,
							taskDirective: taskDirective
							} );
				
			}, [sNamespaceList.length]
			);
			
	useEffect	( () =>
			{
			
				// unselect anything that's selected, and clear the list of sites for the selected data item.
				setSelectedItems( [] );
				setDisplayDetailsPanel( false );
				setDataItemSites( [] );
				
			
			}, [sPageDisplayed]
			);

	//	------------------------------------------------------------
	//
	//	C O M P O N E N T   C O D E
	//
	//	------------------------------------------------------------

	return	(
	
		<div	style = {	{
					flex: "1 1",
					height: "100%",
					display: "flex",
					flexDirection: "column"
					} } >
					
			<div	className = "navigation"
				style = {	{
						flex: '0 0 30px',
						width: '100%',
						display: 'flex',
						flexDirection: 'row'
						} } >
						
				<div	style = {{ flex: '0 0 13px', display: 'flex', alignItems: 'center' }}/>
				<div	style = {{ flex: '0 0 auto', height: 'auto', padding: '0px 10px 0px 10px', display: 'flex', alignItems: 'center', userSelect: 'none' }}
					id = "dataManagement"
					className = "navigation"
					onClick = {sPageDisplayed !== DataManagementPage.DataManagement ? onClickDivHandler : undefined}
					data-clickable = {sPageDisplayed !== DataManagementPage.DataManagement ? "T" : "F"}>
					{t( 'Data management' ).toUpperCase()}
				</div>
				<div	style = {{ flex: '0 0 auto', margin: '0px 5px 0px 5px', display: sPageDisplayed !== DataManagementPage.DataManagement ? 'flex' : 'none', alignItems: 'center', userSelect: 'none' }}
					className = "navigation">
					&gt;
				</div>
				<div	style = {{ flex: '0 0 auto', padding: '0px 10px 0px 10px', display: sPageDisplayed === DataManagementPage.Namespace || sPageDisplayed === DataManagementPage.Dataset || sPageDisplayed === DataManagementPage.JobDetails ? 'flex' : 'none', alignItems: 'center', userSelect: 'none' }}
					id = "namespace"
					className = "navigation"
					onClick = {sPageDisplayed !== DataManagementPage.Namespace ? onClickDivHandler : undefined}
					data-clickable = {sPageDisplayed !== DataManagementPage.Namespace ? "T" : "F"} >
					{t( 'Namespace' ).toUpperCase() + ": " + (sTableNamespace !== undefined ? sTableNamespace.toUpperCase() : "")}
				</div>
				<div	style = {{ flex: '0 0 auto', margin: '0px 5px 0px 5px', display: sPageDisplayed === DataManagementPage.Dataset || sPageDisplayed === DataManagementPage.JobDetails ? 'flex' : 'none', alignItems: 'center', userSelect: 'none' }}
					className = "navigation">
					&gt;
				</div>
				<div	style = {{ flex: '0 0 auto', padding: '0px 10px 0px 10px', display: sPageDisplayed === DataManagementPage.Dataset ? 'flex' : 'none', alignItems: 'center', userSelect: 'none' }}
					id = "dataset"
					className = "navigation"
					onClick = {sPageDisplayed !== DataManagementPage.Dataset ? onClickDivHandler : undefined}
					data-clickable = {sPageDisplayed !== DataManagementPage.Dataset ? "T" : "F"} >
					{t( 'Dataset' ).toUpperCase() + ": " + (sTableDataset !== undefined ? sTableDataset.toUpperCase() : "")}
				</div>
				<div	style = {{ flex: '0 0 auto', padding: '0px 10px 0px 10px', display: sPageDisplayed === DataManagementPage.JobDetails ? 'flex' : 'none', alignItems: 'center', userSelect: 'none' }}
					id = "jobDetails"
					className = "navigation"
					onClick = {sPageDisplayed !== DataManagementPage.JobDetails ? onClickDivHandler : undefined}
					data-clickable = {sPageDisplayed !== DataManagementPage.JobDetails ? "T" : "F"} >
					{t( 'Job Details' ).toUpperCase() + ": " + (sTableJobID !== undefined ? sTableJobID.toUpperCase() : "")}
				</div>
						
			</div>
			
			<div style = {{ flex: '0 0 15px' }}></div>
	
			<div	style = {	{
						flex: "1 1",
						width: "100%",
						display: "flex",
						flexDirection: "row"
						} } >
			
		    		<div	style = {{ flex: '0 0', display: 'flex', flexDirection: 'column', height: '100%' }}>
			
					{/*
					//
					//	minimised filter box. click once to expand.
					//
					*/}
				    	<button	className = "minimised-filter"
				    			name = "maximiseFilter"
				    			type = "button"
				    			onClick = {onClickButtonHandler}
				    			data-maximised = {sFilterMaximised === true ? "T" : "F"} >
				    	    	<div className = "flex-15px"></div>
				    		<div className = "maximise">&raquo;</div>
				    		<div className = "flex-row">
					    		<div className = "rotated-text-box">{t( "Quick search" )}</div>
					    	</div>
				    	</button>
				
					{/*
					//
					//	maximised filter box.
					//
					*/}
					<div	className = "search-form"
						data-maximised = {sFilterMaximised === true ? "T" : "F"}>
					
				    	    	<div className = "flex-15px"></div>
				    	    	<div className = "flex-row">
				    			<div className = "data-management-form-title">{t( "Quick search" )}</div>
				    			<div className = "flex-expanding"></div>
				    			<button	className = "minimise"
				    					name = "minimiseFilter"
				    					type = "button"
				    					onClick = {onClickButtonHandler}>&laquo;</button>
				    			<div style = {{ flex: '0 0 15px' }}></div>
			    			</div>
				    	    	<div className = "flex-15px"></div>
				    	    	
				    	    	{/*
				    	    	//
				    	    	//	namespace filter box
				    	    	//
				    	    	*/}
			    			<div className = "filter-combobox-container">
						    	<select	name = "lstNamespace"
						    			className = "listbox"
						    			multiple = {false}
						    			value = {sNamespaceValue}
						    			onChange = {onChangeSelectHandler}
						    			data-placeholder-shown = {sNamespaceValue === '' ? "T" : "F"}
						    			style = {{ lineHeight: '100%' }} >
						    		<option hidden value = "">{t("Select namespace")}</option>
						    		{
						    			sNamespaceList.map
						    			(
						    				item =>
						    				(
						    					<option	key = {item}
						    							label = {item}
						    							value = {item} > {item} </option>
						    				)
						    			)
						    		}
							</select> 
						</div>
				    	    	<div className = "flex-10px"></div>
				    	    	
				    	    	{/*
				    	    	//
				    	    	//	filename filter box
				    	    	//
				    	    	*/}
				    	    	<div className = "filter-textbox-container" >
		    	    				<input	className = "filter-textbox"
		    	    					type = "text"
		    	    					name = "filename"
		    	    					placeholder = {t("Filename")}
		    	    					onChange = {inputHandler}
		    	    					value = {sFilterFilename}
								maxLength = {255} />
				    	    	</div>
				    	    	<div className = "flex-10px"></div>
						
			    			{/*
			    			//
			    			//	search buttonNamespace
			    			//
			    			*/}
						<div className = "form-button-container">
				    	    		<ToolButton	key = {t("Search") + (props.tabVisible ? 't' : 'f')}
				    	    				name = "searchData"
				    	    				onClick = {onClickHandler}
				    	    				text = {t("Search")}
				    	    				type = {ToolButtonType.PRIMARY} />
				    	    	</div>
				    		
					</div>
					
					<div style = {{ flex: '0 0 15px' }} />
				
					{/*
					//
					//	minimised parameters box. click once to expand.
					//
					*/}
				    	<button	className = "minimised-filter"
				    			name = "maximiseParameters"
				    			type = "button"
				    			onClick = {onClickButtonHandler}
				    			data-maximised = {sParametersMaximised === true ? "T" : "F"} >
				    	    	<div className = "flex-15px"></div>
				    		<div className = "maximise">&raquo;</div>
				    		<div className = "flex-row">
					    		<div className = "rotated-text-box">{t("Parameters")}</div>
					    	</div>
				    	</button>
				
					{/*
					//
					//	maximised parameters box.
					//
					*/}
					<div	className = "search-form"
						data-maximised = {sParametersMaximised === true ? "T" : "F"}>
					
				    	    	<div className = "flex-15px"></div>
				    	    	<div className = "flex-row">
				    			<div className = "form-title">{t( "Parameters" )}</div>
				    			<div className = "flex-expanding"></div>
				    			<button	className = "minimise"
				    					name = "minimiseParameters"
				    					type = "button"
				    					onClick = {onClickButtonHandler}>&laquo;</button>
				    			<div style = {{ flex: '0 0 18px' }}></div>
			    			</div>
				    	    	<div className = "flex-15px"></div>
				    	    	
				    	    	{/*
				    	    	//
				    	    	//	staging seconds parameter box
				    	    	//
				    	    	*/}
				    	    	<div className = "filter-textbox-container" >
		    	    				<input	className = "filter-textbox"
		    	    					type = "text"
		    	    					name = "lifetime"
								onKeyPress = {numericKeysOnly}
		    	    					placeholder = {t("Lifetime [s]")}
		    	    					onChange = {inputHandler}
								onBlur = {onBlurEvent}
								maxLength = {50}
								value = {sParamsLifetime} />
				    	    	</div>
				    	    	<div className = "flex-10px"></div>
				    		
					</div>
					
					<div style = {{ flex: '0 0 15px' }} />
				
					{/*
					//
					//	minimised details box. click once to expand.
					//
					*/}
				    	<button	className = "minimised-filter"
				    			name = "maximiseDetails"
				    			type = "button"
				    			onClick = {onClickButtonHandler}
				    			data-maximised = {sDetailsMaximised === true || sDisplayDetailsPanel === false ? "T" : "F"} >
				    	    	<div className = "flex-15px"></div>
				    		<div className = "maximise">&raquo;</div>
				    		<div className = "flex-row">
					    		<div className = "rotated-text-box">{t("Details")}</div>
					    	</div>
				    	</button>
				
					{/*
					//
					//	maximised details box.
					//
					*/}
					<div	className = "search-form"
						data-maximised = {sDetailsMaximised === true && sDisplayDetailsPanel === true ? "T" : "F"}
						style = {{ flex: '1 1' }} >
					
				    	    	<div className = "flex-15px"></div>
				    	    	<div style = {{ display: 'flex', flexDirection: 'row', flex: '0 0' }} >
				    			<div className = "form-title">{t( "Details" )}</div>
				    			<div className = "flex-expanding"></div>
				    			<button	className = "minimise"
				    					name = "minimiseDetails"
				    					type = "button"
				    					onClick = {onClickButtonHandler}>&laquo;</button>
				    			<div style = {{ flex: '0 0 18px' }}></div>
			    			</div>
				    	    	<div className = "flex-10px"></div>
				    	    	
				    	    	<div	style = {	{
				    	    				flex: '1 1',
				    	    				display: 'flex',
				    	    				flexDirection: 'column',
				    	    				maxHeight: '100%'
				    	    				} } >
				    	    	
					    	    	<div	className = "data-management-scrollbox-container"
					    	    		style = {	{
					    	    				width: '279px',
					    	    				height: '0px',
					    	    				flex: '1 1 auto',
					    	    				margin: '2px 15px 10px 15px'
					    	    				} } >
					
								<DetailsPane	selectedItems = {sSelectedItems}
										refreshTokens = {props.refreshTokens}
										storageAreas = {props.storageAreas}
										initiateComputeSearchEvent = {props.initiateComputeSearchEvent}
										setSites = {setDataItemSites} />
								
							</div>
						
						</div>
				    	    	
				    	    	<div className = "flex-10px"></div>
				    		
					</div>
			    	
			    	</div>
				<div className = "transparent-vertical-separator"></div>
				
				{/*
				//
				//	run a search query with the given parameters, and display the results in a table.
				//
				*/}
				<div style = {{ flex: '3 3 0px', display: 'flex', flexDirection: 'column', paddingRight: '10px' }} >
					<DataManagementTable	paramsLifetime = {sTableParamsLifetime}
				    				refreshTokens = {props.refreshTokens}
				    				taskExecutor = {props.taskExecutor}
				    				storageAreas = {props.storageAreas}
				    				pageDisplayed = {sPageDisplayed}
								iconSize = {sIconSize}
								filterFileType = {sFilterFileType}
								setState = {setState}
								setInitiateDMSearch = {props.setInitiateDMSearch} />
					<div	style = {{ flex: '0 0 20px', display: sComputeMaximised === true && sDisplayDetailsPanel === true ? 'flex' : 'none' }} />
				
					{/*
					//
					//	minimised compute box. click once to expand.
					//
					*/}
				    	<button	className = "minimised-filter"
				    			name = "maximiseCompute"
				    			type = "button"
				    			onClick = {onClickButtonHandler}
				    			data-maximised = {sComputeMaximised === true || sDisplayDetailsPanel === false ? 'T' : 'F'}
				    			data-row = "T"
				    			style = {	{
				    					margin: '0px 0px 0px 0px',
				    					padding: '0px 15px 0px 0px',
				    					width: 'auto',
				    					flexDirection: 'row'
				    					} } >
				    		<div className = "flex-row">
					    		<div	className = "form-title"
					    			style = {{ whiteSpace: 'nowrap' }} >{t("Local processing systems")}</div>
				    		<div	className = "maximise"
				    			style = {{ transform: 'rotate(-90deg)', paddingTop: '0px', paddingBottom: '2px' }} >&raquo;</div>
					    	</div>
				    	</button>
				
					{/*
					//
					//	maximised compute box.
					//
					*/}
					<div	className = "search-form"
						data-maximised = {sComputeMaximised === true && sDisplayDetailsPanel === true ? 'T' : 'F'}
						style = {	{
								flex: '0 0 170px',
								flexDirection: 'column',
								width: '100%',
								marginLeft: '0px',
								marginRight: '5px'
								} } >
								
						<div	style = {{ flex: '0 0 10px' }} />
						<div	className = "form-title"
							style = {{ flex: '0 0 auto', display: 'flex', flexDirection: 'row', marginLeft: '0px' }} >
							<div	className = "form-title"
								style = {{ flex: '0 0 auto' }} >
								{t( 'Local processing systems' )}
							</div>
				    			<div	style = {{ flex: '1 1' }} />
				    			<button	className = "minimise"
				    					name = "minimiseCompute"
				    					type = "button"
				    					style = {{ transform: 'rotate(-90deg)', paddingTop: '7px', paddingBottom: '12px' }}
				    					onClick = {onClickButtonHandler}>&laquo;</button>
						</div>	
						<div	style = {{ flex: '0 0 10px' }} />
						<div	style = {	{
									flex: '1 1',
									display: 'flex',
									flexDirection: 'row',
									width: '100%',
									maxWidth: '100%'
									} } >
		
							<div	className = "data-management-scrollbox-container"
								style = {	{
										width: '0px',
										flex: '1 1 auto'
										} } >
							
								<div	id = "scrollbox"
									style = {	{
											width: "100%",
											height: "100%",
											overflowX: "auto",
											display: "flex",
											flexDirection: "row",
											alignItems: 'center'
											} } >
									<div style = {{ flex: '0 0 15px' }} />
									{
									sDataItemSites.map
									(
										( site, siteindex ) =>
										(
										<div	key = {siteindex}
											style = {{ flex: '0 0 ' + (110 + (getAssociatedServices( { site: site, jupyterHUBs: props.jupyterHUBs } ).length * 265)).toString() + 'px', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
											<div style = {{ flex: '0 0 80px', height: '85px', marginBottom: '25px', display: 'flex', flexDirection: 'column', textAlign: 'center' }} >
												<div style = {{ flex: '0 0 45px' }} >
													<img	src = {siteIcon}
														alt = ''
														width = '55'
														height = '55' />
												</div>
												<div style = {{ flex: '1 1', fontSize: '13pt' }} >
													{site}
												</div>
											</div>
											<div style = {{ flex: '0 0 10px', fontSize: '40px', marginBottom: '25px' }} >
												:
											</div>
											<div style = {{ flex: '0 0 10px' }} />
											{
											getAssociatedServices(	{
														site: site,
														jupyterHUBs: props.jupyterHUBs
														} ).map
											(
												( hub, hubindex ) =>
												(
												<div	key = {hubindex}
													className = "jupyter-hub-row"
													style = {	{
															flex: '0 0 250px',
															display: 'flex',
															flexDirection: 'row',
															margin: '0px 0px 25px 10px',
															borderRadius: '10px',
															cursor: 'pointer',
															alignItems: 'center'
															} }
													id = {'jupyter_' + site + '_' + hubindex.toString()}
													onClick = {onClickDivHandler}
													title = {hub.identifier !== undefined ? hub.identifier : '{No description}'} >
													<div style = {{ flex: '0 0 20px' }} />
													<div style = {{ flex: '0 0 80px', height: '60px', backgroundColor: 'white', textAlign: 'center', alignItems: 'center', margin: '10px 0px 10px 0px' }} >
														<img	style = {{ position: 'relative', left: '12px', top: '3px' }}
															src = {jupyterIcon}
															alt = ""
															width = "54" />
														<img	style = {{ position: 'relative', left: '-54px', top: '-31px' }}
															src = {playIcon}
															alt = ""
															width = "22" />
													</div>
													<div style = {{ flex: '0 0 10px' }} />
													<div style = {{ flex: '1 1', fontWeight: '800', margin: '10px 10px 10px 10px', display: 'flex', alignItems: 'center', whiteSpace: 'normal', fontSize: '11pt' }} >{hub.identifier !== undefined ? limitChars( { value: hub.identifier, chars: 25 } ) : '{No description}'}</div>
												</div>
												)
											)
											}
										</div>
										)

									)

									}
									<div style = {{ flex: '0 0 10px' }} />
									
								</div>
								
							</div>
							
						</div>
					</div>
				</div>
				<div className = "transparent-vertical-separator"></div>
				
				{/*
				//
				//	display a list of current data-management jobs.
				//
				*/}
				<JobStatus	dataManagementJobs = {props.dataManagementJobs}
						storageAreas = {props.storageAreas}
						taskExecutor = {props.taskExecutor}
						jobsLoading = {props.jobsLoading}
						initiateComputeSearchEvent = {props.initiateComputeSearchEvent}
						jupyterHUBs = {props.jupyterHUBs}
						launchNotebook = {props.launchNotebook} />
			
			</div>
		
		</div>
		
		)

} // DataManagement
