import React, { useState, useEffect } from 'react';
import '../../App.css';
import './user-management.css';
import CSS from 'csstype';
import { useTranslation } from "react-i18next";

// icons
import gearsIcon from '../../icons/gears.gif';

// types
import { AccessToken } from '../../utils/types';
import { UserManagementPage } from './types';

// functions
import { APIPrefix } from '../../utils/functions';

// classes
import { ToolButtonType } from '../../tools/tool-button';
import ToolButton from '../../tools/tool-button';
import UserManagementGroupList from './user-management-group-list';
import UserManagementResults from './user-management-results';
import UserManagementGroupEditor from './user-management-group-editor';
import UserManagementUserEditor from './user-management-user-editor';

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function UserManagement( props:	{
							tabVisible: boolean					
							} )
{

	// translation function
	const { t } = useTranslation();

  	// filter values.
  	const [sGroupValue, setGroupValue] = useState< string >( '' );
  	const [sFilterName, setFilterName] = useState< string >( '' );
  	
  	// current parameters for the results table.
  	const [sTableGroup, setTableGroup] = useState< string >( '' );
  	const [sTableName, setTableName] = useState< string >( '' );
  	const [sTableRenderCount, setTableRenderCount] = useState< number >( 0 );
  	const [sTableDisplayed, setTableDisplayed] = useState< boolean >( false );
  	
  	// pop-up boxes maximised, or not?
  	const [sFilterMaximised, setFilterMaximised] = useState< boolean >( true );
  	
  	// which page is currently displayed?
  	const [sPageDisplayed, setPageDisplayed] = useState< UserManagementPage >( UserManagementPage.UserManagement );

	//	-------------------------------------------------
	//
	//	move to the user profile page
	//
	//	-------------------------------------------------
  	
  	function displayPage( args:	{
  					displayPage: UserManagementPage,
  					userID?: number,
  					groupID?: number,
  					editing?: boolean
  					} )
  	{
  	
  		var userID: number = -1;
  		if (args.userID !== undefined)
  			userID = args.userID;
  	
  		var groupID: number = -1;
  		if (args.groupID !== undefined)
  			groupID = args.groupID;
  			
  		var editing: boolean = false;
  		if (args.editing !== undefined)
  			editing = args.editing;
  	
  		sSetUserProfileEditingEvent(	{
  						editing: (editing === true),
  						userID: userID
  						} );
  	
  		sSetGroupProfileEditingEvent(	{
  						editing: (editing === true),
  						groupID: groupID
  						} );
  						
  		setPageDisplayed( args.displayPage );
  	
  	} // displayPage

	//	-------------------------------------------------
	//
	//	function that handles a change in the selected group on the search form
	//
	//	-------------------------------------------------
  	
  	const groupOnChangeHandler = function( args: { group: string } )
  	{
  	
  		setGroupValue( args.group );
  	
  	} // groupOnChangeHandler

	//	------------------------------------------------------------
	//
	//	handler for changes to the input boxes.
	//
	//	------------------------------------------------------------
  	
  	const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.target;
  		
  		// update the state for any input boxes that have been changed.
  		if (inputBox.name === 'nameFilter')
  			setFilterName( inputBox.value );
  	
  	} // inputHandler

	//	------------------------------------------------------------
	//
	//	Handler for button clicks.
	//
	//	------------------------------------------------------------
  	
  	const onClickHandler = (event: React.MouseEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.currentTarget;
			
		// if we click a search button on the search form then open a new results tab.
		if (inputBox.name === "searchData")
		{
		
			//initiateDMSearch( 	{
			//			namespace: sNamespaceValue,
			//			filename: sFilterFilename
			//			} );
		
		}
  	
  	} // onClickHandler

	//	------------------------------------------------------------
	//
	//	Handler for button clicks.
	//
	//	------------------------------------------------------------
  	
  	const onClickButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) =>
  	{

		const button: HTMLButtonElement = event.currentTarget;
			
		// minimise/maximise the filter box if the user clicks minimise/maximise.
		if (button.name === "minimiseFilter")
			setFilterMaximised( false );
		if (button.name === "maximiseFilter")
			setFilterMaximised( true );
		
	} // onClickButtonHandler

	//	------------------------------------------------------------
	//
	//	Handler for div clicks.
	//
	//	------------------------------------------------------------
  	
  	const onClickDivHandler = (event: React.MouseEvent<HTMLDivElement>) =>
  	{

		const div: HTMLDivElement = event.currentTarget;
			
		if (div.id === "userManagement")
			setPageDisplayed( UserManagementPage.UserManagement );
		if (div.id === "userEditor")
			setPageDisplayed( UserManagementPage.UserEditor );
		if (div.id === "groupEditor")
			setPageDisplayed( UserManagementPage.GroupEditor );
		
	} // onClickDivHandler

	//	-------------------------------------------------
	//
	//	define an event that updates whether the group
	//	profile page is in edit mode or not.
	//
	//	-------------------------------------------------
	
	// the event handler runs in the child component, but is held here in state.
	const [sSetGroupProfileEditingEvent, setSetGroupProfileEditingEvent] = useState<any>( undefined );
			
	// this function is called when the child with the event handler renders, and sets the event-handler function in state.
	const setSetGroupProfileEditingEventHandler = (newFunction: any) =>
	{
	
		setSetGroupProfileEditingEvent( () => newFunction );
		
	} // setSetGroupProfileEditingEventHandler

	//	-------------------------------------------------
	//
	//	define an event that updates whether the user
	//	profile page is in edit mode or not.
	//
	//	-------------------------------------------------
	
	// the event handler runs in the child component, but is held here in state.
	const [sSetUserProfileEditingEvent, setSetUserProfileEditingEvent] = useState<any>( undefined );
			
	// this function is called when the child with the event handler renders, and sets the event-handler function in state.
	const setSetUserProfileEditingEventHandler = (newFunction: any) =>
	{
	
		setSetUserProfileEditingEvent( () => newFunction );
		
	} // setSetUserProfileEditingEventHandler

	//	--------------------------------------------------------------------------
	//
	//	C O M P O N E N T S
	//
	//	--------------------------------------------------------------------------

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------

	return	(
	
		<div style = {{ flex: '1 1', height: '100%', display: 'flex', flexDirection: 'column' }}>
		
			<div style = {{ flex: '0 0 30px', width: '100%', display: 'flex', flexDirection: 'row' }} className = "navigation">
				<div	style = {{ flex: '0 0 13px', display: 'flex', alignItems: 'center' }}/>
				<div	style = {{ flex: '0 0 auto', height: 'auto', padding: '0px 10px 0px 10px', display: 'flex', alignItems: 'center' }}
					id = "userManagement"
					className = "navigation"
					onClick = {sPageDisplayed !== UserManagementPage.UserManagement ? onClickDivHandler : undefined}
					data-clickable = {sPageDisplayed !== UserManagementPage.UserManagement ? "T" : "F"}>
					{t( 'User management' ).toUpperCase()}
				</div>
				<div	style = {{ flex: '0 0 auto', margin: '0px 5px 0px 5px', display: sPageDisplayed !== UserManagementPage.UserManagement ? 'flex' : 'none', alignItems: 'center' }}
					className = "navigation">
					&gt;
				</div>
				<div	style = {{ flex: '0 0 auto', padding: '0px 10px 0px 10px', display: sPageDisplayed === UserManagementPage.UserEditor || sPageDisplayed === UserManagementPage.AddUserGroups ? 'flex' : 'none', alignItems: 'center' }}
					id = "userEditor"
					className = "navigation"
					onClick = {sPageDisplayed === UserManagementPage.AddUserGroups ? onClickDivHandler : undefined}
					data-clickable = {sPageDisplayed === UserManagementPage.AddUserGroups ? "T" : "F"} >
					{t( 'User Profile' ).toUpperCase()}
				</div>
				<div	style = {{ flex: '0 0 auto', padding: '0px 10px 0px 10px', display: sPageDisplayed === UserManagementPage.GroupEditor || sPageDisplayed === UserManagementPage.AddGroupUsers ? 'flex' : 'none', alignItems: 'center' }}
					id = "groupEditor"
					className = "navigation"
					onClick = {sPageDisplayed === UserManagementPage.AddGroupUsers ? onClickDivHandler : undefined}
					data-clickable = {sPageDisplayed === UserManagementPage.AddGroupUsers ? "T" : "F"} >
					{t( 'Group detail' ).toUpperCase()}
				</div>
				<div	style = {{ flex: '0 0 auto', margin: '0px 5px 0px 5px', display: sPageDisplayed === UserManagementPage.AddUserGroups || sPageDisplayed === UserManagementPage.AddGroupUsers ? 'flex' : 'none', alignItems: 'center' }}
					className = "navigation">
					&gt;
				</div>
				<div	style = {{ flex: '0 0 auto', padding: '0px 10px 0px 10px', display: sPageDisplayed === UserManagementPage.AddUserGroups ? 'flex' : 'none', alignItems: 'center' }}
					className = "navigation" >
					{t( 'Add groups' ).toUpperCase()}
				</div>
				<div	style = {{ flex: '0 0 auto', padding: '0px 10px 0px 10px', display: sPageDisplayed === UserManagementPage.AddGroupUsers ? 'flex' : 'none', alignItems: 'center' }}
					className = "navigation" >
					{t( 'Add users' ).toUpperCase()}
				</div>
			</div>
			
			<div style = {{ flex: '0 0 15px' }}></div>
			
			{/*
			//
			//	----------------------------------------
			//
			//	USER MANAGEMENT PAGE
			//
			//	----------------------------------------
			//
			*/}
	
			<div style = {{ flex: '1 1', width: '100%', display: (sPageDisplayed === UserManagementPage.UserManagement ? 'flex' : 'none'), flexDirection: 'row' }}>
		
		    		<div	style = {{ flex: '0 0', display: 'flex', flexDirection: 'column', height: '100%' }}>
			
					{/*
					//
					//	minimised filter box. click once to expand.
					//
					*/}
				    	<button	className = "minimised-filter"
				    			name = "maximiseFilter"
				    			type = "button"
				    			onClick = {onClickButtonHandler}
				    			data-maximised = {sFilterMaximised === true ? "T" : "F"} >
				    	    	<div className = "flex-15px"></div>
				    		<div className = "maximise">&raquo;</div>
				    		<div className = "flex-row">
					    		<div className = "rotated-text-box">Filter</div>
					    	</div>
				    	</button>
				
					{/*
					//
					//	maximised filter box.
					//
					*/}
					<div	className = "search-form"
						data-maximised = {sFilterMaximised === true ? "T" : "F"}>
					
				    	    	<div className = "flex-15px"></div>
				    	    	<div className = "flex-row">
				    			<div className = "form-title">{t( "Filter" )}</div>
				    			<div className = "flex-expanding"></div>
				    			<button	className = "minimise"
				    					name = "minimiseFilter"
				    					type = "button"
				    					onClick = {onClickButtonHandler}>&laquo;</button>
				    			<div style = {{ flex: '0 0 15px' }}></div>
			    			</div>
				    	    	<div className = "flex-15px"></div>
				    	    	
				    	    	{/*
				    	    	//
				    	    	//	namespace filter box
				    	    	//
				    	    	*/}
			    			<div className = "filter-combobox-container">
							<UserManagementGroupList	/*key = {props.dataManagementTokenRefreshCount.toString() + props.gatewayBackendTokenRefreshCount.toString()}*/
											changeGroup = {groupOnChangeHandler}
											placeholderShown = {sGroupValue === ''}
											value = {sGroupValue} />
						</div>
				    	    	<div className = "flex-10px"></div>
				    	    	
				    	    	{/*
				    	    	//
				    	    	//	name filter box
				    	    	//
				    	    	*/}
				    	    	<div className = "filter-textbox-container" >
		    	    				<input	className = "filter-textbox"
		    	    					type = "text"
		    	    					name = "nameFilter"
		    	    					placeholder = {t("Name")}
		    	    					onChange = {inputHandler}
		    	    					value = {sFilterName}
								maxLength = {255} />
				    	    	</div>
				    	    	<div className = "flex-10px"></div>
						
			    			{/*
			    			//
			    			//	search button
			    			//
			    			*/}
						<div className = "form-button-container">
				    	    		<ToolButton	key = {t("Search") + (props.tabVisible ? 't' : 'f')}
				    	    				name = "searchData"
				    	    				onClick = {onClickHandler}
				    	    				text = {t("Search")}
				    	    				type = {ToolButtonType.PRIMARY} />
				    	    	</div>
				    		
					</div>
			    	
			    	</div>
				<div className = "transparent-vertical-separator"></div>
				
				{/*
				//
				//	run a search query with the given parameters, and display the results in a table.
				//
				*/}
				<UserManagementResults	key = {sTableGroup + sTableName}
							tableDisplayed = {sTableDisplayed}
							group = {sTableGroup}
							nameFilter = {sTableName}
							displayPage = {displayPage} />
				<div className = "transparent-vertical-separator"></div>
			
			</div>
			
			{/*
			//
			//	----------------------------------------
			//
			//	USER EDITOR PAGE
			//
			//	----------------------------------------
			//
			*/}
	
			<UserManagementUserEditor	/*key = 'a'*/
							pageDisplayed = {sPageDisplayed}
							setSetUserProfileEditingEventHandler = {setSetUserProfileEditingEventHandler}
							displayPage = {displayPage} />
			
			{/*
			//
			//	----------------------------------------
			//
			//	GROUP EDITOR PAGE
			//
			//	----------------------------------------
			//
			*/}
	
			<UserManagementGroupEditor	/*key = 'a'*/
							pageDisplayed = {sPageDisplayed}
							setSetGroupProfileEditingEventHandler = {setSetGroupProfileEditingEventHandler}
							displayPage = {displayPage} />
		
		</div>
		
		)

} // UserManagement
