import React, { useState, useEffect } from 'react';
import CSS from 'csstype';
import '../../App.css';
import './user-management.css';
import '../../tools/search-results/search-results.css';
import { useTranslation } from "react-i18next";

// images
import gearsIcon from '../../icons/gears.gif';
import eyeDark from '../../icons/eye-dark.512.png';
import tick from '../../icons/tick.512.png';

// functions
import { APIPrefix } from '../../utils/functions';

// classes
import { ToolButtonType } from '../../tools/tool-button';
import ToolButton from '../../tools/tool-button';

// types
import { UserDataType } from './types';
import { UserManagementPage } from './types';

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function UserManagementUsers( props:	{
							key: string,
							page: number,
							pageSize: number,
							rows: number,
							displayPage: any
							} )
{

	// translation function
	const { t } = useTranslation();

	// class-level constants.
  	const TABLE_COL: CSS.Properties =
  			{
  			padding: '3px 5px 3px 5px',
  			whiteSpace: 'nowrap'
  			}
	
	// user data.
	const [sUserData, setUserData] = useState< UserDataType[] >( [] );
							
	const [sColumns, setColumns] = useState< string[] >( [] );
	const [sLoadingComponent, setLoadingComponent] = useState< boolean >( true );

	//	------------------------------------------------------------
	//
	//	Only certain columns should be displayed.
	//
	//	------------------------------------------------------------
	
	function filterColumns( args:	{
					column: string
					} )
	{
	
		return (args.column !== 'id' && args.column != 'memberSince');
	
	} // filterColumns

	//	------------------------------------------------------------
	//
	//	Load the dataset when the component is rendered
	//
	//	------------------------------------------------------------

	async function loadData()
	{
	
		//try
		//{

		//	var urlCommand: string = APIPrefix() + '/v1/data_management/list_data?';
			
			// token.
		//	urlCommand = urlCommand +	'namespace=' + props.namespace;
		//	if (props.filename !== '')
		//		urlCommand = urlCommand + '&filename=' + props.filename;

		//	try
		//	{
				
		//		const apiResult = await fetch( urlCommand, {headers: {'Content-Type': 'application/json'}, credentials: 'include'} );
		//		if (apiResult.status === 200)
		//		{
				
		//			const returnedJson = await apiResult.json();

					// get data list.
		//			var dataList: { scope: string, name: string, did_type: string, bytes: number, length: number }[] = [];
		//			if (returnedJson.data_list !== undefined)
		//				dataList = returnedJson.data_list;
					
					// update the state with the list of returned data items.
		//			setDataList( dataList );
					
		//		}
				
				// if the return code is 401 then either the data-management token or the gateway-backend
				// token has expired. we should renew them.
		//		if (apiResult.status === 401)
		//			props.renewTokens( {} );
				
		//	}
		//	catch (e)
		//	{
		//		console.log( e );
		//	}
			
      		//}
      		//catch (e)
      		//{
		//	console.log(e);
		//}
		
		const userData: UserDataType[] = [];
		
		// calculate number of rows.
		var numberOfRows: number = props.pageSize;
		if (props.rows - (props.page * props.pageSize) < numberOfRows)
			numberOfRows = props.rows - (props.page * props.pageSize);
		
		for ( var userID: number = 0; userID < numberOfRows; userID++ )
		{
						
			var id: number = Math.floor( Math.random() * 1000000000 );	
			var username: string = '';
			var email: string = '';
			var name: string = '';
			var length: number = Math.floor( Math.random() * 5 ) + 3;
			for ( var i = 0; i < length; i++ )
			{
				var newChar = Math.floor( Math.random() * 26 );
				username = username + String.fromCharCode( 97 + newChar );
			}
			username = username + '-';
			length = Math.floor( Math.random() * 5 ) + 2;
			for ( var i = 0; i < length; i++ )
			{
				var newChar = Math.floor( Math.random() * 26 );
				username = username + String.fromCharCode( 97 + newChar );
			}
			length = Math.floor( Math.random() * 5 ) + 2;
			for ( var i = 0; i < length; i++ )
			{
				var newChar = Math.floor( Math.random() * 26 );
				email = email + String.fromCharCode( 97 + newChar );
			}
			email = email + '@';
			length = Math.floor( Math.random() * 5 ) + 2;
			for ( var i = 0; i < length; i++ )
			{
				var newChar = Math.floor( Math.random() * 26 );
				email = email + String.fromCharCode( 97 + newChar );
			}
			email = email + '.com';
			var newChar = Math.floor( Math.random() * 26 );
			name = name + String.fromCharCode( 65 + newChar ) + '. ';
			newChar = Math.floor( Math.random() * 26 );
			name = name + String.fromCharCode( 65 + newChar );
			length = Math.floor( Math.random() * 5 ) + 2;
			for ( var i = 0; i < length; i++ )
			{
				var newChar = Math.floor( Math.random() * 26 );
				name = name + String.fromCharCode( 97 + newChar );
			}
			const created: string = new Date( Date.now() + ((-365 + Math.floor( Math.random() * 365 )) * (24*60*60*1000)) ).toLocaleString();
			const endTime: string = new Date( Date.now() + ((Math.floor( Math.random() * 365 )) * (24*60*60*1000)) ).toLocaleString();
			const lastUpdate: string = new Date( Date.now() + ((-365 + Math.floor( Math.random() * 365 )) * (24*60*60*1000)) ).toLocaleString();
			
			var newUser: UserDataType =	{
							id: id,
							username: username,
							email: email,
							name: name,
							created: created,
							endTime: endTime,
							lastUpdate: lastUpdate,
							memberSince: '',
							status: 'active'
							};
			userData.push( newUser );
		
		}

		//
		//	extract a list of columns, and store them in state. this column list will be used when
		//	formatting the data into a table.
		//	-------------------------------------------------
		//
		
		var columns: string[] = [];
		
		// loop over all the rows.
		if (Array.isArray( userData ))
			userData.forEach
			(
				(element) =>
				{
				
					// get a list of keys for this object.
					var newColumns: string[] = Object.keys( element );
					
					// add these items if they don't already exist. we need to do this as a loop because we can't trust
					// that every row will have the same list of columns in it.
					for ( var i = 0; i < newColumns.length; i++ )
						if (columns.findIndex( element => element === newColumns[ i ] ) == -1)
							columns.push( newColumns[ i ] );
					
				}
			);
		else
		
			// get a list of keys for this object.
			columns = Object.keys( userData );
			
		// set state.
		setUserData( userData );
		setColumns( columns );
		setLoadingComponent( false );
		
	} // loadData

	//	------------------------------------------------------------
	//
	//	Handler for onClick events (buttons).
	//
	//	------------------------------------------------------------
  	
  	const onClickHandlerBtn = (event: React.MouseEvent<HTMLButtonElement>) =>
  	{
  	
  		const btn: HTMLButtonElement = event.currentTarget;

  		// if we've clicked on a button to download data.
  		if (btn.name.length > 16)
  			if (btn.name.slice( 0, 16 ) === "btnDownloadData_")
  			{
  			
  				// get the rest of the identifier, and convert to numeric.
				var rowID: string = btn.name.slice( 16 - btn.id.length );
  				let rowIDNumeric: number = -1;
  				try
  				{
  					rowIDNumeric = Number( rowID );
  				}
  				catch (e)
  				{
  				}
  				
  				// do something.......
  			
  			}
  			
  		// show the user profile page
  		if (btn.name === 'viewData_')
  		{
  			var userID: number = 0;
  			props.displayPage(	{
  						displayPage: UserManagementPage.UserEditor,
  						userID: userID
  						} );
  		}
  			
  	} // onClickHandlerBtn
	
	useEffect	( () =>
			{
			
				// run the query and load the data asynchronously.
				loadData();
			
			}, []
			)

	//	------------------------------------------------------------
	//
	//	C O M P O N E N T S
	//
	//	------------------------------------------------------------

	//	------------------------------------------------------------
	//
	//	A HTML component that contains the action buttons for each
	//	row.
	//
	//	------------------------------------------------------------

	function Actions( args: { tableRow: object, index: number } )
	{
  				
  		return	(
  			<div	key = {t('View') + '_' + args.index.toString()}
  				style = {{ display: "flex", flexDirection: "row", alignItems: "center", margin: "0px 0px 0px 0px" }}>
	    	    		<ToolButton	key = {t("View") + args.index.toString()}
	    	    				name = "viewData_"
	    	    				onClick = {onClickHandlerBtn}
	    	    				text = {t("View")}
	    	    				icon = {eyeDark}
	    	    				type = {ToolButtonType.TRANSPARENT} />
  			</div>
  			)

	} // Actions

	//	------------------------------------------------------------
	//
	//	Constructs a list of columns.
	//
	//	------------------------------------------------------------
	
	function TableColumns( )
	{
	
		return sColumns.map
		(
			(column: string) =>
			{
				return <col key = {column}/>
			}
		)
		
	} // TableColumns

	//	------------------------------------------------------------
	//
	//	Constructs a table header row from a list of column names.
	//
	//	------------------------------------------------------------
	
	function TableHeadings()
	{
	
		return	<>
		{
			sColumns.filter( (column) => filterColumns( { column: column } ) ).map
			(
				(column: string) =>
				{
					return <th style = {TABLE_COL} data-hidden = "true" key = {column}>{column}</th>
				}
			)
		}
		</>
	
	} // TableHeadings

	//	------------------------------------------------------------
	//
	//	A HTML component that takes a list of sources and renders
	//	them as a list of table rows.
	//
	//	------------------------------------------------------------
	
	function TableRows( args:	{
					userData: UserDataType[]
					} )
	{
		
		return	<>
		{
			args.userData.map
			(
				(item, rowIndex) =>
				(
					<tr	key = {'row_' + rowIndex.toString()}
						className = "search-results-table-row" >
						
						{
							sColumns.filter( (column) => filterColumns( { column: column } ) ).map
							(
								(column, colIndex) =>
								(
									<td	key = {'cell_' + rowIndex.toString() + '_' + colIndex.toString()}
										style = {TABLE_COL}>
										{
										column === 'status' && item[ column as keyof typeof item ] === 'active'
										?	<ToolButton	key = {t("Active") + '_' + rowIndex.toString() + colIndex.toString()}
								    	    				name = "status_"
								    	    				onClick = {undefined}
								    	    				text = {t("Active")}
								    	    				icon = {tick}
								    	    				type = {ToolButtonType.STATUS_ACTIVE} />
										:	item[ column as keyof typeof item ]
										}
									</td>
								)
							)
						}
						<td	key = {'action_' + rowIndex.toString()}
							style = {TABLE_COL}>
							<Actions	key = {rowIndex.toString()}
									tableRow = {item}
									index = {rowIndex} />
						</td>
						
					</tr>
				)
			)
		}
		</>

	} // TableRows

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------

	return	(
	    		
		<div className = "search-results-table-holder">
			
			<table	className = {sLoadingComponent === true ? "search-results-table-hide" : "search-results-table"} >
				<colgroup>
					{TableColumns()}
					<col key = "Actions_col"/>
				</colgroup>
				<thead>
					<tr className = "search-results-table-row-header">
						{TableHeadings()}
						<th style = {TABLE_COL}></th>
					</tr>
				</thead>
				<tbody>
					{
						TableRows(	{
								userData: sUserData
								} )
					}
				</tbody>
			</table>
			
			<div className = {sLoadingComponent === true ? "search-results-table-loading" : "search-results-table-loaded"}>
				<img	className = "animated-gears"
					src = {gearsIcon}
					alt = ""
					width = "60"
					height = "60" />
				Loading
			</div>
			
		</div>
		
		)

} // UserManagementUsers
