import React, { useState, useEffect } from 'react';
import useLocalStorage from 'use-local-storage';
import '../../App.css';
import './user-control.css';
import '../../tools/search-results/search-results.css';
import CSS from 'csstype';
import '../../i18n/i18n'
import { useTranslation } from 'react-i18next';

// functions
import { APIPrefix } from '../../utils/functions';

// types
import { NotificationType } from '../../utils/types';
import { DataManagementPage } from '../data-management/types';

// types relating to tasks.
import { InitiateDMSearch } from '../../utils/tasks';
import { RefreshDirective } from '../../utils/tasks';
import { TaskType } from '../../utils/tasks';
import { CurrentTask } from '../../utils/tasks';
import { TaskDirective } from '../../utils/tasks';

// images
import gearsIcon from '../../icons/gears.gif';
import binDark from '../../icons/bin-dark.256.png';
import binLight from '../../icons/bin-light.256.png';
import checkboxTicked from '../../icons/check-box-ticked.620.png';
import checkboxUnticked from '../../icons/check-box-unticked.620.png';
import unreadIconDark from '../../icons/unread-notification-dark.128.png';
import unreadIconLight from '../../icons/unread-notification-light.128.png';

// classes
import { AccessToken } from '../../utils/types';
import { ToolButtonType } from '../../tools/tool-button';
import ToolButton from '../../tools/tool-button';

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

// class-level constants.
const TABLE_COL: CSS.Properties =
		{
		padding: '3px 5px 3px 5px',
		whiteSpace: 'normal',
		textAlign: 'left'
		};
const TABLE_COL_CENTRED: CSS.Properties =
		{
		padding: '3px 5px 3px 5px',
		whiteSpace: 'nowrap',
		textAlign: 'center'
		};
	
// get default user preferences..
const DEFAULT_DARK = window.matchMedia( '(prefers-color-scheme: dark)' ).matches;

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	------------------------------------------------------------
//
//	display a list of appropriate action buttons for this
//	notification type.
//
//	------------------------------------------------------------

function Actions( props:	{
				notification: NotificationType,
				initiateComputeSearchEvent: any,
				taskExecutor: any
				} )
{

	//	------------------------------------------------------------
	//
	//	search the compute API for compute resources to process
	//	these data.
	//
	//	------------------------------------------------------------

	const initiateComputeSearchHandler = ( event: React.MouseEvent<HTMLButtonElement>, pToSite: string ) =>
	{

		props.initiateComputeSearchEvent(	{
							site: pToSite,
							serviceType: 'jupyterhub'
							} );

	} // initiateComputeSearchHandler

	//	------------------------------------------------------------
	//
	//	search the data-management API for the files being
	//	transferred by this job ID.
	//
	//	------------------------------------------------------------

	const initiateDMSearchHandler = ( event: React.MouseEvent<HTMLButtonElement>, pNamespace: string, pJobID: string ) =>
	{
		
		// populate parameters for the DM search.
		const dmQuery: InitiateDMSearch =	{
							namespace: (pNamespace !== null ? pNamespace : ''),
							jobID: (pJobID !== null ? pJobID : '' ),
							fileType: 'all',
							pageDisplayed: DataManagementPage.JobDetails,
							showPage: true
							};
		const currentTask: CurrentTask =	{
							taskType: TaskType.INITIATE_DM_SEARCH,
							parameters: dmQuery
							};
		const taskDirective: TaskDirective =	{
							refreshDirective: RefreshDirective.REFRESH,
							retryAfterRefresh: true
							};
			
		// run the data-management search.
		props.taskExecutor( 	{
					currentTask: currentTask,
					taskDirective: taskDirective
					} );

	} // initiateDMSearchHandler

	return	(
		<div style = {{ display: "flex", flexDirection: "row", alignItems: "center", margin: "0px 0px 0px 0px" }} >
			{
			props.notification.notificationType.toUpperCase() === 'DMR'
			?       <button	type = "button"
						className = 'menu-button-text'
						title = "View data"
						style = {{ color: 'white', flex: '0 0', cursor: 'pointer', margin: '0px', backgroundColor: 'transparent' }}
						onClick =	{
								(event) =>
								initiateDMSearchHandler(	/* event = */ event,
												/* pNamespace = */ props.notification.additionalInfo.namespace,
												/* pJobID = */ props.notification.additionalInfo.data_management_job_id )
								}
						data-align = "R">View data</button>
			:	<></>
			}
			<div	style = {{ flex: '0 0 10px' }} />
			{
			props.notification.notificationType.toUpperCase() === 'DMR'
			?	<button	type = "button"
						className = 'menu-button-text'
						title = "Process data"
						style = {{ color: 'white', flex: '0 0', cursor: 'pointer', margin: '0px', backgroundColor: 'transparent' }}
						onClick =	{
								(event) =>
								initiateComputeSearchHandler(	/* event = */ event,
												/* pToSite = */ props.notification.additionalInfo.to_site )
								}
						data-align = "R">Process data</button>
			:	<></>
			}
		</div>
		)

} // Actions

//	------------------------------------------------------------
//
//	Constructs a list of columns.
//
//	------------------------------------------------------------

function TableColumns( )
{

	return	<>
			<col style = {{ width: '25px' }} key = "delete" />
			<col key = "unread" />
			<col key = "selectCol" />
			<col key = "message" />
			<col key = "date" />
			<col key = "action" />
		</>
	
} // TableColumns

//	------------------------------------------------------------
//
//	Constructs a table header row from a list of column names.
//
//	------------------------------------------------------------

function TableHeadings(	props:	{
				sNotifications: NotificationType[],
				sSelectedRows: number[],
				setSelectedRows: any,
				sRenderCount: number,
				setRenderCount: any,
				sSelectAll: boolean,
				setSelectAll: any
				} )
{

	//	------------------------------------------------------------
	//
	//	Button handler for check box click events.
	//
	//	------------------------------------------------------------
	
	const checkChange = ( event: React.MouseEvent<HTMLButtonElement> ) =>
	{

		// invert the select all check box.
		const selectAll = !props.sSelectAll;
		props.setSelectAll( selectAll );

		// get the list of selected rows from state.
		var selectedRows: number[] = props.sSelectedRows;

		// loop over all the notifications, adding them to the selected-items list
		// if they're not already there.
		if (selectAll === true)
			for ( var i: number = 0; i < props.sNotifications.length; i++ )
				if (selectedRows.indexOf( props.sNotifications[ i ].notificationID ) === -1)
					selectedRows.push( props.sNotifications[ i ].notificationID );

		// or clear the selected rows list.
		if (selectAll === false)
			selectedRows = [];

		// update state.
		props.setSelectedRows( selectedRows );
		props.setRenderCount( props.sRenderCount + 1 );
				
	} // checkChange

	return	<>
			<th style = {TABLE_COL} data-hidden = "true" key = "delete" />
			<th style = {TABLE_COL} data-hidden = "true" key = "unread"></th>
			<th style = {TABLE_COL_CENTRED} data-hidden = "true" key = "selectCol">
				<button	name = "chkNotificationCol"
					type = "button"
					title = "Select/deselect all"
					className = "action-button"
					onClick = {checkChange} >
					<img	src =	{
								props.sSelectAll === true
								?	checkboxTicked
								:	checkboxUnticked
							}
						alt = ""
						width = "25" />
				</button>
				{/*<input	type = "checkbox"
					onChange = {undefined}
					id = 'selectAll'
					checked = {false} ></input>*/}
			</th>
			<th style = {TABLE_COL} data-hidden = "true" key = "message">Message</th>
			<th style = {TABLE_COL} data-hidden = "true" key = "date">Date</th>
			<th style = {TABLE_COL} data-hidden = "true" key = "action">Action</th>
		</>

} // TableHeadings

//	------------------------------------------------------------
//
//	A HTML component that takes a list of sources and renders
//	them as a list of table rows.
//
//	------------------------------------------------------------

function TableRows(	props:	{
				sNotifications: NotificationType[],
				setNotifications: any,
				sSelectedRows: number[],
				setSelectedRows: any,
				sRenderCount: any,
				setRenderCount: any,
				setSelectAll: any,
				taskExecutor: any,
				initiateComputeSearchEvent: any
				} )
{

	// which row is currently highlighted?
	const [sHighlightedRow, setHighlightedRow] = useState< number >( -1 );
	
	// light/dark mode theme.
	const [sTheme, setTheme] = useLocalStorage( 'gateway_theme', DEFAULT_DARK ? 'dark' : 'light' );

	//	------------------------------------------------------------
	//
	//	delete a single notification.
	//
	//	------------------------------------------------------------

	const deleteNotificationHandler = ( event: React.MouseEvent<HTMLButtonElement>, pNotificationID: number ) =>
	{

		// delete the selected row (if this row is selected).
		var selectedRows: number[] = props.sSelectedRows.slice();
		const selectedPos: number = props.sSelectedRows.indexOf( pNotificationID );
		if (selectedPos > -1)
			selectedRows.splice( selectedPos, 1 );

		// delete the selected notification.
		var notifications: NotificationType[] = props.sNotifications.slice();
		const notificationPos: number = props.sNotifications.findIndex( (item) => item.notificationID === pNotificationID );
		if (notificationPos > -1)
			notifications.splice( notificationPos, 1 );

		// call the end point to remove the notification.
		const notificationIDs: number[] =	[
	  						pNotificationID
	  						];
	  	const currentTask: CurrentTask =	{
							taskType: TaskType.DELETE_NOTIFICATIONS,
							parameters: notificationIDs
							};
		const taskDirective: TaskDirective =	{
							refreshDirective: RefreshDirective.REFRESH,
							retryAfterRefresh: true
							};
		props.taskExecutor(	{
					currentTask: currentTask,
					taskDirective: taskDirective
					} );

		// update state.
		props.setSelectedRows( selectedRows );
		props.setNotifications( notifications );
		props.setRenderCount( props.sRenderCount + 1 );

	} // deleteNotificationHandler

	//	------------------------------------------------------------
	//
	//	format a date that is in text format.
	//
	//	------------------------------------------------------------

  	function formatDate( args:	{
  					createdAt: string
  					} )
  	{

		const year: string = args.createdAt.slice( 0, 4 );
		const month: string = args.createdAt.slice( 5, 7 );
		const day: string = args.createdAt.slice( 8, 10 );
		const time: string = args.createdAt.slice( 11, 16 );
		const formattedDateTime: string = year + '-' + month + '-' + day + ' ' + time;

  		// return something.
  		return formattedDateTime;

  	} // formatDate

	//	------------------------------------------------------------
	//
	//	build the notification text depending upon the notificaiton
	//	type.
	//
	//	------------------------------------------------------------

  	function notificationText( args:	{
  						notification: NotificationType
  						} )
  	{

  		var textValue: string = args.notification.notificationText;

  		// DMR: data-management transfer request (READY).
  		if (args.notification.notificationType === 'DMR')
  			textValue = textValue + ': ' + args.notification.additionalInfo.description;

  		// return something.
  		return textValue;

  	} // notificationText

	//	------------------------------------------------------------
	//
	//	Row is now longer being hovered over.
	//
	//	------------------------------------------------------------
  	
  	const onMouseLeave = ( event: React.MouseEvent<HTMLTableRowElement> ) =>
  	{
  	
  		setHighlightedRow( -1 );
  	
  	} // onMouseLeave

	//	------------------------------------------------------------
	//
	//	Row is being hovered over.
	//
	//	------------------------------------------------------------
  	
  	const onMouseOver = ( event: React.MouseEvent<HTMLTableRowElement>, index: number ) =>
  	{
  	
  		setHighlightedRow( index );
  	
  	} // onMouseOver

	//	------------------------------------------------------------
	//
	//	Button handler for check box onChange events.
	//
	//	------------------------------------------------------------
	
	const rowCheckChange = ( event: React.MouseEvent<HTMLButtonElement>, pNotificationID: number ) =>
	{

		// get the list of selected rows from state.
		var selectedRows: number[] = props.sSelectedRows.slice();
		const pos: number = props.sSelectedRows.indexOf( pNotificationID );
		const ticked: boolean = (pos === -1);

		// remove the select-all checkbox in the row header.
		if (ticked === false)
			props.setSelectAll( false );

		// delete the row.
		if (pos > -1 && ticked === false)
			selectedRows.splice( pos, 1 );

		// add the row.
		if (pos === -1 && ticked === true)
			selectedRows.push( pNotificationID );

		// update state.
		props.setSelectedRows( selectedRows );
		props.setRenderCount( props.sRenderCount + 1 );
				
	} // rowCheckChange

	return	<>
	{
		props.sNotifications.map
		(
			(item, index) =>
			(
				<tr	key = {index}
					className = "search-results-table-row"
					onMouseOver = {(event) => onMouseOver( event, index )}
					onMouseLeave = {onMouseLeave} >
					<td style = {{...TABLE_COL_CENTRED, ...{width: '25px'}}} >
						<div style = {{ display: 'flex', flexDirection: 'row', width: '34px', alignItems: 'center' }} >
							<div style = {{ flex: '0 0 2px', height: '30px', backgroundColor: item.readFlag ? 'transparent' : 'red' }} />
							<div style = {{ flex: '0 0 8px', height: '30px' }} />
							{
							sHighlightedRow === index
							?	<button	name = {"btnDelete_" + index.toString()}
									type = "button"
									title = "Delete notification"
									style = {{display: 'flex', padding: '0px', cursor: 'pointer', border: 'none', backgroundColor: 'transparent'}}
									onClick =	{
												(event) =>
												deleteNotificationHandler(	/* event = */ event,
																/* pNotificationID = */ item.notificationID )
											} >
									<img	src = {sTheme === 'dark' ? binDark : binLight}
										alt = ""
										width = "25px"
										height = "25px" />
								</button>
							:	<div style = {{flex: '0 0 25px'}} ></div>
							}
						</div>
					</td>
					<td style = {{...TABLE_COL_CENTRED, ...{paddingTop: '10px', width: '25px'}}}>
						{
							item.readFlag === false
							?	<img	src = {sTheme === 'dark' ? unreadIconDark : unreadIconLight}
									alt = ""
									width = "25px" />
							:	<></>
						}
					</td>
					<td style = {{...TABLE_COL_CENTRED, ...{paddingTop: '10px', width: '25px'}}} >
						<button	name = {"chkNotification_" + index.toString()}
							type = "button"
							title = "Select/deselect"
							className = "action-button"
							onClick =	{
										(event) =>
										rowCheckChange(	/* event = */ event,
												/* pNotificationID = */ item.notificationID )
									} >
							<img	src =	{
										props.sSelectedRows.indexOf( item.notificationID ) === -1
										?	checkboxUnticked
										:	checkboxTicked
									}
								alt = ""
								width = "25px" />
						</button>
					</td>
					<td style = {{...TABLE_COL, ...{fontWeight: item.readFlag ? '500' : '800'}}}>
						{notificationText( { notification: item } )}
					</td>
					<td style = {{...TABLE_COL, ...{width: '180px'}}}>
						{formatDate( { createdAt: item.createdAt } )}
					</td>
					<td style = {TABLE_COL}>
						{Actions(	{
								notification: item,
								initiateComputeSearchEvent: props.initiateComputeSearchEvent,
								taskExecutor: props.taskExecutor
								} )}
					</td>
				</tr>
			)
		)
	}
	</>

} // TableRows

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function ViewNotifications( props: 	{
							renewTokens: any,
							tokensObtained: boolean,
							taskExecutor: any,
							notifications: NotificationType[],
							setNotifications: any,
							renderCount: number,
							setRenderCount: any,
							pageDisplayed: boolean,
							initiateComputeSearchEvent: any
							} )
{

	//	-------------------------------------------------
	//
	//	CONSTANTS.
	//
	//	-------------------------------------------------

	// multi-language support
	const { i18n, t } = useTranslation();

  	// filter box maximised, or not?
  	const [sFilterMaximised, setFilterMaximised] = useState<boolean>( true );

	// which rows are currently selected?
	const [sSelectedRows, setSelectedRows] = useState< number[] >( [] );
	const [sSelectAll, setSelectAll] = useState< boolean >( false );

  	// filter values.
  	const [sFilterNotification, setFilterNotification] = useState<string>( '' );
  	const [sFilterFromTime, setFilterFromTime] = useState<string>( '' );
  	const [sFilterToTime, setFilterToTime] = useState<string>( '' );

  	const [sLoadingComponent, setLoadingComponent] = useState<boolean>( false );

	//	------------------------------------------------------------
	//
	//	delete one or more notifications.
	//
	//	------------------------------------------------------------

	const deleteNotificationsHandler = ( event: React.MouseEvent<HTMLButtonElement> ) =>
	{

		if (sSelectedRows.length > 0)
		{

			// delete the selected notifications.
			var notifications: NotificationType[] = props.notifications.slice();
			for ( var i: number = 0; i < sSelectedRows.length; i++ )
			{
				const notificationPos: number = notifications.findIndex( (item) => item.notificationID === sSelectedRows[ i ] );
				if (notificationPos > -1)
					notifications.splice( notificationPos, 1 );
			}

			// call the end point to remove the notification.
		  	const currentTask: CurrentTask =	{
								taskType: TaskType.DELETE_NOTIFICATIONS,
								parameters: sSelectedRows
								};
			const taskDirective: TaskDirective =	{
								refreshDirective: RefreshDirective.REFRESH,
								retryAfterRefresh: true
								};
			props.taskExecutor(	{
						currentTask: currentTask,
						taskDirective: taskDirective
						} );

			// ensure the select-all checkbox is unchecked.
			setSelectAll( false );

			// update state.
			setSelectedRows( [] );
			props.setNotifications( notifications );
			props.setRenderCount( props.renderCount + 1 );

		}

	} // deleteNotificationsHandler

	//	------------------------------------------------------------
	//
	//	handler for changes to the input boxes.
	//
	//	------------------------------------------------------------
  	
  	const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.target;
  		
  		// update the state for any input boxes that have been changed.
  		if (inputBox.name === 'notificationFilter')
  			setFilterNotification( inputBox.value );
  	
  	} // inputHandler

	//	------------------------------------------------------------
	//
	//	Mark the listed notification IDs are READ in the database.
	//
	//	------------------------------------------------------------

	function markNotificationsRead( args:	{
						notificationIDs: number[]
						} )
	{

		// update the notifications to mark them as read.
		var notifications: NotificationType[] = props.notifications.slice();
		for ( var i: number = 0; i < args.notificationIDs.length; i++ )
		{
			const notificationPos: number = notifications.findIndex( (item) => item.notificationID === args.notificationIDs[ i ] );
			if (notificationPos > -1)
				notifications[ notificationPos ].readFlag = true;
		}

		// call the end point to mark the notifications as read.
		const notificationIDs: number[] =	args.notificationIDs;
	  	const currentTask: CurrentTask =	{
							taskType: TaskType.MARK_NOTIFICATIONS_READ,
							parameters: notificationIDs
							};
		const taskDirective: TaskDirective =	{
							refreshDirective: RefreshDirective.REFRESH,
							retryAfterRefresh: true
							};
		props.taskExecutor(	{
					currentTask: currentTask,
					taskDirective: taskDirective
					} );

		// update state.
		props.setNotifications( notifications );
		props.setRenderCount( props.renderCount + 1 );

	} // markNotificationsRead

	//	------------------------------------------------------------
	//
	//	Handler for button clicks.
	//
	//	------------------------------------------------------------
  	
  	const onClickButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) =>
  	{

		const button: HTMLButtonElement = event.currentTarget;
			
		// minimise/maximise the filter box if the user clicks minimise/maximise.
		if (button.name === "minimiseFilter")
			setFilterMaximised( false );
		if (button.name === "maximiseFilter")
			setFilterMaximised( true );
		if (button.name === "searchData")
		{

		}
		
	} // onClickButtonHandler

	//	------------------------------------------------------------
	//
	//	Handle changes in the selected rows.
	//
	//	------------------------------------------------------------

	function selectedRowsUpdated( args:	{
						selectedRows: number[]
						} )
	{

		// update state.
		setSelectedRows( args.selectedRows );
		props.setRenderCount( Math.random() );

	} // selectedRowsUpdated

	//	------------------------------------------------------------
	//
	//	H O O K S
	//
	//	------------------------------------------------------------

	// define a hook that checks if any selected notification IDs no longer exist
	// in the notifications state. if so, we need to remove them.
	useEffect	( () =>
			{

				// loop over the selected rows.
				var selectedRows: number[] = sSelectedRows.slice();
				var updated: boolean = false;
				for ( var i = selectedRows.length - 1; i >= 0; i-- )
				{

					// try to find this notification ID in the notifications list.
					const pos = props.notifications.findIndex( (item) => item.notificationID === selectedRows[ i ] );
					if (pos === -1)
					{
						selectedRows = selectedRows.splice( pos, 1 );
						updated = true;
					}

				}

				// possibly set the select-all checkbox in the row header.
				if (props.notifications.length === selectedRows.length && selectedRows.length > 0)
					setSelectAll( true );

				// set state.
				if (updated === true)
					setSelectedRows( selectedRows );

			}, [props.renderCount]
			)

	// define a hook that marks the displayed notifications as read once a time interval has expired.
	useEffect	( () =>
			{

				// set a timer to mark the notifications are read.
				if (props.pageDisplayed === true)
				{

					// build a list of notification IDs that have been displayed.
					var notificationIDs: number[] = [];
					for ( var i: number = 0; i < props.notifications.length; i++ )
						notificationIDs.push( props.notifications[ i ].notificationID );

					setTimeout	( () =>
							{

								markNotificationsRead(	{
											notificationIDs: notificationIDs
											} );

							}, 5000
							);

				}

			}, [props.pageDisplayed]
			)

	//	------------------------------------------------------------
	//
	//	C O M P O N E N T   C O D E
	//
	//	------------------------------------------------------------
	
	return	(
	
		<div style = {{ flex: '1 1', height: '100%', display: 'flex', flexDirection: 'row', backgroundColor: 'transparent' }}>
		
			{/* minimised Tools window */}
			<button	className = "minimised-filter"
				name = "maximiseFilter"
				type = "button"
				onClick = {onClickButtonHandler}
				data-maximised = {sFilterMaximised === true ? "T" : "F"}
				data-row = "T" >
				<div className = "flex-15px"></div>
				<div className = "maximise">&raquo;</div>
				<div className = "flex-row">
					<div className = "rotated-text-box">{t('Filter')}</div>
				</div>
			</button>
		
			{/* maximised Tools window */}
			<div className = "tool-form" data-maximised = {sFilterMaximised === true ? "T" : "F"}>
			
		    	    	<div className = "flex-15px"></div>
		    	    	<div className = "flex-row">
		    			<div className = "form-title">{t('Filter')}</div>
		    			<div className = "flex-expanding"></div>
		    			<button className = "minimise" name = "minimiseFilter" type = "button" onClick = {onClickButtonHandler}>&laquo;</button>
		    			<div style = {{ flex: '0 0 15px' }}></div>
	    			</div>
		    	    	<div className = "flex-15px"></div>
		    	    	
		    	    	{/*
		    	    	//
		    	    	//	notification filter box
		    	    	//
		    	    	*/}
		    	    	<div className = "filter-textbox-container" >
    	    				<input	className = "filter-textbox"
    	    					type = "text"
    	    					name = "notificationFilter"
    	    					placeholder = {t("Message")}
    	    					onChange = {inputHandler}
    	    					value = {sFilterNotification}
						maxLength = {255} />
		    	    	</div>
		    	    	<div className = "flex-10px"></div>

		    	    	{/*
		    	    	//
		    	    	//	from and to datetimes
		    	    	//
		    	    	*/}
		    	    	<div style = {{ display: 'flex', flexDirection: 'row', flex: '0 0', width: '279px', margin: '2px 15px 10px 15px',
		    	    			alignItems: 'center' }}>
			    	    	<div className = "filter-textbox-container" style = {{ flex: '1 1', margin: '0px' }} >
	    	    				<input	className = "filter-textbox"
	    	    					type = "text"
	    	    					name = "filterFromTime"
	    	    					placeholder = {"From time"}
	    	    					onChange = {inputHandler}
	    	    					value = {sFilterFromTime}
							maxLength = {32} />
			    	    	</div>
			    	    	<div style = {{ flex: '0 0 10px' }} />
			    	    	<div className = "filter-textbox-container" style = {{ flex: '1 1', margin: '0px' }} >
						<input	className = "filter-textbox"
							type = "text"
							name = "filterFromTime"
							placeholder = {"To time"}
							onChange = {inputHandler}
							value = {sFilterToTime}
							maxLength = {32} />
					</div>
				</div>
				<div className = "flex-10px"></div>
					
				{/*
				//
				//	search button
				//
				*/}
				<div className = "form-button-container">
					<ToolButton	key = {t("Search")}
							name = "searchData"
							onClick = {onClickButtonHandler}
							text = {t("Search")}
							type = {ToolButtonType.PRIMARY} />
				</div>

				<div className = "flex-15px"></div>
		    		
			</div>
			<div className = "transparent-vertical-separator"></div>

			<div	className = "view-notifications"
				style = {{ width: '100%', height: '100%', borderRadius: '10px', marginRight: '5px', display: 'flex', flexDirection: 'column' }} >
				
				<div	style = {{ height: '0px', flex: '1 1 auto' }} >
				
					<div	style = {{ width: '100%', height: '100%', overflowY: 'auto', display: 'flex', flexDirection: 'row' }} >

						<div style = {{ flex: '0 0 10px' }} />

						<div style = {{ flex: '1 1', display: 'flex', flexDirection: 'column' }} >

							<div	className = 'header-text'
								style = {{ flex: '0 0 45px', alignItems: 'center',
										display: 'flex', width: '100%' }} >All notifications
							</div>

							<div style = {{ flex: '1 1' }} >
		    		
								<div className = "search-results-table-holder" >
									
									<table	className = {sLoadingComponent === true ? "search-results-table-hide" : "search-results-table"} style = {{ width: '99%' }} >
										<colgroup>
											<TableColumns />
										</colgroup>
										<thead>
											<tr className = "search-results-table-row-header">
												<TableHeadings	sNotifications = {props.notifications}
														sSelectedRows = {sSelectedRows}
														setSelectedRows = {setSelectedRows}
														sRenderCount = {props.renderCount}
														setRenderCount = {props.setRenderCount}
														sSelectAll = {sSelectAll}
														setSelectAll = {setSelectAll} />
											</tr>
										</thead>
										<tbody>
											<TableRows	sNotifications = {props.notifications}
													setNotifications = {props.setNotifications}
													sSelectedRows = {sSelectedRows}
													setSelectedRows = {setSelectedRows}
													sRenderCount = {props.renderCount}
													setRenderCount = {props.setRenderCount}
													setSelectAll = {setSelectAll}
													taskExecutor = {props.taskExecutor}
													initiateComputeSearchEvent = {props.initiateComputeSearchEvent} />
										</tbody>
									</table>
									
									<div className = {sLoadingComponent === true ? "search-results-table-loading" : "search-results-table-loaded"}>
										<img	className = "animated-gears"
											src = {gearsIcon}
											alt = ""
											width = "60"
											height = "60" />
										Loading
									</div>
									
								</div>
			

							</div>

						</div>

						<div style = {{ flex: '0 0 10px' }} />
						
					</div>
					
				</div>

				<div	key = {props.renderCount}
					className = "view-notification-footer"
					style = {{ flex: '0 0 60px', alignItems: 'center', display: sSelectedRows.length > 0 ? 'flex' : 'none', flexDirection: 'row' }} >
					<div style = {{ flex: '0 0 15px' }} />
					<div 	className = "header-text-secondary"
						style = {{ flex: '0 0 auto' }} >
						{sSelectedRows.length.toString() + ' notification(s) selected:'}
					</div>
					<div style = {{ flex: '0 0 50px' }} />
					<div	style = {{ flex: '0 0 auto' }} >
						<ToolButton	key = {t("Delete")}
								name = "deleteNotifications"
								onClick = {deleteNotificationsHandler}
								icon = {binDark}
								text = {t("Delete")}
								type = {ToolButtonType.SECONDARY} />
					</div>
					<div style = {{ flex: '1 1' }} />
					<div style = {{ flex: '0 0 15px' }} />
				</div>

			</div>

		</div>

		)

} // ViewNotifications
