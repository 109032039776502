import {useEffect} from "react";
import { APIPrefix } from "../utils/functions";

const Notifications = (
    isSessionValid: boolean | undefined,
    incrementNotificationEventCount: () => void
) => {

    // Hook to open a notifications event source connection if the user has a valid
    // session.
    useEffect(() => {
        let eventSource: EventSource | null = null;
        let retryTimeout: ReturnType<typeof setTimeout> | null = null;
        let retryDelay = 1000;
        const maxDelay = 30000;

        const setupEventSource = () => {
            if (!isSessionValid || eventSource) {
                // Don't attempt connection if the session is invalid or an eventSource
                // already exists.
                return
            }

            eventSource = new EventSource(
                APIPrefix() + '/v1/notifications_sse_source',
                {withCredentials: true}
            );

            eventSource.onmessage = (event) => {
                if (event.data === "notifications") {
                    incrementNotificationEventCount();
                }
            };

            eventSource.onopen = () => {
                retryDelay = 1000; // Reset retry delay on successful connection
            };

            eventSource.onerror = (err) => {
                console.error(
                    `Notifications eventSource connection lost.\nerror: ${err}.`);
                if (eventSource) {
                    eventSource.close();
                    eventSource = null;

                    // Attempt to re-establish event source connection if disconnected.
                    retryTimeout = setTimeout(() => {
                        retryDelay = Math.min(retryDelay * 2, maxDelay);
                        console.log(
                            "re-establishing notifications eventSource connection...");
                        setupEventSource();
                    }, retryDelay);
                }
            };
        }

        setupEventSource();

        return () => {
            if (eventSource) {
                eventSource.close();
                eventSource = null;
            }
            if (retryTimeout) {
                clearTimeout(retryTimeout);
            }
        };
    }, [isSessionValid]);
};

export default Notifications;
