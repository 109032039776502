import React, { useState, useEffect } from 'react';
import '../../App.css';
import './user-control.css';
import CSS from 'csstype';
import { useTranslation } from "react-i18next";

// images
import padlockIcon from '../../icons/padlock.512.png';
import userAccountIcon from '../../icons/user.512.png';

// types
import { NotificationType } from '../../utils/types';

// classes
import Tool from '../../tools/tool';
//import UserControlLoginBox from './user-control-login-box';
import UserControlDropdownMenu from './user-control-dropdown-menu';

// functions
import { APIPrefix } from '../../utils/functions';
import Notifications from '../../services/NotificationService';

// types relating to tasks.
import { RefreshDirective } from '../../utils/tasks';
import { TaskDirective } from '../../utils/tasks';
import { TaskType } from '../../utils/tasks';
import { CurrentTask } from '../../utils/tasks';

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	------------------------------------------------------------
//
//	Displays the login button with a different name depending
//	upon whether we're logged in or not.
//
//	------------------------------------------------------------

function UserAccountButton( args:	{
					loggedIn: boolean | undefined,
					buttonHandler: any,
					username: string,
					initials: string,
					userDropdownMenuDisplayed: boolean,
					numNotifications: number
					} )
{

	// translation function
	const { t } = useTranslation();

	if (args.loggedIn === undefined)
		return	(
				<button className = "user-control-login" name = "btnLogin" type = "button" onClick = {args.buttonHandler}>
					<div className = "user-control-login-text">{t( 'Please wait' ) + '...'}</div>
					<div className = "user-control-login-icon"><img src = {padlockIcon} alt = "" width = "16px"></img></div>
				</button>
			)
	else if (args.loggedIn === false)
		return	(
				<button className = "user-control-login" name = "btnLogin" type = "button" onClick = {args.buttonHandler}>
					<div className = "user-control-login-text">{t( 'Log in' )}</div>
					<div className = "user-control-login-icon"><img src = {padlockIcon} alt = "" width = "16px"></img></div>
				</button>
			)
	else
		return	(
				<button className = "user-control-login" name = "btnUserAccount" type = "button" onClick = {args.buttonHandler}>
					<div	className = "user-control-login-text"
						data-menu-displayed = {args.userDropdownMenuDisplayed === true ? "T" : "F"} >{args.username}</div>
					<div className = "user-control-login-initials">{args.initials}</div>
					{
						args.numNotifications > 0
						?	<div	className = "user-control-notification-count"
								data-offset = "T"
								data-menu-displayed =	{
											args.userDropdownMenuDisplayed === true
											?	"T"
											:
												"F"
											} >	{
												args.numNotifications > 99
												?	"99+"
												:	args.numNotifications
												}</div>
						:	<></>
					}
					{/*<!-div className = "user-control-login-dropdown">{args.userDropdownMenuDisplayed ? "ᐱ" : "ᐯ"}</div>*/}
				</button>
			)

} // UserAccountButton

//	--------------------------------------------------------------------------
//
//	C O M P O N E N T   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function UserControl( props:	{
						buttonHandler: any,
						loggedIn: boolean | undefined,
						username: string,
						userDropdownMenuDisplayed: boolean,
						setUserDropdownMenuDisplayed: any,
						renewTokens: any,
						tokensObtained: boolean,
						taskExecutor: any,
						notifications: NotificationType[],
						setNotifications: any,
						renderCount: number,
						setRenderCount: any,
						initiateComputeSearchEvent: any,
						isSessionValid: boolean | undefined
						} )
{

	// translation function
	const { t } = useTranslation();
		
	// loop over all the characters of the username to extract the initials.
	let initials: string = "";
	let spaceLast: boolean = true;
	if (props.username !== null)
		for ( let index = 0; index < props.username.length; index++ )
		{
			if (spaceLast === true && initials.length < 3)
				initials += props.username.slice( index, index + 1 );
			spaceLast = (props.username.slice( index, index + 1 ) === " ");
		}
		
	const [sUsername, setUsername] = useState< string >( props.username !== null ? props.username : t( 'Unknown User' ) );
	const [sInitials, setInitials] = useState< string >( initials );
	const [sNotificationEventCount, setNotificationEventCount] = useState<number>(0);
	const [sIsFetchingNotifications, setIsFetchingNotifications] = useState<boolean>(false);


	//	-------------------------------------------------
	//
	//	refresh notifications
	//
	//	-------------------------------------------------
  	
  	async function getNotifications()
  	{

		try
		{

  			// call the /get_notifications endpoint here.
			const apiResult = await fetch( APIPrefix() + '/v1/get_notifications',
								{
								headers: {'Content-Type': 'application/json'},
								credentials: 'include'
								} );

			if (apiResult.status === 200)
			{

				const returnedJson = await apiResult.json();

				var notifications: NotificationType[] = [];

				// loop through the returned notifications, and add them to the array.
				for ( var i: number = 0; i < returnedJson.notifications.length; i++ )
				{

					var newNotification: NotificationType = {
										notificationID: returnedJson.notifications[ i ].notification_id,
										notificationType: returnedJson.notifications[ i ].notification_type,
										notificationText: returnedJson.notifications[ i ].notification_text,
										readFlag: returnedJson.notifications[ i ].read_flag === 1,
										additionalInfo: returnedJson.notifications[ i ].additional_info,
										createdAt: returnedJson.notifications[ i ].created_at
										};
					notifications.push( newNotification );

				}

				// update the state.
				props.setNotifications( notifications );
					
			}
				
			// if the return code is 401 then the gateway-backend token has expired. we should renew tokens.
			if (apiResult.status === 401)
			{
				const taskDirective: TaskDirective =	{
									refreshDirective: RefreshDirective.REFRESH
									};
				props.renewTokens( { taskDirective: taskDirective } );
			}
		
      		}
      		catch (e)
      		{
		}
  	
  	} // getNotifications

	//	-------------------------------------------------
	//
	//	count the number of notifications that are
	//	unread.
	//
	//	-------------------------------------------------

  	function numUnreadNotifications( args:	{
  						notifications: NotificationType[]
  						} )
  	{

  		var foundUnread: number = 0;
  		for ( var i: number = 0; i < args.notifications.length; i++ )
  			if (args.notifications[ i ].readFlag === false)
  				foundUnread = foundUnread + 1;

  		// return something.
  		return foundUnread;

  	} // numUnreadNotifications

	const incrementNotificationEventCount = () => {
		setNotificationEventCount((prevCount: number) => prevCount + 1);
	};

	const decrementNotificationEventCount = () => {
		setNotificationEventCount((prevCount: number) => Math.max(prevCount - 1, 0));
	};

	// Call the wrapper of the hook that creates an eventSource for notification events.
	Notifications(props.isSessionValid, incrementNotificationEventCount);

	// define a hook that loads the notifications from the back end (if we're logged in).
	useEffect(() => {
		if (props.tokensObtained === true)
			getNotifications();
	}, [ props.tokensObtained]);


	/* Hook to retrieve notifications and data management jobs. The counter
	 * sNotificationEventCount in combination with the flag sIsFetchingNotifications
	 * prevents race conditions from occurring by ensuring that each event received is
	 * processed and that events are processed sequentially. Note, the backend can only
	 * send one event each second to prevent it from overloading the frontend.
	 */
	useEffect(() => {
		if (sNotificationEventCount > 0 && !sIsFetchingNotifications) {
			setIsFetchingNotifications(true);
			getNotifications();
			const currentTask: CurrentTask = {
				taskType: TaskType.GET_DATA_MANAGEMENT_JOBS
			};
			const taskDirective: TaskDirective = {
				refreshDirective: RefreshDirective.REFRESH,
				retryAfterRefresh: true
			};
			props.taskExecutor({
				currentTask: currentTask,
				taskDirective: taskDirective
			});
			decrementNotificationEventCount();
			setIsFetchingNotifications(false);
		}
	}, [sNotificationEventCount, sIsFetchingNotifications]);

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------
		
	return	(
		<div	className = "user-control"
			data-dropdown-displayed = {props.userDropdownMenuDisplayed === true ? "T" : "F"}
			data-contains-notifications = {props.notifications.length > 0 ? "T" : "F"} >
			<div className = "flex-expander"></div>
			{
				UserAccountButton(	{
							loggedIn: props.loggedIn,
							buttonHandler: props.buttonHandler,
							username: props.username,
							initials: sInitials,
							userDropdownMenuDisplayed: props.userDropdownMenuDisplayed,
							numNotifications: numUnreadNotifications( { notifications: props.notifications } )
							} )
			}
			<UserControlDropdownMenu	sUserDropdownMenuDisplayed = {props.userDropdownMenuDisplayed}
							setUserDropdownMenuDisplayed = {props.setUserDropdownMenuDisplayed}
							buttonHandler = {props.buttonHandler}
							notifications = {props.notifications}
							setNotifications = {props.setNotifications}
							taskExecutor = {props.taskExecutor}
							sRenderCount = {props.renderCount}
							setRenderCount = {props.setRenderCount}
							initiateComputeSearchEvent = {props.initiateComputeSearchEvent} />
			<div style = {{flex: '0 0 8px'}} />
		</div>
		)

} // UserControl
