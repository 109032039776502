import React, { useState, useRef, Ref, useEffect } from 'react';
import '../../App.css';
import './data-management.css';
import CSS from 'csstype';
import '../../tools/search-results/search-results.css';
import { useTranslation } from "react-i18next";

// icons
import gearsIcon from '../../icons/gears.gif';
import fileLargeIcon from '../../icons/file.128.png';
import fileSmallIcon from '../../icons/file-small.128.png';
import fileDetailsIcon from '../../icons/file-details.128.png';
import fileIcon from '../../icons/file.512.png';
import datasetIcon from '../../icons/folder.256.png';
import containerIcon from '../../icons/cabinet.256.png';
import namespaceIcon from '../../icons/namespace.420.png';

// types
import { AccessToken, JobType } from '../../utils/types';
import { DataManagementPage } from './types';

// types relating to tasks.
import { RefreshDirective } from '../../utils/tasks';
import { TaskType } from '../../utils/tasks';
import { MoveDataToStorage } from '../../utils/tasks';
import { InitiateDMSearch } from '../../utils/tasks';
import { CurrentTask } from '../../utils/tasks';
import { TaskDirective } from '../../utils/tasks';

// functions
import { APIPrefix, DisplayFileSize } from '../../utils/functions';

// classes
import { ContextMenuType } from '../menus/context-menu';
import { ContextMenu } from '../menus/context-menu';

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	------------------------------------------------------------
//
//	Display a small sized icon for each item, with a single
//	row of details.
//
//	------------------------------------------------------------

function DataItemDetails( props:	{
					id: string,
					item: { scope: string, name: string, did_type: string, bytes: number, length: number },
					itemSelected: boolean,
					onClickHandler: any
					} )
{

	return	(
	
		<div	className = "data-management-item"
			style = {	{
					width: '100%',
					margin: '2px 2px 2px 2px',
					float: 'left',
					display: 'flex',
					flexDirection: 'column',
					} }
			id = {props.id}
			data-selected = {props.itemSelected ? "T" : "F"}
			onClick = {props.onClickHandler}
			onContextMenu = {props.onClickHandler} >
		
			<div	style = {	{
						flex: '0 0 auto',
						display: 'flex',
						flexDirection: 'row',
						margin: '0px 0px 0px 0px',
						fontSize: '12pt',
						alignItems: 'left'
						} } >
				<div style = {{ flex: '0 0 10px' }} />
				<Icon	item = {props.item}
					size = {40} />
				<div style = {{ flex: '0 0 10px' } } />
				<div	style = {	{
							whiteSpace: 'nowrap',
							flex: '1 1',
							alignItems: 'center',
							textAlign: 'left',
							display: 'flex',
							flexDirection: 'row'
							} } >
					{'name' in props.item ? props.item[ 'name' ] : ''}
				</div>
				<div style = {{ flex: '0 0 10px' } } />
				<div	style = {	{
							whiteSpace: 'nowrap',
							flex: '0 0 120px',
							alignItems: 'center',
							textAlign: 'left',
							display: 'flex',
							flexDirection: 'row'
							} } >
					{'did_type' in props.item ? props.item[ 'did_type' ].toUpperCase() : ''}
				</div>
				<div style = {{ flex: '0 0 10px' } } />
				<div	style = {	{
							whiteSpace: 'nowrap',
							flex: '0 0 120px',
							alignItems: 'center',
							textAlign: 'left',
							display: 'flex',
							flexDirection: 'row'
							} } >
					{'bytes' in props.item ? DisplayFileSize( { bytes: props.item[ 'bytes' ] } ) : '/'}
				</div>
			</div>
			
		</div>
	
		)

} // DataItemDetails

//	------------------------------------------------------------
//
//	Display a medium sized icon for each item.
//
//	------------------------------------------------------------

function DataItemMedium( props:	{
					id: string,
					item: { scope: string, name: string, did_type: string, bytes: number, length: number },
					itemSelected: boolean,
					onClickHandler: any
					} )
{

	return	(
	
		<div	className = "data-management-item"
			style = {	{
					width: '375px',
					margin: '2px 2px 2px 2px',
					float: 'left',
					display: 'flex',
					flexDirection: 'column',
					} }
			id = {props.id}
			data-selected = {props.itemSelected === true ? "T" : "F"}
			onClick = {props.onClickHandler}
			onContextMenu = {props.onClickHandler} >
		
			<div	style = {	{
						flex: '0 0 auto',
						display: 'flex',
						flexDirection: 'row',
						margin: '0px 0px 0px 0px',
						alignItems: 'left'
						} } >
				<div style = {{ flex: '0 0 10px' }} />
				<Icon	item = {props.item}
					size = {40} />
				<div	style = {	{
							flex: '1 1',
							display: 'flex',
							flexDirection: 'column',
							margin: '0px 5px 0px 5px',
							height: 'auto'
							} } >
					<div style = {{ flex: '1 1' }} />
					<div	style = {	{
								overflowWrap: 'anywhere',
								flex: '0 0 auto',
								fontSize: '12pt',
								alignItems: 'center',
								textAlign: 'left'
								} } >
						{'name' in props.item ? props.item[ 'name' ] : ''}
					</div>
					<div style = {{ flex: '1 1' }} />
				</div>
			</div>
			
		</div>
	
		)

} // DataItemMedium

//	------------------------------------------------------------
//
//	Display a large sized icon for each item.
//
//	------------------------------------------------------------

function DataItemLarge( props:	{
					id: string,
					item: { scope: string, name: string, did_type: string, bytes: number, length: number },
					itemSelected: boolean,
					onClickHandler: any
					} )
{

	return	(
	
		<div	className = "data-management-item"
			style = {	{
					width: '275px',
					margin: '2px 2px 2px 2px',
					float: 'left',
					display: 'flex',
					flexDirection: 'column',
					} }
			id = {props.id}
			data-selected = {props.itemSelected === true ? "T" : "F"}
			onClick = {props.onClickHandler}
			onContextMenu = {props.onClickHandler} >
		
			<div	style = {	{
						flex: '0 0 auto',
						display: 'flex',
						flexDirection: 'column',
						margin: '0px 0px 0px 0px',
						alignItems: 'center'
						} } >
				<div style = {{ flex: '0 0 10px' }} />
				<Icon	item = {props.item}
					size = {120} />
				<div	style = {	{
							overflowWrap: 'anywhere',
							flex: '0 0 auto',
							height: 'auto',
							fontSize: '12pt',
							alignItems: 'center',
							margin: '0px 5px 0px 5px',
							textAlign: 'left'
							} } >
					{'name' in props.item ? props.item[ 'name' ] : ''}
				</div>
				<div style = {{ flex: '0 0 10px' }} />
			</div>
			
		</div>
	
		)

} // DataItemLarge

//	------------------------------------------------------------
//
//	Display an icon and name for each data item.
//
//	------------------------------------------------------------

function DataItems( props:	{
				dataList: { scope: string, name: string, did_type: string, bytes: number, length: number }[],
				selectedItems: number[],
				iconSize: string,
				onClickHandler: any,
				onContextMenuClick: any,
				onContextMenuMouseEnter: any,
				contextMenuDefinition: ContextMenuType[],
				contextPos: { x: number, y: number }[],
				setContextPos: any,
				contextRow: number[],
				setContextRow: any,
				contextVisible: boolean[],
				setContextVisible: any,
				NUM_CONTEXT_MENUS: number,
				CONTEXT_MENU_WIDTH: number
				} )
{

	//	------------------------------------------------------------
	//
	//	constants
	//
	//	------------------------------------------------------------

	// translation function
	const { t } = useTranslation();
	
	// size of data window.
	const [sDataWindowWidth, setDataWindowWidth] = useState< number >( 0.0 );
	const [sDataWindowHeight, setDataWindowHeight] = useState< number >( 0.0 );

	//	------------------------------------------------------------
	//
	//	code for storing the size when the data window is resized.
	//
	//	------------------------------------------------------------
	
	// handle changes in size to the data window container.
	const resizeDataWindow =	React.useRef<ResizeObserver>
					(
						new ResizeObserver
						(
							(entries:ResizeObserverEntry[]) =>
							{
							
								// recalculate the number of tabs that we can display along the top. any other tabs
								// must be placed in a drop-down menu.
								if (entries[0].contentRect.width > 0 && entries[0].contentRect.height > 0)
								{
			  						setDataWindowWidth( entries[0].contentRect.width );
			  						setDataWindowHeight( entries[0].contentRect.height );
								}
								
							}
						)
					);

	// create a reference to the HTML Div element that contains the data items, so that we can monitor for changes in size.
	const dataWindow =	React.useCallback
				(
					(container: HTMLDivElement) =>
					{
						
						// check that this reference has actually been assigned to an element, and switch on observing.
    						if (container !== null)
        						resizeDataWindow.current.observe( container );
    							
						// When element is unmounted, ref callback is called with a null argument
						// => best time to cleanup the observer
    						else
        						if (resizeDataWindow.current)
            							resizeDataWindow.current.disconnect();
    							
					}, [resizeDataWindow.current]
				);

	//	------------------------------------------------------------
	//
	//	retrieves the scroll position of the container that
	//	holds the context menu.
	//
	//	------------------------------------------------------------
  	
  	function getContainerScrollPosition()
  	{
  	
  		var containerScrollPosition: number = 0.0;
	  		
		// get the DIV element with the vertical scroll bars, and find the scroll position.
		const id = 'scrollbox';
		const scrollDiv: HTMLDivElement | null = document.querySelector(`#${id}`);
		if (scrollDiv !== null)
			containerScrollPosition = scrollDiv.scrollTop;
  	
  		// return something.
  		return containerScrollPosition
  	
  	} // getContainerScrollPosition

	//	------------------------------------------------------------
	//
	//	C O M P O N E N T   C O D E
	//
	//	------------------------------------------------------------

	return	(
	
		<div	ref = {dataWindow} style = {{ height: '100%' }}
			className = "data-management-items"
			id = 'container'
			onClick = {props.onClickHandler}
			onContextMenu = {props.onClickHandler} >
			
			{/* add the list of files, datasets, etc */}
			{
				props.dataList.map( (item, index) =>
				(
					props.iconSize === 'list'
					?	<DataItemDetails	key = {index}
									id = {'itmData_' + index.toString()}
									item = {item}
									itemSelected = {props.selectedItems.indexOf( index ) > -1}
									onClickHandler = {props.onClickHandler} />
					:	props.iconSize === 'medium'
						?	<DataItemMedium	key = {index}
										id = {'itmData_' + index.toString()}
										item = {item}
										itemSelected = {props.selectedItems.indexOf( index ) > -1}
										onClickHandler = {props.onClickHandler} />
						:	<DataItemLarge		key = {index}
										id = {'itmData_' + index.toString()}
										item = {item}
										itemSelected = {props.selectedItems.indexOf( index ) > -1}
										onClickHandler = {props.onClickHandler} />
				) )
			}
			
			{/* add the context menus */}
			{
				props.contextMenuDefinition.map
				(
					(item, index) =>
					(
						<ContextMenu	key = {index}
								contextMenuDefinition = {props.contextMenuDefinition}
								menuID = {index}
								onClick = {props.onContextMenuClick}
								onMouseEnter = {props.onContextMenuMouseEnter}
								dataWindowWidth = {sDataWindowWidth}
								dataWindowHeight = {sDataWindowHeight}
								containerScrollPosition = {getContainerScrollPosition()}
								contextPos = {props.contextPos}
								setContextPos = {props.setContextPos}
								contextRow = {props.contextRow}
								setContextRow = {props.setContextRow}
								contextVisible = {props.contextVisible}
								setContextVisible = {props.setContextVisible}
								numContextMenus = {props.NUM_CONTEXT_MENUS}
								contextMenuWidth = {props.CONTEXT_MENU_WIDTH} />
					)
				)
			}
			
		</div>

		)

} // DataItems

//	------------------------------------------------------------
//
//	Display icon
//
//	------------------------------------------------------------

function Icon( args:	{
			item: any,
			size: number
			} )
{

	if ('did_type' in args.item)
	{
		const did_type: string = args.item[ 'did_type' ].toUpperCase();
		switch (did_type)
		{
			case "DATASET":
				return	(
					<img src = {datasetIcon} alt = "" width = {args.size} height = {args.size} />
					)
				break;
			case "FILE":
				return	(
					<img src = {fileIcon} alt = "" width = {args.size} height = {args.size} />
					)
				break;
			case "CONTAINER":
				return	(
					<img src = {containerIcon} alt = "" width = {args.size} height = {args.size} />
					)
				break;
			case "NAMESPACE":
				return	(
					<img src = {namespaceIcon} alt = "" width = {args.size} height = {args.size} />
					)
				break;
			default:
				return	(
					<div className = "data-management-item-icon-empty">{did_type}</div>
					)
				break;
		}
	}
	else
		return	(
			<div className = "data-management-item-icon-empty"></div>
			)
			
} // Icon

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function DataManagementTable( props:	{
							paramsLifetime: number,
							refreshTokens: any,
							taskExecutor: any,
							storageAreas:	{
									site: string,
									storage_areas:	{
											associated_storage_id: string,
											storage_id: string,
											storage_type: string,
											relative_path: string,
											identifier: string
											}[]
									}[],
							pageDisplayed: DataManagementPage,
							iconSize: string,
							filterFileType: string,
							setState: any,
							setInitiateDMSearch: any
							} )
{

	//	------------------------------------------------------------
	//
	//	constants
	//
	//	------------------------------------------------------------

	// translation function
	const { t } = useTranslation();
  			
  	// state variables.
	const [sLoadingComponent, setLoadingComponent] = useState< boolean >( false );
	const [sDataList, setDataList] = useState< { scope: string, name: string, did_type: string, bytes: number, length: number }[] >( [] );
	const [sSelectedItems, setSelectedItems] = useState< number[] >( [] );
	
	// context menu constants.
	const NUM_CONTEXT_MENUS: number = 3;
	const CONTEXT_MENU_WIDTH: number = 200;
	
	// context menus state variables.
	const [sContextVisible, setContextVisible] = useState< boolean[] >( Array( NUM_CONTEXT_MENUS ).fill( false ) );
	const [sContextPos, setContextPos] = useState< { x: number, y: number }[] >( Array( NUM_CONTEXT_MENUS ).fill( { x: 0.0, y: 0.0 } ) );
	const [sContextRow, setContextRow] = useState< number[] >( Array( NUM_CONTEXT_MENUS ).fill( -1 ) );
	
	// current search values.
	const [sNamespace, setNamespace] = useState< string | undefined >( undefined );
	const [sJobID, setJobID] = useState< string | undefined >( undefined );
	const [sDataset, setDataset] = useState< string | undefined >( undefined );
	
	const [sFilterFilename, setFilterFilename] = useState< string >( '' );
	
	// store a timer that fires when the double-click window times out.
	var doubleClickTimeout = useRef< ReturnType<typeof setTimeout> >();
	
	// list of context menus.
	enum CONTEXT_MENU
	{
	
		NONE = -1,
		MAIN,
		SITES,
		STORAGES
		
	} // ContextMenu
	
	// store the context menu definition.
	const [sContextMenuDefinition, setContextMenuDefinition] = useState<	ContextMenuType[] >
				(	[
					
						// main context menu.
						{
							items:	[
									{
									text: t( 'Move to' ),
									childMenu: CONTEXT_MENU.SITES,
									onMouseEnter: true,
									onClick: false,
									enabled: false
									}
								],
							parentMenu: CONTEXT_MENU.NONE
						},
						
						// list of sites.
						{
							items:	[],
							parentMenu: CONTEXT_MENU.MAIN
						},
						
						// list of storage areas.
						{
							items: [],
							parentMenu: CONTEXT_MENU.SITES
						}
						
					] );

	//	------------------------------------------------------------
	//
	//	Build the data list from a list of namespaces.
	//
	//	------------------------------------------------------------
			
	function displayNamespaces( args:	{
						namespaces: string[],
						filename: string | undefined
						} )
	{
	
		var dataList:	{
				scope: string,
				name: string,
				did_type: string,
				bytes: number,
				length: number
				}[] = [];
				
		for ( var i = 0; i < args.namespaces.length; i++ )
		{
		
			const namespace:	{
						scope: string,
						name: string,
						did_type: string,
						bytes: number,
						length: number
						} =	{
							scope: '',
							name: args.namespaces[ i ],
							did_type: 'namespace',
							bytes: -1,
							length: -1
							};
			var addItem: boolean = true;
			if (args.filename !== undefined)
				if (args.namespaces[ i ].indexOf( args.filename ) === -1)
					addItem = false;
			if (addItem === true)
				dataList.push( namespace );
		
		}

		// update the state.
		setDataList( dataList );
	
	} // displayNamespaces

	//	------------------------------------------------------------
	//
	//	builds a list of the selected items.
	//
	//	------------------------------------------------------------
  	
  	function getSelectedItems( args:	{
  						divID: number[]
  						} )
  	{
  	
  		var selectedItems:	{
  					namespace: string,
  					name: string,
  					type: string,
  					size: number
  					}[] = [];
  					
  		// loop over the selected IDs.
		for ( var selectedItemIdx = 0; selectedItemIdx < args.divID.length; selectedItemIdx++ )
			selectedItems.push(	{
						namespace: (sNamespace !== undefined && sNamespace !== '' ? sNamespace : sDataList[ args.divID[ selectedItemIdx ] ].scope),
						name: sDataList[ args.divID[ selectedItemIdx ] ].name,
						type: sDataList[ args.divID[ selectedItemIdx ] ].did_type,
						size: sDataList[ args.divID[ selectedItemIdx ] ].bytes
						} );
  					
  		// return something.
  		return selectedItems;
  	
  	} // getSelectedItems

	//	------------------------------------------------------------
	//
	//	a data-management search is initiated, either from the Search
	//	button or from a parent component.
	//
	//	------------------------------------------------------------
  	
  	function initiateDMSearch( args:	{
  						dmQuery: InitiateDMSearch,
  						taskDirective: TaskDirective
  						} )
  	{

		setLoadingComponent( true );
	
		console.log( "data management search initiated:" );
		console.log( "namespace: " + args.dmQuery.namespace );
		console.log( "filename: " + args.dmQuery.filename );
		console.log( "jobID: " + args.dmQuery.jobID );
		console.log( "fileType: " + args.dmQuery.fileType );
		console.log( "dataset: " + args.dmQuery.dataset );
		console.log( "namespaceList: " + args.dmQuery.namespaceList );
		console.log( "pageDisplayed: " + DataManagementPage[ args.dmQuery.pageDisplayed ] );

		// update state of parent compoonent.
		if (args.dmQuery.filename !== undefined && args.dmQuery.pageDisplayed === DataManagementPage.DataManagement)
			props.setState( { filterFilenameNamespaces: args.dmQuery.filename } );
		if (args.dmQuery.filename !== undefined && args.dmQuery.pageDisplayed === DataManagementPage.Namespace)
			props.setState( { filterFilenameDIDs: args.dmQuery.filename } );
		if (args.dmQuery.pageDisplayed === DataManagementPage.JobDetails)
			props.setState( { filterFilenameDIDs: '' } );
		
		// update current search parameters, and hide the details panel on the parent component.
		props.setState(	{
					filterFileType: args.dmQuery.fileType,
					tableNamespace: args.dmQuery.namespace,
					tableFilename: args.dmQuery.filename,
					tableJobID: args.dmQuery.jobID,
					tableDataset: args.dmQuery.dataset,
					selectedItems: [],
					displayDetailsPanel: false,
					pageDisplayed: args.dmQuery.pageDisplayed
					} );
		setSelectedItems( [] );
		  	
  		// load the data from the data-management end point of the data-management API.
  		if (args.dmQuery.pageDisplayed === DataManagementPage.Namespace || args.dmQuery.pageDisplayed === DataManagementPage.JobDetails)
	  		loadData(	{
	  				dmQuery: args.dmQuery,
					taskDirective: args.taskDirective
	  				} );
	  				
	  	// load files from a dataset.
	  	if (args.dmQuery.pageDisplayed === DataManagementPage.Dataset)
	  		loadDataset(	{
	  				dmQuery: args.dmQuery,
					taskDirective: args.taskDirective
	  				} );
	  		
	  	// display a list of namespaces if we're on the namespaces tab.
	  	if (args.dmQuery.pageDisplayed === DataManagementPage.DataManagement && args.dmQuery.namespaceList !== undefined)
	  		displayNamespaces(	{
	  					namespaces: args.dmQuery.namespaceList,
	  					filename: args.dmQuery.filename
	  					} );
	  					
	  	// update state.
	  	if (args.dmQuery.filename !== undefined)
	  		setFilterFilename( args.dmQuery.filename );
	  	setNamespace( args.dmQuery.namespace );
	  	setDataset( args.dmQuery.dataset );
	  	setJobID( args.dmQuery.jobID );
  		
		setLoadingComponent( false );
  	
  	} // initiateDMSearch

	//	------------------------------------------------------------
	//
	//	An asynchronous function that loads data from the data
	//	management API for a specific namespace.
	//
	//	------------------------------------------------------------
  	
  	async function loadData( args:	{
  						dmQuery: InitiateDMSearch,
  						taskDirective: TaskDirective
  						} )
  	{
	
		try
		{

			var urlCommand: string = APIPrefix() + '/v1/data_management/list_data?';
			if (args.dmQuery.namespace !== undefined && args.dmQuery.namespace !== '')
				urlCommand = urlCommand + 'namespace=' + encodeURIComponent( args.dmQuery.namespace ) + '&';
			if (args.dmQuery.filename !== undefined && args.dmQuery.filename !== '')
				urlCommand = urlCommand + 'filename=' + args.dmQuery.filename + '&';
			if (args.dmQuery.jobID !== undefined && args.dmQuery.jobID !== '')
				urlCommand = urlCommand + 'job_id=' + args.dmQuery.jobID + '&';
			urlCommand = urlCommand.slice( 0, -1 );

			try
			{
				
				const apiResult = await fetch( urlCommand,	{
										headers: {'Content-Type': 'application/json'},
										credentials: 'include'
										} );
				if (apiResult.status === 200)
				{
				
					const returnedJson = await apiResult.json();

					// get data list.
					var dataList: { scope: string, name: string, did_type: string, bytes: number, length: number }[] = [];
					if (returnedJson.data_list !== undefined)
						dataList = returnedJson.data_list;
						
					// if we are filtering by file type, then remove the non-matches.
					for ( var i = dataList.length - 1; i >= 0; i-- )
						if (args.dmQuery.fileType !== undefined)
							if (args.dmQuery.fileType !== 'all' && dataList[ i ].did_type.toUpperCase() !== args.dmQuery.fileType.toUpperCase())
								dataList.splice( i, 1 );
					
					// update the state with the list of returned data items.
					console.log( dataList );
					setDataList( dataList );
		
					console.log( "loadData() fires: " );
					console.log( dataList );
					
				}
				else
					setDataList( [] );
				
				// if the return code is 401 then the data-management token has expired. we should renew it.
				if (apiResult.status === 401 && args.taskDirective.refreshDirective === RefreshDirective.REFRESH)
				{
					console.log( "loadData 401: attempting refresh" );
					var currentTask: CurrentTask =	{
										taskType: TaskType.NONE
										}
					if (args.taskDirective.retryAfterRefresh === true)
						currentTask =	{
								taskType: TaskType.INITIATE_DM_SEARCH,
								parameters: args.dmQuery
								};
					const taskDirective: TaskDirective =	{
										refreshDirective: RefreshDirective.NO_REFRESH
										};
					props.refreshTokens(	{
								currentTask: currentTask,
								taskDirective: taskDirective
								} );
				}
			}
			catch (e)
			{
				console.log( e );
			}
			
      		}
      		catch (e)
      		{
			console.log(e);
		}
  	
  	} // loadData

	//	------------------------------------------------------------
	//
	//	An asynchronous function that loads data from the data
	//	management API for a specific dataset.
	//
	//	------------------------------------------------------------
  	
  	async function loadDataset( args:	{
  						dmQuery: InitiateDMSearch,
  						taskDirective: TaskDirective
  						} )
  	{
	
		try
		{

			var urlCommand: string = APIPrefix() + '/v1/data_management/locate_data?';
			if (args.dmQuery.namespace !== undefined && args.dmQuery.namespace !== '')
				urlCommand = urlCommand + 'namespace=' + encodeURIComponent( args.dmQuery.namespace ) + '&';
			if (args.dmQuery.dataset !== undefined && args.dmQuery.dataset !== '')
				urlCommand = urlCommand + 'name=' + args.dmQuery.dataset + '&';
			urlCommand = urlCommand.slice( 0, -1 );

			try
			{
				
				const apiResult = await fetch( urlCommand,	{
										headers: {'Content-Type': 'application/json'},
										credentials: 'include'
										} );
				if (apiResult.status === 200)
				{
				
					const returnedJson = await apiResult.json();

					// get data list.
					var locations: { identifier: string, associated_storage_area_id: string, replicas: string[] }[] = [];
					if (returnedJson.locations !== undefined)
						locations = returnedJson.locations;

					// get data list.
					var dataList: { scope: string, name: string, did_type: string, bytes: number, length: number }[] = [];
					if (locations.length > 0)
						for ( var i = 0; i < locations[ 0 ].replicas.length; i++ )
						{
							
							// extract the filename after the last /.
							var filename: string = locations[ 0 ].replicas[ i ];
							if (filename.lastIndexOf( '/' ) > -1)
								filename = filename.slice( -(filename.length - filename.lastIndexOf( '/' ) - 1) );
								
							// check if filename matches the filter.
							var matchesFilter: boolean = true;
							if (args.dmQuery.filename !== undefined)
								matchesFilter = (filename.indexOf( args.dmQuery.filename ) > -1);
														
							// push the new data item.
							if (matchesFilter === true)
							{
								var dataItem: { scope: string, name: string, did_type: string, bytes: number, length: number } =
									{
									scope: args.dmQuery.namespace !== undefined ? args.dmQuery.namespace : '',
									name: filename,
									did_type: "file",
									bytes: 0,
									length: 0
									};
								dataList.push( dataItem );
							}
							
						}
						
					// if we are filtering by file type, then remove the non-matches.
					if (args.dmQuery.fileType !== undefined)
						if (args.dmQuery.fileType.toUpperCase() === 'DATASET' || args.dmQuery.fileType.toUpperCase() === 'CONTAINER')
							dataList = [];
					
					// update the state with the list of returned data items.
					console.log( dataList );
					setDataList( dataList );
		
					console.log( "loadDataset() fires: " );
					console.log( dataList );
					
				}
				else
					setDataList( [] );
				
				// if the return code is 401 then the data-management token has expired. we should renew it.
				if (apiResult.status === 401 && args.taskDirective.refreshDirective === RefreshDirective.REFRESH)
				{
					console.log( "loadDataset 401: attempting refresh" );
					var currentTask: CurrentTask =	{
										taskType: TaskType.NONE
										}
					if (args.taskDirective.retryAfterRefresh === true)
						currentTask =	{
								taskType: TaskType.INITIATE_DM_SEARCH,
								parameters: args.dmQuery
								};
					const taskDirective: TaskDirective =	{
										refreshDirective: RefreshDirective.NO_REFRESH
										};
					props.refreshTokens(	{
								currentTask: currentTask,
								taskDirective: taskDirective
								} );
				}
				
			}
			catch (e)
			{
				console.log( e );
			}
			
      		}
      		catch (e)
      		{
			console.log(e);
		}
  	
  	} // loadDataset

	//	------------------------------------------------------------
	//
	//	Handler for lost-focus events on the input boxes.
	//
	//	------------------------------------------------------------
  	
  	const onBlurEvent = (event: React.FocusEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.target;
  		
  		if (inputBox.name === "filename")
  		{
		
			// populate parameters for the DM search.
			const dmQuery: InitiateDMSearch =	{
								namespace: sNamespace,
								filename: inputBox.value,
								jobID: sJobID,
								dataset: sDataset,
								fileType: (props.pageDisplayed === DataManagementPage.DataManagement ? 'all' : props.filterFileType),
								pageDisplayed: props.pageDisplayed
								};
			const currentTask: CurrentTask =	{
								taskType: TaskType.INITIATE_DM_SEARCH,
								parameters: dmQuery
								};
			const taskDirective: TaskDirective =	{
								refreshDirective: RefreshDirective.REFRESH,
								retryAfterRefresh: true
								};
				
			// run the data-management search.
			props.taskExecutor( 	{
						currentTask: currentTask,
						taskDirective: taskDirective
						} );
  			 
  		}
  	
  	} // onBlurEvent

	//	------------------------------------------------------------
	//
	//	Handler for changes to the input boxes.
	//
	//	------------------------------------------------------------
  	
  	const onChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.target;
  		
  		// update the state for any input boxes that have been changed.
  		if (inputBox.name === "filename")
  		
			setFilterFilename( inputBox.value );
	  	
  	} // onChangeEvent

	//	------------------------------------------------------------
	//
	//	Handler for select box onChange event.
	//
	//	------------------------------------------------------------
	
	const onChangeSelectHandler = (event: React.ChangeEvent<HTMLSelectElement>) =>
	{

		const selectBox: HTMLSelectElement = event.target;
		
		// change to the selected file type.
		if (selectBox.id === 'file-type-combo')
		{
		
			// update the state variable that holds the current value of the file type listbox.
			props.setState( { filterFileType: selectBox.value } );
		
			// populate parameters for the DM search.
			const dmQuery: InitiateDMSearch =	{
								namespace: sNamespace,
								filename: sFilterFilename,
								jobID: sJobID,
								dataset: sDataset,
								fileType: selectBox.value,
								pageDisplayed: props.pageDisplayed
								};
			const currentTask: CurrentTask =	{
								taskType: TaskType.INITIATE_DM_SEARCH,
								parameters: dmQuery
								};
			const taskDirective: TaskDirective =	{
								refreshDirective: RefreshDirective.REFRESH,
								retryAfterRefresh: true
								};
				
			// run the data-management search.
			props.taskExecutor( 	{
						currentTask: currentTask,
						taskDirective: taskDirective
						} );
			
		}
				
	} // onChangeSelectHandler

	//	------------------------------------------------------------
	//
	//	Handler for clicks on data items.
	//
	//	------------------------------------------------------------
  	
  	const onClickHandler = (event: React.MouseEvent<HTMLDivElement>) =>
  	{
  	
  		const divElement: HTMLDivElement = event.currentTarget;

  		// check for a right-click, and a double click.
  		const rightClick: boolean = (event.type === 'contextmenu');
  		const doubleClick: boolean = (event.detail === 2);

		// if the user clicked on the container, but not in any items, then we need to clear the selection.
		if (event.target instanceof HTMLDivElement)
		{
			const targetElement: HTMLDivElement = event.target;
			if (targetElement.id === 'container')
			{
			
				// clear selected items.
				setSelectedItems( [] );
				props.setState(	{
							selectedItems: [],
							displayDetailsPanel: false
							} );
				
				// disable the context menu option for moving data.
				var contextMenuTmp: ContextMenuType[] = sContextMenuDefinition.slice();
				contextMenuTmp[ CONTEXT_MENU.MAIN ].items[ 0 ].enabled = false;
				setContextMenuDefinition( contextMenuTmp );
				
				// deselect all the context-menu rows, and set all menus to hidden.
				setContextRow( Array( NUM_CONTEXT_MENUS ).fill( -1 ) );
				setContextVisible( Array( NUM_CONTEXT_MENUS ).fill( false ) );
				
			}
		}

  		// if we've right clicked somewhere in the container store the X and Y coordinates, and set the context menu visible.
  		if (rightClick === true && divElement.id === 'container')
  		{
  		
  			// get the DIV element with the vertical scroll bars, and find the scroll position.
			const id = 'scrollbox';
			const scrollDiv: HTMLDivElement | null = document.querySelector(`#${id}`);
			var scrollPos: number = 0.0;
			if (scrollDiv !== null)
				scrollPos = scrollDiv.scrollTop;

			var bounds = divElement.getBoundingClientRect();
			var contextPos: { x: number, y: number } = { x: event.clientX - bounds.left, y: event.clientY - bounds.top };
			
			// check if the context menu will appear off the right-hand side of the panel.
			if (bounds.width - contextPos.x < CONTEXT_MENU_WIDTH)
				contextPos.x = bounds.width - CONTEXT_MENU_WIDTH;
			if (contextPos.x < 0.0)
				contextPos.x = 0.0;
				
			// check if the context menu will appear off the bottom of the panel.
			if (bounds.height - contextPos.y + scrollPos < 50.0)
				contextPos.y = bounds.height + scrollPos - 50.0;
			if (contextPos.y < 0.0)
				contextPos.y = 0.0;
			
			// update state.
			var pos: { x: number, y: number }[] = sContextPos.slice();
			pos[ CONTEXT_MENU.MAIN ] = contextPos;
			setContextPos( pos );
			var contextVisible: boolean[] = sContextVisible.slice();
			contextVisible[ CONTEXT_MENU.MAIN ] = true;
			contextVisible[ CONTEXT_MENU.SITES ] = false;
			contextVisible[ CONTEXT_MENU.STORAGES ] = false;
			setContextVisible( contextVisible );
			
  			event.preventDefault();
  			
  		}
			
		// check which data item was clicked on.
		if (divElement.id.length > 8)
			if (divElement.id.slice( 0, 8 ) === "itmData_")
			{
		
				// get the ID and convert to numeric.
				const divIDstr: string = divElement.id.slice( 8 - divElement.id.length );
				var divID: number = -1;
				try
				{
					divID = Number( divIDstr );
				}
				catch
				{
				}
				
				// check if this element is already selected.
				const alreadySelected: boolean = (sSelectedItems.indexOf( divID ) > -1);
				
				// set to true once the event has been handled.
				var alreadyHandled: boolean = false;
				
				// set the Move To item in the context menu to enabled if we're on the namespace page, otherwise disable it.
				var contextMenuTmp: ContextMenuType[] = sContextMenuDefinition.slice();
				contextMenuTmp[ CONTEXT_MENU.MAIN ].items[ 0 ].enabled = (props.pageDisplayed === DataManagementPage.Namespace);
				setContextMenuDefinition( contextMenuTmp );
				
				//
				// open the dataset page if the user double-clicks on a dataset, or on a namespace on the landing page
				//
				// we can do this if:
				//
				//	a. the item has been double clicked, and
				//	b. the DID type is DATASET.
				//
				
				if (divID > -1 && doubleClick === true && alreadyHandled === false )
				{
				
					// work out what namespace has been clicked on, and initiate the search.
					const dataItem:	{
								scope: string,
								name: string,
								did_type: string,
								bytes: number,
								length: number
								} = sDataList[ divID ];
								
					console.log( "dataItem:" );
					console.log( divID.toString() );
					console.log( dataItem );
					
					if (dataItem.did_type.toUpperCase() === 'DATASET')
					{
					
						// clear the timeout to prevent the item being selected.
						clearTimeout( doubleClickTimeout.current );
						console.log( "clear timeout set (1)" );
		
						// populate parameters for the DM search.
						const dmQuery: InitiateDMSearch =	{
											namespace: dataItem.scope,
											filename: '',
											dataset: dataItem.name,
											fileType: 'all',
											pageDisplayed: DataManagementPage.Dataset
											};
						const currentTask: CurrentTask =	{
											taskType: TaskType.INITIATE_DM_SEARCH,
											parameters: dmQuery
											};
						const taskDirective: TaskDirective =	{
											refreshDirective: RefreshDirective.REFRESH,
											retryAfterRefresh: true
											};
							
						// run the data-management search.
						props.taskExecutor( 	{
									currentTask: currentTask,
									taskDirective: taskDirective
									} );
										
					}
					
					if (dataItem.did_type.toUpperCase() === 'NAMESPACE')
					{
					
						// clear the timeout to prevent the item being selected.
						clearTimeout( doubleClickTimeout.current );
						console.log( "clear timeout set (1)" );
		
						// populate parameters for the DM search.
						const dmQuery: InitiateDMSearch =	{
											namespace: dataItem.name,
											filename: '',
											fileType: 'all',
											pageDisplayed: DataManagementPage.Namespace
											};
						const currentTask: CurrentTask =	{
											taskType: TaskType.INITIATE_DM_SEARCH,
											parameters: dmQuery
											};
						const taskDirective: TaskDirective =	{
											refreshDirective: RefreshDirective.REFRESH,
											retryAfterRefresh: true
											};
							
						// run the data-management search.
						props.taskExecutor( 	{
									currentTask: currentTask,
									taskDirective: taskDirective
									} );
									
					}
					
					alreadyHandled = true;
				
				}
				
				//
				// clear the selected items, and set the selected item list to this item. also close the context menu.
				//
				// we can do this if:
				//
				//	a. Ctrl and shift are not pressed, OR we have no selected items, and
				//	b. the item is not already selected
				//
				
				if (divID > -1 && ((event.ctrlKey === false && event.shiftKey === false) || sSelectedItems.length === 0) &&
					alreadySelected === false && alreadyHandled === false)
				{
				
					var selectedItems: number[] = [];
					selectedItems.push( divID );
					setSelectedItems( selectedItems );
					props.setState( { selectedItems: getSelectedItems( { divID: selectedItems } ) } );
					
					// set a timer so that we only display the details panel when we're sure it's not a double click.
					doubleClickTimeout.current = setTimeout(	() =>
											{
											props.setState( { displayDetailsPanel: selectedItems.length > 0 } );
											}, 500 );
					console.log( "timeout set (1)" );
					
					setContextVisible( Array( NUM_CONTEXT_MENUS ).fill( false ) );
					setContextRow( Array( NUM_CONTEXT_MENUS ).fill( -1 ) );
					alreadyHandled = true;
					
				}
				
				//
				// add the selected item to the list.
				//
				// we can do this if:
				//
				//	a. Ctrl is pressed, and shift is not pressed
				//
				
				if (divID > -1 && event.ctrlKey === true && event.shiftKey === false && alreadyHandled === false)
				{
				
					var selectedItems: number[] = sSelectedItems.slice();
					if (selectedItems.indexOf( divID ) === -1)
						selectedItems.push( divID );
					setSelectedItems( selectedItems );
					props.setState( { selectedItems: getSelectedItems( { divID: selectedItems } ) } );
					
					// set a timer so that we only display the details panel when we're sure it's not a double click.
					doubleClickTimeout.current = setTimeout(	() =>
											{
											props.setState( { displayDetailsPanel: selectedItems.length > 0 } );
											}, 500 );
					console.log( "timeout set (2)" );
					alreadyHandled = true;
					
				}
				
				//
				// add multiple items to the list.
				//
				// we can do this if:
				//
				//	a. Shift is pressed, and we have at least one selected item already.
				//
				
				if (divID > -1 && event.shiftKey === true && sSelectedItems.length > 0 && alreadyHandled === false)
				{
				
					var selectedItems: number[] = sSelectedItems.slice();
					
					// get the last selected item.
					const lastItem: number = selectedItems[ selectedItems.length - 1 ];
					
					// loop over all the items in between.
					if (divID >= lastItem)
						for ( var i = lastItem; i <= divID; i++ )
						{
						
							// remove this item if it already exists.
							const index = selectedItems.indexOf( i );
							if (index > -1)
								selectedItems.splice( index, 1 );
								
							// add the item to the end of the list.
							selectedItems.push( i );
						
						}
					else
						for ( var i = lastItem; i >= divID; i-- )
						{
						
							// remove this item if it already exists.
							const index = selectedItems.indexOf( i );
							if (index > -1)
								selectedItems.splice( index, 1 );
								
							// add the item to the end of the list.
							selectedItems.push( i );
						
						}
					
					// update the state.
					setSelectedItems( selectedItems );
					props.setState( { selectedItems: getSelectedItems( { divID: selectedItems } ) } );
					
					// set a timer so that we only display the details panel when we're sure it's not a double click.
					doubleClickTimeout.current = setTimeout(	() =>
											{
											props.setState( { displayDetailsPanel: selectedItems.length > 0 } );
											}, 500 );
					console.log( "timeout set (3)" );
					alreadyHandled = true;
					
				}
			
			}
			
		// have we clicked on a icon size button?
		if (divElement.id === 'icon-size-details')
			props.setState( { iconSize: 'list' } );
		if (divElement.id === 'icon-size-medium')
			props.setState( { iconSize: 'medium' } );
		if (divElement.id === 'icon-size-large')
			props.setState( { iconSize: 'large' } );
  	
  	} // onClickHandler

	//	------------------------------------------------------------
	//
	//	handle the click event for a context menu item by launching
	//	the move-data process.
	//
	//	------------------------------------------------------------
  	
  	function onContextMenuClick( args:	{
						menuID: number,
						rowID: number[]
						} )
  	{

  		// if we've clicked on a storage area on the storages menu
  		// then launch the move-data process.
  		if (args.menuID === CONTEXT_MENU.STORAGES)
  		{

			// build a list of selected files.
			const filesToMove:	{
						namespace: string,
						name: string
						}[] = [];
			for ( var selectedItemIdx = 0; selectedItemIdx < sSelectedItems.length; selectedItemIdx++ )
				filesToMove.push(	{
							namespace: (sNamespace !== undefined ? sNamespace : ''),
							name: sDataList[ sSelectedItems[ selectedItemIdx ] ].name
							} );
							
			// build parameters for moving some data to a storage location.
			const moveDataToStorage: MoveDataToStorage =	{
									toStorageAreaUUID: props.storageAreas[ args.rowID[ CONTEXT_MENU.SITES ] ].storage_areas[ args.rowID[ CONTEXT_MENU.STORAGES ] ].storage_id,
									toSite: props.storageAreas[ args.rowID[ CONTEXT_MENU.SITES ] ].site,
									toStorageIdentifier: props.storageAreas[ args.rowID[ CONTEXT_MENU.SITES ] ].storage_areas[ args.rowID[ CONTEXT_MENU.STORAGES ] ].identifier,
									lifetime: props.paramsLifetime,
									filesToMove: filesToMove
									}
			const currentTask: CurrentTask =	{
								taskType: TaskType.MOVE_DATA_TO_STORAGE,
								parameters: moveDataToStorage
								};
								
			const taskDirective: TaskDirective =	{
								refreshDirective: RefreshDirective.REFRESH,
								retryAfterRefresh: true
								};
			
			// call the API to move this data to the required storage area.
			props.taskExecutor(	{
						currentTask: currentTask,
						taskDirective: taskDirective
						} );
					
		}
  	
  	} // onContextMenuClick

	//	------------------------------------------------------------
	//
	//	handle the mouse enter event for a context menu item
	//	by rebuilding the child menu.
	//
	//	------------------------------------------------------------
  	
  	function onContextMenuMouseEnter( args:	{
  							menuID: number,
  							rowID: number
  							} )
  	{

  		// if we've entered the 'Move to' option on the main context menu then
  		// rebuild the sites list menu.
  		if (args.menuID === CONTEXT_MENU.MAIN && args.rowID === 0)
  		{
  		
			var contextMenuTmp: ContextMenuType[] = sContextMenuDefinition.slice();
			
			// build the new sites context menu.
			contextMenuTmp[ CONTEXT_MENU.SITES ].items =	props.storageAreas.map
									(
										item =>
										(
											{
											text: item.site,
											childMenu: CONTEXT_MENU.STORAGES,
											onMouseEnter: true,
											onClick: false,
											enabled: true
											}
										)
									);
			
			// update the state.
			setContextMenuDefinition( contextMenuTmp );
  			
  		}
  		
  		// if we've entered a menu item on the sites context menu then
  		// rebuild the storage areas menu.
  		if (args.menuID === CONTEXT_MENU.SITES)
  		{
  		
			var contextMenuTmp: ContextMenuType[] = sContextMenuDefinition.slice();
			
			// build the new sites context menu.
			contextMenuTmp[ CONTEXT_MENU.STORAGES ].items =	props.storageAreas[ args.rowID ].storage_areas.map
										(
											item =>
											(
												{
												text: (item.identifier !== '' ? item.identifier : '<' + item.relative_path + '>'),
												childMenu: CONTEXT_MENU.NONE,
												onMouseEnter: false,
												onClick: true,
												enabled: true
												}
											)
										);
			
			// update the state.
			setContextMenuDefinition( contextMenuTmp );
  		
  		}
  	
  	} // onContextMenuMouseEnter
  	
  	//	------------------------------------------------------------
  	//
  	//	H O O K S
  	//
  	//	------------------------------------------------------------

	//	------------------------------------------------------------
	//
	//	Kick off some processes once the page has loaded.
	//
	//	------------------------------------------------------------
  	
  	useEffect	( () =>
		  	{
		  		
		  		// set the initiate search function so that the parent component can call it.
		  		props.setInitiateDMSearch( initiateDMSearch );
				
			}, []
			);

	//	------------------------------------------------------------
	//
	//	C O M P O N E N T   C O D E
	//
	//	------------------------------------------------------------
	
	return	(
		
		<div	className = "data-management-table"
			style = {	{
					flex: '1 1 0px',
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					maxHeight: '100%'
					} } >
		
			<div	className = "data-management-scrollbox-container"
				style = {	{
						height: '0px',
						flex: '1 1 auto'
						} } >
			
				<div	id = "scrollbox"
					style = {	{
							width: "100%",
							height: "100%",
							overflowY: "auto",
							display: "flex",
							flexDirection: "column"
							} } >
				
					<div	key = {sLoadingComponent ? 'T' : 'F'}
						className = {sLoadingComponent === true ? "search-results-table-loading" : "search-results-table-loaded"}>
						<img	className = "animated-gears"
							src = {gearsIcon}
							alt = ""
							width = "60"
							height = "60" />
						Loading
					</div>
					
					{/* display the list of data items */}
					<div	style = {	{
								display: 'flex',
								flexDirection: 'column',
								flex: '1 1',
								padding: '10px 10px 10px 10px'
								} }>
						<DataItems	key = {sDataList.length.toString() + sSelectedItems.length.toString()}
								dataList = {sDataList}
								selectedItems = {sSelectedItems}
								iconSize = {props.iconSize}
								onClickHandler = {onClickHandler}
								onContextMenuClick = {onContextMenuClick}
								onContextMenuMouseEnter = {onContextMenuMouseEnter}
								contextMenuDefinition = {sContextMenuDefinition}
								contextPos = {sContextPos}
								setContextPos = {setContextPos}
								contextRow = {sContextRow}
								setContextRow = {setContextRow}
								contextVisible = {sContextVisible}
								setContextVisible = {setContextVisible}
								NUM_CONTEXT_MENUS = {NUM_CONTEXT_MENUS}
								CONTEXT_MENU_WIDTH = {CONTEXT_MENU_WIDTH} />
					</div>
				
				</div>
			
			</div>
			
			<div style = {{ flex: '0 0 20px' }} />
			
			<div	style = {	{
						flex: '0 0 38px',
						display: 'flex',
						flexDirection: 'row'
						} } >
				<div	className = "data-management-form-text"
					style = {{ flex: '1 1' }} >
					{
					sDataList.length === 100 && props.pageDisplayed !== DataManagementPage.DataManagement
					?	'Showing first 100 results'
					:	''
					}
				</div>
				<div	style = {{ flex: '0 0 5px' }} />
				<div	style = {	{
							flex: '0 0 auto',
							height: '34px',
							alignItems: 'center',
							display: 'flex',
							flexDirection: 'row'
							} } >
				    	<select	id = "file-type-combo"
				    			className = "listbox"
				    			multiple = {false}
				    			onChange = {onChangeSelectHandler}
				    			data-placeholder-shown = "F"
				    			disabled = {props.pageDisplayed === DataManagementPage.DataManagement}
				    			data-disabled = {props.pageDisplayed === DataManagementPage.DataManagement ? "T" : "F"}
				    			style = {{ width: '180px', height: '34px', lineHeight: '100%' }}
				    			value = {props.pageDisplayed === DataManagementPage.DataManagement ? "all" : props.filterFileType} >
				    		<option	key = {0}
				    				label = {t( "All types" )}
				    				value = "all" > {t( "All types" )} </option>
				    		<option	key = {1}
				    				label = {t( "Files" )}
				    				value = "file" > {t( "Files" )} </option>
				    		<option	key = {2}
				    				label = {t( "Datasets" )}
				    				value = "dataset" > {t( "Datasets" )} </option>
				    		<option	key = {3}
				    				label = {t( "Containers" )}
				    				value = "container" > {t( "Containers" )} </option>
					</select> 
				</div>
				<div	style = {{ flex: '0 0 5px' }} />
				<div	id = "icon-size-details"
					className = "data-management-mini-button-div" data-selected = {props.iconSize === 'list' ? "T" : "F"}
					style = {{ flex: '0 0 auto', height: '34px' }}
					onClick = {onClickHandler} >
					<img	className = "data-management-mini-button-img"
						src = {fileDetailsIcon} alt = "" width = "32" height = "32" />
				</div>
				<div	style = {{ flex: '0 0 5px' }} />
				<div	id = "icon-size-medium"
					className = "data-management-mini-button-div" data-selected = {props.iconSize === 'medium' ? "T" : "F"}
					style = {{ flex: '0 0 auto', height: '34px' }}
					onClick = {onClickHandler} >
					<img	className = "data-management-mini-button-img"
						src = {fileSmallIcon} alt = "" width = "32" height = "32" />
				</div>
				<div	style = {{ flex: '0 0 5px' }} />
				<div	id = "icon-size-large"
					className = "data-management-mini-button-div" data-selected = {props.iconSize === 'large' ? "T" : "F"}
					style = {{ flex: '0 0 auto', height: '34px' }}
					onClick = {onClickHandler} >
					<img	className = "data-management-mini-button-img"
						src = {fileLargeIcon} alt = "" width = "32" height = "32" />
				</div>
				<div	style = {{ flex: '0 0 5px' }} />
				<div	style = {	{
							flex: '0 0 279px',
							height: '34px'
							} } >
    	    				<input	className = "filter-textbox"
    	    					type = "text"
    	    					name = "filename"
    	    					placeholder = {t("Filter names")}
    	    					onBlur = {onBlurEvent}
    	    					onChange = {onChangeEvent}
    	    					value = {sFilterFilename}
						maxLength = {255} />
				</div>
				<div style = {{ flex: '0 0 10px' }} />
							
			</div>
			
			<div style = {{ flex: '0 0 10px' }} />
			
		</div>
		
		)

} // DataManagementTable
