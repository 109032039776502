import React, { useState } from 'react';
import '../App.css';
import './tool.css';
import CSS from 'csstype';

// images

// classes
import { CheckOverflow } from '../utils/functions';
import { ShortenText } from '../utils/functions';

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export function CheckBox( props:	{
					name: string,
					value: string,
					text: string,
					checked: boolean,
					onChange: any
					} )
{
	
	// the text to display in the tool.
	const [sToolText, setToolText] = useState< string >( props.text );
	const [sToolTextWithoutDots, setToolTextWithoutDots] = useState< string >( props.text );

	// check the Label element to see if the text has overflowed.
	const monitorLabelForOverflow = React.useRef<HTMLLabelElement | null>( null );
	
	// remove one character at a time from the text until it no longer causes an overflow.
	if (CheckOverflow( { ref: monitorLabelForOverflow, text: sToolText } ) === true)
		ShortenText( { text: sToolTextWithoutDots, setText: setToolText, setTextWithoutDots: setToolTextWithoutDots } );

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------
	
	return	(
	
		<label	className = "check-label"
			ref = {monitorLabelForOverflow}
			style = {{overflow: 'auto'}} >
			
			<input	type = "checkbox"
				checked = {props.checked}
				name = {props.name}
				value = {props.value}
				onChange = {props.onChange}>
			</input>
			{sToolText}
			
		</label>
		
  		);

} // CheckBox
