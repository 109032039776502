import React, { useState } from 'react';
import '../App.css';
import './tool.css';
import CSS from 'csstype';

// images

// classes
import { CheckOverflow } from '../utils/functions';
import { ShortenText } from '../utils/functions';

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

//
//	ToolButtonType should be supplied. If not, the component assumes a PRIMARY type.
//

export enum ToolButtonType
{

	PRIMARY,
	SECONDARY,
	TRANSPARENT,
	STATUS_ACTIVE,
	STATUS_INACTIVE
	
} // ToolButtonType

//
//	ToolButtonWidth will default depending on button type, but this can be changed by
//	supplied a value instead.
//

export enum ToolButtonWidth
{

	WIDE,
	STANDARD,
	NARROW

} // ToolButtonWidth

//
//	ToolButtonColour will default depending on button type, but this can be changed by
//	supplied a value instead.
//

export enum ToolButtonColour
{

	PINK,
	RED,
	GREEN,
	BLUE,
	AMBER,
	NONE

} // ToolButtonColour

export default function ToolButton( props:	{
						name: string,
						icon?: string,
						disabledIcon?: string,
						text: string,
						onClick: any,
						tooltip?: string,
						disabled?: boolean,
						type?: ToolButtonType,
						width?: ToolButtonWidth,
						colour?: ToolButtonColour
						} )
{

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------
	
	// the text to display in the tool.
	const [sToolText, setToolText] = useState< string >( props.text );
	const [sToolTextWithoutDots, setToolTextWithoutDots] = useState< string >( props.text );

	// check the DIV element to see if the text has overflowed.
	const monitorDivForOverflow = React.useRef<HTMLDivElement | null>( null );
	
	// check if type, width, and colour parameters are provided.
	var toolButtonType: ToolButtonType =	(
						props.type !== undefined
						?	props.type
						:	ToolButtonType.PRIMARY
						);
	var toolButtonWidth: ToolButtonWidth =	(
							props.width !== undefined
							?	props.width
							:	(
								props.type === ToolButtonType.STATUS_ACTIVE ||
											props.type === ToolButtonType.STATUS_INACTIVE
								?	ToolButtonWidth.NARROW
								:	ToolButtonWidth.STANDARD
								)
							);
	var toolButtonColour: ToolButtonColour =	(
							props.colour !== undefined
							?	props.colour
							:	(
								props.type === ToolButtonType.SECONDARY
								?	ToolButtonColour.BLUE
								:	(
									props.type === ToolButtonType.TRANSPARENT
									?	ToolButtonColour.NONE
									:	(
										props.type === ToolButtonType.STATUS_ACTIVE
										?	ToolButtonColour.GREEN
										:	(
											props.type === ToolButtonType.STATUS_INACTIVE
											?	ToolButtonColour.RED
											:	ToolButtonColour.PINK
											)
										)
									)
								)
							);
	
	// remove one character at a time from the text until it no longer causes an overflow.
	if (CheckOverflow( { ref: monitorDivForOverflow, text: sToolText } ) === true)
		ShortenText( { text: sToolTextWithoutDots, setText: setToolText, setTextWithoutDots: setToolTextWithoutDots } );

	// do we need to display an icon or not?
	if (props.icon !== undefined)
		return	(
			<button	name = {props.name}
					type = "button"
					className = "button-with-icon"
					onClick = {props.onClick}
					data-type = {	(
							toolButtonType === ToolButtonType.PRIMARY
							?	'PRIMARY'
							:	(
								toolButtonType === ToolButtonType.SECONDARY
								?	'SECONDARY'
								:	(
									toolButtonType === ToolButtonType.TRANSPARENT
									?	'TRANSPARENT'
									:	(
										toolButtonType === ToolButtonType.STATUS_ACTIVE
										?	'STATUS_ACTIVE'
										:	'INACTIVE'
										)
									)
								)
							) }
					data-width = {	(
							toolButtonWidth === ToolButtonWidth.WIDE
							?	'WIDE'
							:	(
								toolButtonWidth === ToolButtonWidth.NARROW
								?	'NARROW'
								:	'STANDARD'
								)
							) }
					data-colour = {	(
								toolButtonColour === ToolButtonColour.BLUE
								?	'BLUE'
								:	(
									toolButtonColour === ToolButtonColour.RED
									?	'RED'
									:	(
										toolButtonColour === ToolButtonColour.GREEN
										?	'GREEN'
										:	(
											toolButtonColour === ToolButtonColour.NONE
											?	'NONE'
											:	(
												toolButtonColour === ToolButtonColour.AMBER
												?	'AMBER'
												:	'PINK'
												)
											)
										)
									)
								) } >
					
				<div	style = {{ float: 'left', height: '24px', marginLeft: '7px', alignItems: 'center' }} >
					<img	src = {props.icon}
						alt = ""
						width = "24"
						height = "24" />
				</div>
				
				<div	className = "button-text-container"
					style = {{overflow: 'auto'}}
					data-align = {toolButtonType === ToolButtonType.TRANSPARENT ? "L" : "C"}
					ref = {monitorDivForOverflow} >
					
					<div key = {sToolText} className = "text-container-inner">{sToolText}</div>
					
				</div>
				
			</button>
			);
	else
		return	(
			<button	name = {props.name}
					type = "button"
					title = {props.tooltip}
					className = "button"
					onClick = {props.onClick}
					data-disabled = {props.disabled ? 'T' : 'F'}
					data-type = {	(toolButtonType === ToolButtonType.PRIMARY ? 'PRIMARY' :
							(toolButtonType === ToolButtonType.SECONDARY ? 'SECONDARY' :
							(toolButtonType === ToolButtonType.TRANSPARENT ? 'TRANSPARENT' :
							(toolButtonType === ToolButtonType.STATUS_ACTIVE ? 'STATUS_ACTIVE' :
							('INACTIVE')))))}
					data-width = {	(
							toolButtonWidth === ToolButtonWidth.WIDE
							?	'WIDE'
							:	(
								toolButtonWidth === ToolButtonWidth.NARROW
								?	'NARROW'
								:	'STANDARD'
								)
							) }
					data-colour = {	(
								toolButtonColour === ToolButtonColour.BLUE
								?	'BLUE'
								:	(
									toolButtonColour === ToolButtonColour.RED
									?	'RED'
									:	(
										toolButtonColour === ToolButtonColour.GREEN
										?	'GREEN'
										:	(
											toolButtonColour === ToolButtonColour.NONE
											?	'NONE'
											:	(
												toolButtonColour === ToolButtonColour.AMBER
												?	'AMBER'
												:	'PINK'
												)
											)
										)
									)
								) } >
			
				<div	className = "button-text-container"
					style = {{overflow: 'auto'}}
					ref = {monitorDivForOverflow}>
				
					<div key = {sToolText} className = "text-container-inner">{sToolText}</div>
					
				</div>
				
			</button>
			);

} // ToolButton
