import React, { useEffect } from 'react';
import '../../App.css';
import './user-control.css';
import CSS from 'csstype';
import { useTranslation } from "react-i18next";

// types
import { NotificationType } from '../../utils/types';
import { DataManagementPage } from '../data-management/types';

// classes
import { HideDropdownMenu } from '../../utils/functions';

// images
import oidcIcon from '../../icons/oidc.256.png';
import magnifierIcon from '../../icons/magnifier.512.png';
import userAccountIcon from '../../icons/user.512.png';
import preferencesIcon from '../../icons/preferences.512.png';
import logoutIcon from '../../icons/logout.512.png';
import closeIcon from '../../icons/close-icon.160.png';
import unreadIcon from '../../icons/unread-notification-dark.128.png';

// types relating to tasks.
import { InitiateDMSearch } from '../../utils/tasks';
import { RefreshDirective } from '../../utils/tasks';
import { TaskType } from '../../utils/tasks';
import { CurrentTask } from '../../utils/tasks';
import { TaskDirective } from '../../utils/tasks';

// classes

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function UserControlDropdownMenu( props:	{
								sUserDropdownMenuDisplayed: boolean,
								setUserDropdownMenuDisplayed: any,
								buttonHandler: any,
								notifications: NotificationType[],
								setNotifications: any,
								taskExecutor: any,
								sRenderCount: number,
								setRenderCount: any,
								initiateComputeSearchEvent: any
								} )
{

	// multi-language support
	const { i18n, t } = useTranslation();

	// function that monitors for mouse clicks. we need to add {ref} to the DIV element of the dropdown menu.
	const { ref } = HideDropdownMenu(	{
						sDropdownDisplayed: props.sUserDropdownMenuDisplayed,
						setDropdownDisplayed: props.setUserDropdownMenuDisplayed
						} );

	//	------------------------------------------------------------
	//
	//	delete a single notification.
	//
	//	------------------------------------------------------------

	const deleteNotificationHandler = ( event: React.MouseEvent<HTMLButtonElement>, pNotificationID: number ) =>
	{

		// delete the notification.
		var notifications: NotificationType[] = props.notifications.slice();
		const notificationPos: number = props.notifications.findIndex( (item) => item.notificationID === pNotificationID );
		if (notificationPos > -1)
			notifications.splice( notificationPos, 1 );

		// call the end point to remove the notification.
		const notificationIDs: number[] =	[
	  						pNotificationID
	  						];
	  	const currentTask: CurrentTask =	{
							taskType: TaskType.DELETE_NOTIFICATIONS,
							parameters: notificationIDs
							};
		const taskDirective: TaskDirective =	{
							refreshDirective: RefreshDirective.REFRESH,
							retryAfterRefresh: true
							};
		props.taskExecutor(	{
					currentTask: currentTask,
					taskDirective: taskDirective
					} );

		// update state.
		props.setNotifications( notifications );
		props.setRenderCount( props.sRenderCount + 1 );

	} // deleteNotificationHandler

	//	------------------------------------------------------------
	//
	//	search the compute API for JupyterHUBs that can be
	//	used to analyse these data.
	//
	//	------------------------------------------------------------

	const initiateComputeSearchHandler = ( event: React.MouseEvent<HTMLButtonElement>, pToSite: string ) =>
	{

		props.initiateComputeSearchEvent(	{
							site: pToSite,
							serviceType: 'jupyterhub'
							} );
							
		// hide the drop-down menu.
		props.setUserDropdownMenuDisplayed( false );

	} // initiateComputeSearchHandler

	//	------------------------------------------------------------
	//
	//	search the data-management API for the files being
	//	transferred by this job ID.
	//
	//	------------------------------------------------------------

	const initiateDMSearchHandler = ( event: React.MouseEvent<HTMLButtonElement>, pNamespace: string, pJobID: string ) =>
	{
		
		// populate parameters for the DM search.
		const dmQuery: InitiateDMSearch =	{
							namespace: (pNamespace !== null ? pNamespace : ''),
							jobID: (pJobID !== null ? pJobID : '' ),
							fileType: 'all',
							pageDisplayed: DataManagementPage.JobDetails,
							showPage: true
							};
		const currentTask: CurrentTask =	{
							taskType: TaskType.INITIATE_DM_SEARCH,
							parameters: dmQuery
							};
		const taskDirective: TaskDirective =	{
							refreshDirective: RefreshDirective.REFRESH,
							retryAfterRefresh: true
							};
			
		// run the data-management search.
		props.taskExecutor( 	{
					currentTask: currentTask,
					taskDirective: taskDirective
					} );
							
		// hide the drop-down menu.
		props.setUserDropdownMenuDisplayed( false );

	} // initiateDMSearchHandler

	//	------------------------------------------------------------
	//
	//	Mark the listed notification IDs are READ in the database.
	//
	//	------------------------------------------------------------

	function markNotificationsRead( args:	{
						notificationIDs: number[]
						} )
	{

		// update the notifications to mark them as read.
		var notifications: NotificationType[] = props.notifications.slice();
		for ( var i: number = 0; i < args.notificationIDs.length; i++ )
		{
			const notificationPos: number = notifications.findIndex( (item) => item.notificationID === args.notificationIDs[ i ] );
			if (notificationPos > -1)
				notifications[ notificationPos ].readFlag = true;
		}

		// call the end point to mark the notifications as read.
		const notificationIDs: number[] =	args.notificationIDs;
	  	const currentTask: CurrentTask =	{
							taskType: TaskType.MARK_NOTIFICATIONS_READ,
							parameters: notificationIDs
							};
		const taskDirective: TaskDirective =	{
							refreshDirective: RefreshDirective.REFRESH,
							retryAfterRefresh: true
							};
		props.taskExecutor(	{
					currentTask: currentTask,
					taskDirective: taskDirective
					} );

		// update state.
		props.setNotifications( notifications );
		props.setRenderCount( props.sRenderCount + 1 );

	} // markNotificationsRead

	//	-------------------------------------------------
	//
	//	count the number of notifications that are
	//	unread.
	//
	//	-------------------------------------------------

  	function numUnreadNotifications( args:	{
  						notifications: NotificationType[]
  						} )
  	{

  		var foundUnread: number = 0;
  		for ( var i: number = 0; i < args.notifications.length; i++ )
  			if (args.notifications[ i ].readFlag === false)
  				foundUnread = foundUnread + 1;

  		// return something.
  		return foundUnread;

  	} // numUnreadNotifications

	//	------------------------------------------------------------
	//
	//	A HTML component that renders a single menu item on the
	//	dropdown menu.
	//
	//	------------------------------------------------------------

	function MenuItem( args: { name: string, text: string, icon?: string, onClick: any } )
	{

		return	(
		
			<button name={args.name} className="menu-button" onClick={args.onClick}>
				<div style = {{ flex: '0 0 10px' }}/>
				<div className="menu-button-image">
					<img src={args.icon} alt="" width="24" height="24" />
				</div>
				<div style = {{ flex: '0 0 10px' }}/>
				<div className="menu-button-text">{args.text}</div>
			</button>
		
			)

	} // MenuItem

	//	------------------------------------------------------------
	//
	//	A HTML component that renders a single menu item that
	//	contains a notification.
	//
	//	------------------------------------------------------------

	function NotificationRow( args: { item: NotificationType, onClick: any } )
	{

		//var myTimestamp: Date = new Date( 2021, 10, 1 );
		//const formatting = myTimestamp.toLocaleString();
		//const formatted = moment( formatting ).format( "D MMMM YYYY LTS" )
		//const formatter = new Intl.DateTimeFormat( document.documentElement.lang );
		//const formatted: string = formatter.format( myTimestamp );
		const year: string = args.item.createdAt.slice( 0, 4 );
		const month: string = args.item.createdAt.slice( 5, 7 );
		const day: string = args.item.createdAt.slice( 8, 10 );
		const time: string = args.item.createdAt.slice( 11, 16 );
		const formattedDateTime: string = year + '-' + month + '-' + day + ' ' + time;

		return	(
		
			<div	style = {{ display: 'flex', flexDirection: 'row', height: '122px', width: '490px', alignItems: 'center', borderBottom: '1px solid #4d4a4a' }}>
				<div style = {{ flex: '0 0 10px' }} />
				<div style = {{ flex: '0 0 2px', height: '100%', display: 'flex', flexDirection: 'column' }} >
					<div style = {{ flex: '0 0 5px' }} />
					<div style = {{ flex: '1 1', width: '100%', backgroundColor: args.item.readFlag === true ? 'transparent' : '#e70068' }} />
					<div style = {{ flex: '0 0 5px' }} />
				</div>
				<div style = {{ flex: '0 0 50px', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
					{
					args.item.readFlag === false
					?	<img	src = {unreadIcon}
							alt = ""
							width = "40px" />
					:	<div style = {{ width: '22px', height: '22px', borderRadius: '11px', backgroundColor: 'white' }}></div>
					}
				</div>
				<div style = {{ flex: '1 1', display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
					<div style = {{ color: 'white', opacity: '1', fontWeight: args.item.readFlag ? '500' : '800' }}>{args.item.notificationText}</div>
					<div style = {{ color: 'white', opacity: '0.8', fontSize: '10pt' }}>{args.item.additionalInfo.description}</div>
					<div style = {{ color: 'white', opacity: '0.8', fontSize: '10pt' }}>{formattedDateTime}</div>
				</div>
				<div style = {{ flex: '0 0 150px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<div style = {{ flex: '0 0 4px' }} />
					<div style = {{ flex: '0 0', cursor: 'pointer', border: 'none', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
						<button	name = {"btnDelete_" + args.item.notificationID}
							type = "button"
							title = "Delete notification"
							style = {{display: 'flex', padding: '0px', cursor: 'pointer', border: 'none', backgroundColor: 'transparent'}}
							onClick =	{
									(event) =>
									deleteNotificationHandler(	/* event = */ event,
													/* pNotificationID = */ args.item.notificationID )
									} >
							<img	src = {closeIcon}
								alt = ""
								width = "20px"/>
						</button>
					</div>
					<div style = {{ flex: '0 0 5px' }} />
					{
					args.item.notificationType.toUpperCase() === 'DMR'
					?       <button	type = "button"
								className = 'menu-button-text'
								title = "View data"
								style = {{ color: 'white', flex: '0 0', cursor: 'pointer', margin: '0px', backgroundColor: 'transparent' }}
								onClick =	{
										(event) =>
										initiateDMSearchHandler(	/* event = */ event,
														/* pNamespace = */ args.item.additionalInfo.namespace,
														/* pJobID = */ args.item.additionalInfo.data_management_job_id )
										}
								data-align = "R">View data</button>
					:	<></>
					}
					{
					args.item.notificationType.toUpperCase() === 'DMR'
					?	<button	type = "button"
								className = 'menu-button-text'
								title = "Process data"
								style = {{ color: 'white', flex: '0 0', cursor: 'pointer', margin: '0px', backgroundColor: 'transparent' }}
								onClick =	{
										(event) =>
										initiateComputeSearchHandler(	/* event = */ event,
														/* pToSite = */ args.item.additionalInfo.to_site )
										}
								data-align = "R">Process data</button>
					:	<></>
					}
				</div>
				<div style = {{ flex: '0 0 10px' }}></div>
			</div>
			
			)

	} // NotificationRow

	//	------------------------------------------------------------
	//
	//	A HTML component that renders a single menu item that
	//	contains the View Notifications link.
	//
	//	------------------------------------------------------------

	function ViewNotifications( args: { onClick: any, enabled: boolean } )
	{

		const unreadNotifications: number = numUnreadNotifications( { notifications: props.notifications } );

		return	(
		
			<div style = {{ display: 'flex', flexDirection: 'row', height: '42px', width: '100%', alignItems: 'center' }} >
				<div style = {{ flex: '1 1' }} />
				<button 	name = 'viewNotifications'
						className = 'menu-button-text'
						style = {{ backgroundColor: 'transparent', color: args.enabled === true ? 'white' : 'grey' }}
						data-align = "R"
						onClick =	{
								args.enabled === true
								?	args.onClick
								:	undefined
								} >View all notifications</button>
				{
					unreadNotifications > 0
					?	<div	className = "user-control-notification-count"
							data-menu-displayed =	"T" >
							{
							unreadNotifications > 99
							?	"99+"
							:	unreadNotifications
							}
						</div>
					:	<></>
				}
				<div style = {{ flex: '0 0 0px' }} />
			</div>
			
			)

	} // ViewNotifications

	//	------------------------------------------------------------
	//
	//	H O O K S
	//
	//	------------------------------------------------------------

	// define a hook that marks the displayed notifications as read once a time interval has expired.
	useEffect	( () =>
			{

				// set a timer to mark the notifications are read
				// once the menu has been displayed.
				if (props.sUserDropdownMenuDisplayed === true)
				{

					// build a list of notification IDs that have been displayed.
					var notificationIDs: number[] = [];
					for ( var i: number = 0; i < ((props.notifications.length > 4) ? 4 : props.notifications.length); i++ )
						notificationIDs.push( props.notifications[ i ].notificationID );

					setTimeout	( () =>
							{

								markNotificationsRead(	{
											notificationIDs: notificationIDs
											} );

							}, 5000
							);

				}

			}, [props.sUserDropdownMenuDisplayed]
			)

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------

	const userDropdownPopup: CSS.Properties =
  			{
			display: (props.sUserDropdownMenuDisplayed === true ? 'block' : 'none'),
			position: 'absolute',
			top: '100%',
			right: '0%',
			border: 'none',
			zIndex: '9'
  			}

	return	(

		<div	ref = {ref}
			style = {userDropdownPopup}>
			<div className="user-dropdown-menu">
				<div	key = {props.sRenderCount}
					style = {{ height: '0px', borderTop: '1px solid #4d4a4a', padding: '0px' }}></div>
				{

					/* filter to select only the first five notifications */
					props.notifications.filter( ( item, index ) => (index < 4) ).map
					(
						( item, index ) =>
						(
							<NotificationRow key = {index} item = {item} onClick = {props.buttonHandler} />
						)
					)
					
				}
				{ViewNotifications( { onClick: props.buttonHandler, enabled: props.notifications.length > 0 } )}
				<div style = {{ height: '0px', borderTop: '1px solid #4d4a4a', padding: '0px' }}></div>
				{MenuItem( {name: "btnOIDCToken", text: i18n.t("View Token"), icon: oidcIcon, onClick: props.buttonHandler} )}
				{/*this.MenuItem( {name: "btnSearchSiteCapabilities", text: i18n.t("Search Site Capabilities"), icon: magnifierIcon, onClick: this.props.buttonHandler} )*/}
				{MenuItem( {name: "btnProfile", text: i18n.t("Profile"), icon: userAccountIcon, onClick: props.buttonHandler} )}
				{MenuItem( {name: "btnPreferences", text: i18n.t("Preferences"), icon: preferencesIcon, onClick: props.buttonHandler} )}
				{MenuItem( {name: "btnLogout", text: i18n.t("Logout"), icon: logoutIcon, onClick: props.buttonHandler} )}
			</div>
		</div>

		)

} // UserControlDropdownMenu
