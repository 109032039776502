import React from 'react';
import '../../App.css';
import './project-button.css';
import CSS from 'csstype';

// images

// classes

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

interface ProjectButtonProps
{

	name: string;
	icon: string;
	projectName: string;
	description?: string;
	pi?: string;
	onClick: any;
	selected?: boolean;

} // ProjectButtonProps

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

function ProjectUnselected( props:ProjectButtonProps )
{

	return	(
	
		<button name = {props.name} className = "project" onClick = {props.onClick}>
			<div className = "project-icon-container">
				<img className = "opaque-image-slight" src = {props.icon} alt = "" width = "32" height = "32" />
			</div>
			<div className = "project-text-container">
				<div className = "text-container-inner">{props.projectName}</div>
			</div>
		</button>
	
		)

} // ProjectUnselected

function ProjectSelected( props:ProjectButtonProps )
{

	return	(
	
		<button name = {props.name} className = "project-selected" onClick = {props.onClick}>
			<div className = "project-icon-container">
				<img src = {props.icon} alt = "" width = "32" height = "32" />
			</div>
			<div className = "project-text-container">
				<div className = "text-container-inner">{props.projectName}</div>
			</div>
		</button>
	
		)

} // ProjectSelected

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function ProjectButton( props:	ProjectButtonProps )
{

	if (props.selected === true)
		return	(
			<ProjectSelected	name = {props.name}
						icon = {props.icon}
						projectName = {props.projectName}
						description = {props.description}
						pi = {props.pi}
						onClick = {props.onClick} />
			)
	else
		return	(
			<ProjectUnselected	name = {props.name}
						icon = {props.icon}
						projectName = {props.projectName}
						onClick = {props.onClick} />
			)

} // ProjectButton
