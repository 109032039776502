import React, { useState, useEffect } from 'react';
import '../../App.css';
import './search-catalog.css';
import CSS from 'csstype';

// icons
import arrowsIcon from '../../icons/arrows.256.png';
import animatedArrowsIcon from '../../icons/animated-arrows.256.gif';

// functions
import { APIPrefix } from '../../utils/functions';

// classes
import { ToolButtonType } from '../../tools/tool-button';
import ToolButton from '../../tools/tool-button';
import { useTranslation } from "react-i18next";

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	------------------------------------------------------------
//
//	Component that generates a list of data-product types by calling
//	the API.
//
//	------------------------------------------------------------

function SearchDataProductTypesList( props:	{
						changeDataProductType: any,
						placeholderShown: boolean,
						value: string | undefined
						} )
{
	// translation function
	const { i18n, t } = useTranslation();

	// the list of data product types.
	const [sDataProductTypes, setDataProductTypes] = useState< { dataproduct_type: string, num_records: number }[] >( [] );

	//	------------------------------------------------------------
	//
	//	Handler for select box onChange event.
	//
	//	------------------------------------------------------------
	
	const onChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
	{

		const value = event.target.value;
		
		// raise an onChange event.
		props.changeDataProductType( {dataProductType: value} );
				
	} // onChange

	//	------------------------------------------------------------
	//
	//	Load the data-product types from the API
	//
	//	------------------------------------------------------------
	
	async function loadDataProductTypes()
	{
					
		var urlCommand: string = APIPrefix() + '/v1/data/get_product_types';

		try
		{
		
			const apiResult = await fetch( urlCommand, {headers: {'Content-Type': 'application/json'}} );
			
			if (apiResult.status === 200)
			{

				const returnedJson = await apiResult.json();

				// get data-product types list.
				var dataProductTypesList: any = [];
				if (returnedJson.result !== undefined)
					dataProductTypesList = returnedJson.result;

				// update the state with the list of returned data-product types.
				setDataProductTypes( dataProductTypesList );
			
			}
			
		}
		catch (e)
		{
		}
	
	} // loadDataProductTypes
  	
  	// this code will not be executed immediately, it will be execute a short while after the component has loaded.
  	useEffect	( () =>
		  	{
		  	
		  		// get the data-product types.
		  		loadDataProductTypes();
				
			}, []
			);
	
	{/* return the listbox */}
	return	(
	    	
	    	<select	name = "lstDataProductTypes"
	    		className = "data-product-types-listbox"
	    		value = {props.value}
	    		multiple = {false}
	    		onChange = {onChange}
	    		data-placeholder-shown = {props.placeholderShown ? "T" : "F"} >
	    		<option hidden value = "">{t( "Select data-product type" )}</option>
	    		<option label = "All" value = "<all>"> All </option>
	    		{
	    			sDataProductTypes.map
	    			(
	    				(item, index) =>
	    				(
	    				<option	key = {item.dataproduct_type}
	    						label = {item.dataproduct_type !== '' ? item.dataproduct_type : '<none>'}
	    						value = {item.dataproduct_type}>
	    					{item.dataproduct_type !== '' ? item.dataproduct_type : '<none>'}
	    				</option>
	    				)
	    			)
	    		}
		</select> 
		
		)

} // SearchDataProductTypesList

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function SearchCatalogForm( props:	{
							addSearchResultsTab: any,
							tabVisible: boolean,
							updateSearchPosition: any,			// positions need updating in Aladin window
							setUpdateAladinPositionEventHandler: any	// positions need updating here.
							} )
{

	// translation function
	const { i18n, t } = useTranslation();
  	
  	// current source name.
  	const [sSourceName, setSourceName] = useState<string>( '' );
  	const [sResolverText, setResolverText] = useState<string>("Uses SIMBAD name resolver");
  	const [sCurrentlyResolving, setCurrentlyResolving] = useState<boolean>( false );
	
  	// dataset select box.
  	const [sDatasetValue, setDatasetValue] = useState<string>( '' );
  	
  	// input boxes.
  	const [sRAValue, setRAValue] = useState<string>( "" );
  	const [sDecValue, setDecValue] = useState<string>( "" );
  	const [sRadiusValue, setRadiusValue] = useState<string>( "" );
  	
  	// hold the Aladin position (updated by an event triggered by Aladin).
  	const [sAladinPosition, setAladinPosition] = useState< { ra: number, dec: number } | undefined >( undefined );
  	const [sAladinFOV, setAladinFOV] = useState< number | undefined >( undefined );
  	
  	// data-product type.
  	const [sDataProductType, setDataProductType] = useState< string | undefined >( undefined );
  	
  	// filter box maximised, or not?
  	const [sFilterMaximised, setFilterMaximised] = useState<boolean>( true );

	//	------------------------------------------------------------
	//
	//	Convert the ra, dec, and radius from string to numeric,
	//	and add a new search-results tab.
	//
	//	------------------------------------------------------------
	
	function addSearchResultsTab( args:	{
						ra: string,
						dec: string,
						radius: string
						} )
	{
		
		// convert ra, dec, & radius into numeric value.
		var raNumeric: number = 0.0;
		var decNumeric: number = 0.0;
		var radiusNumeric: number = 0.0;
		
		try
		{
			raNumeric = Number( args.ra );
			decNumeric = Number( args.dec );
			radiusNumeric = Number( args.radius );
		}
		catch (e)
		{
		}
	
		// add a new search results tab.
		props.addSearchResultsTab(	{
						datasetValue: sDatasetValue,
						raValue: raNumeric,
						decValue: decNumeric,
						radiusValue: radiusNumeric,
						obsPublisherDidValue: '',
						dataProductType: sDataProductType
						} );
	
	} // addSearchResultsTab

	//	-------------------------------------------------
	//
	//	function that handles a change in the selected data-product
	//	type on the search form
	//
	//	-------------------------------------------------

	const dataProductTypeOnChangeHandler = function( args: {dataProductType: string | undefined } )
	{
		setDataProductType( args.dataProductType );

	} // dataProductTypeOnChangeHandler

	//	------------------------------------------------------------
	//
	//	Handler for changes to the input boxes.
	//
	//	------------------------------------------------------------
  	
  	const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.target;
  		
  		// update the state for any input boxes that have been changed.
  		if (inputBox.name === "sourceNameSearch")
  			setSourceName( inputBox.value );
  			
  		// update changes to the ra, dec, and fov input boxes.
  		if (inputBox.name === "raSearch")
  			setRAValue( inputBox.value );
  		if (inputBox.name === "decSearch")
  			setDecValue( inputBox.value );
  		if (inputBox.name === "radiusSearch")
  			setRadiusValue( inputBox.value );
	  	
  	} // inputHandler

	//	------------------------------------------------------------
	//
	//	Restrict input box entry to numbers only, plus . , + -
	//
	//	------------------------------------------------------------
  	
  	const numericKeysOnly = (event: React.KeyboardEvent<HTMLInputElement>) =>
  	{
  	
  		if ((event.charCode < 48 || event.charCode > 57) && (event.charCode < 43 || event.charCode > 46))
  			event.preventDefault();
  	
  	} // numericKeysOnly

	//	------------------------------------------------------------
	//
	//	Handler for lost-focus events on the input boxes.
	//
	//	------------------------------------------------------------
  	
  	const onBlurEvent = (event: React.FocusEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.target;
  	
  		if (inputBox.name === "raSearch" || inputBox.name === "decSearch")
  		{
		
			// convert ra and dec into numeric value.
			let raNumeric: number = 0.0;
			let decNumeric: number = 0.0;
			
			try
			{
				raNumeric = Number( sRAValue );
				decNumeric = Number( sDecValue );
			}
			catch (e)
			{
			}
  			const newPosition: { ra: number, dec: number } = { ra: raNumeric, dec: decNumeric };
  			props.updateSearchPosition( { position: newPosition } );
  			setSourceName( '' );
  			setResolverText( 'Uses SIMBAD name resolver' );
  			 
  		}
  		if (inputBox.name === "radiusSearch")
  		{
		
			// convert fov into numeric value.
			let fovNumeric: number = 0.0;
			
			try
			{
				fovNumeric = Number( inputBox.value );
			}
			catch (e)
			{
			}
  			
  			// update state.
  			if (fovNumeric > 0)
	  			props.updateSearchPosition( { fov: fovNumeric } );
  			 
  		}
  	
  	} // onBlurEvent

	//	------------------------------------------------------------
	//
	//	Handler for button onClick events.
	//
	//	------------------------------------------------------------
	
	const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) =>
	{

		const button: HTMLButtonElement = event.currentTarget;
		
		// resolve the name that has been entered against an online database.
		if (button.name === "resolveName")
			resolveObjectName( {} );
			
		// minimise/maximise the filter box if the user clicks minimise/maximise.
		if (button.name === "minimiseFilter")
			setFilterMaximised( false );
		if (button.name === "maximiseFilter")
			setFilterMaximised( true );
			
		// if we click a search button on the search form then open a new results tab.
		if (button.name === "searchData")
		{
		
			// check if there is a value in the source search box. if so, we need to resolve
			// this source before we add the search results.
			if (sSourceName !== '')
				resolveObjectName(	{
							addResultsTab: true
							} );
			else
				addSearchResultsTab(	{
							ra: sRAValue,
							dec: sDecValue,
							radius: sRadiusValue
							} );
			
		}
				
	} // onClickHandler

	//	------------------------------------------------------------
	//
	//	An asynchronous function that resolves a name against
	//	an online database, such as NED, SIMBAD or Sesame.
	//
	//	------------------------------------------------------------
  	
  	async function resolveObjectName( args:	{
  							addResultsTab?: boolean
  							} )
  	{
  	
  		// update the icon to a animated gif.
  		setCurrentlyResolving( true );
	
		try
		{
		
			const apiResult = await fetch( APIPrefix() + '/v1/resolve?source_name=' + sSourceName.toUpperCase() );
			
			// status is 200 if we found the source, 400 if it wasn't found, or 500 if the query wasn't well formed.
			if (apiResult.status === 200)
			{
				
				const returnedJson = await apiResult.json();
		
				// convert ra, & dec into numeric value.
				var raNumeric: number = 0.0;
				var decNumeric: number = 0.0;
				
				try
				{
					raNumeric = Number( returnedJson.ra );
					decNumeric = Number( returnedJson.dec );
				}
				catch (e)
				{
				}
				
				// update position in Aladin.
	  			const newPosition: { ra: number, dec: number } = { ra: raNumeric, dec: decNumeric };
	  			if (sRadiusValue === "")
		  			props.updateSearchPosition( { position: newPosition, fov: 5.0 } );
	  			else
		  			props.updateSearchPosition( { position: newPosition } );
		  			
		  		// update input boxes.
		  		setRAValue( returnedJson.ra );
		  		setDecValue( returnedJson.dec );
		  		var radius: string = sRadiusValue;
		  		if (radius === "")
		  		{
		  			radius = "5.0";
		  			setRadiusValue( radius );
		  		}
		  		
		  		setResolverText( returnedJson.description );
		  		
		  		// check if we need to add a results tab now that we've finished resolving the source.
		  		if (args.addResultsTab === true)
					addSearchResultsTab(	{
								ra: returnedJson.ra,
								dec: returnedJson.dec,
								radius: radius
								} );
			
			}
	  				
			else if (apiResult.status === 404)
				setResolverText( 'Could not resolve source' );
			
      		}
      		catch (e)
      		{
			console.log(e);
		}
		
		// return the icon to a non-animated one.
		setCurrentlyResolving( false );
  	
  	} // resolveObjectName

	//	------------------------------------------------------------
	//
	//	Update the values in the search boxes.
	//	This function is primarily triggered when the user drags
	//	or scrolls in the Aladin window.
	//
	//	------------------------------------------------------------
  	
  	function updateSearchPositions( args:	{
  						position?:	{
  								ra: number,
  								dec: number
  								},
  						fov?: number
  						} )
  	{
  		
  		// update the state.
  		if (args.position !== undefined)
			setAladinPosition( args.position );
  		if (args.fov !== undefined)
  			setAladinFOV( args.fov );
  	
  	} // updateSearchPositions
  			
  	useEffect	( () =>
  			{
  			
  				// set the event handler to update search positions.
  				if (props.setUpdateAladinPositionEventHandler !== undefined)
  					props.setUpdateAladinPositionEventHandler( updateSearchPositions );
  			
  			}, []
  			);
  			
  	useEffect	( () =>
  			{
  		
	  			// check if the position has changed by more than 1/1000th of a degree.
	  			if (sAladinPosition !== undefined)
	  			{
					try
					{
						const val: number = Number( sRAValue );
			  			if (Math.abs(val - sAladinPosition.ra) > 0.0001)
							setRAValue( sAladinPosition.ra.toString() );
					}
					catch
					{
					}
					try
					{
						const val: number = Number( sDecValue );
			  			if (Math.abs(val - sAladinPosition.dec) > 0.0001)
							setDecValue( sAladinPosition.dec.toString() );
					}
					catch
					{
					}
				}
  			
  			}, [sAladinPosition]
  			);
  			
  	useEffect	( () =>
  			{
  		
	  			// check if the field-of-view has changed by more than 1/1000th of a degree.
	  			if (sAladinFOV !== undefined)
	  			
					try
					{
						const val: number = Number( sRadiusValue );
			  			if (Math.abs(val - sAladinFOV) > 0.0001)
							setRadiusValue( sAladinFOV.toString() );
					}
					catch
					{
					}
  			
  			}, [sAladinFOV]
  			);
	
	{/* main search form */}
	return	(
	    		
	    	<div	style = {{ flex: '0 0 auto', height: 'auto', display: 'flex', flexDirection: 'column' }}>
	    	
		    	<button	className = "minimised-filter"
		    			name = "maximiseFilter"
		    			type = "button"
		    			title = "Maximise filter"
		    			onClick = {onClickHandler}
						data-testid='maximisedFilter'
		    			data-maximised = {sFilterMaximised === true ? "T" : "F"} >
				<div className = "flex-15px"></div>
				<div className = "maximise">&raquo;</div>
				<div className = "flex-row">
					<div className = "rotated-text-box">Filter</div>
			    	</div>
		    	</button>
		    	
	    		<div	className = "search-form-visible" data-testid='minimisedFilter'
	    			data-maximised = {sFilterMaximised === true ? "T" : "F"} >
		    	    	<div className = "flex-15px"></div>
		    	    	<div className = "flex-row">
		    			<div className = "search-catalog-form-title">{t( "Filter" )}</div>
		    			<div className = "flex-expanding"></div>
		    			<button	className = "minimise"
		    					name = "minimiseFilter"
		    					type = "button"
		    					title = "Minimise filter"
		    					onClick = {onClickHandler}>&laquo;</button>
		    			<div style = {{ flex: '0 0 15px' }}></div>
	    			</div>
		    	    	<div className = "flex-15px"></div>
	    			<div className = "source-name-search-box-horizontal">
	    				<div className = "source-name-input-container">
	    					<input	className = "input-field-source-name"
	    						type = "text"
	    						name = "sourceNameSearch"
	    						onChange = {inputHandler}
							maxLength = {30}
	    						placeholder = {t("Source name")}
	    						value = {sSourceName} ></input>
	    				</div>
	    	    			<div className = "flex-10px"></div>
	    	    			<div className = "flex-fixed">
		    	    			<ToolButton	key = {t("Resolve") + (props.tabVisible ? 't' : 'f')}
		    	    					name = "resolveName"
		    	    					onClick = {onClickHandler}
		    	    					icon = {sCurrentlyResolving === true ? animatedArrowsIcon : arrowsIcon}
		    	    					text = {t("Resolve")}
		    	    					type = {ToolButtonType.SECONDARY} />
		    	    		</div>
	    	    		</div>
		    	    	<div className = "flex-10px"></div>
	    	    		<div className = "resolver-text-box">{sResolverText}</div>
	    			<div className = "flex-20px"></div>
	    			<div className = "source-location-search-box">
	    				<div className = "source-location-search-box-ra-dec">
	    					<div className = "flex-expanding">
							<input	className = "input-field-ra-dec-rad"
								type = "text"
								name = "raSearch"
								onKeyPress = {numericKeysOnly}
								onChange = {inputHandler}
								onBlur = {onBlurEvent}
								maxLength = {20}
								value = {sRAValue}
								placeholder = {t("RA (degrees)")}></input>
						</div>
		    	    			<div className = "flex-10px"></div>
	    					<div className = "flex-expanding">
							<input	className = "input-field-ra-dec-rad"
								type = "text"
								name = "decSearch"
								onKeyPress = {numericKeysOnly}
								onChange = {inputHandler}
								onBlur = {onBlurEvent}
								maxLength = {20}
								value = {sDecValue}
								placeholder = {t("DEC (degrees)")}></input>
						</div>
					</div>
	    	    			<div className = "flex-15px"></div>
					<div className = "source-location-search-box-rad">
						<input	className = "input-field-ra-dec-rad"
							type = "text"
							name = "radiusSearch"
							onKeyPress = {numericKeysOnly}
							onChange = {inputHandler}
							onBlur = {onBlurEvent}
							maxLength = {20}
							value = {sRadiusValue}
							placeholder = {t("Radius (degrees)")}></input>
					</div>
				</div>
	    			<div className = "flex-15px"></div>
				<div className = "search-form-data-product-type">
					<SearchDataProductTypesList	changeDataProductType = {dataProductTypeOnChangeHandler}
									placeholderShown = {sDataProductType === undefined}
									value = {sDataProductType} />
				</div>
	    			<div className = "flex-40px"></div>
				<div className = "search-catalog-form-buttons">
					<ToolButton	key = {t("Search") + (props.tabVisible ? 't' : 'f')}
							name = "searchData"
							onClick = {onClickHandler}
							tooltip = "Submit search with these parameters"
							text = {t("Search")}
							type = {ToolButtonType.PRIMARY} />
				</div>
	    			<div className = "flex-10px"></div>
			</div>
		
		</div>
		
		)

} // SearchCatalogForm
