import React, { useState, useEffect } from 'react';
import '../../App.css';
import './visualisation.css';
import CSS from 'csstype';

// icons
import gearsIcon from '../../icons/gears.gif';
import binocularsIcon from '../../icons/binoculars.512.png';
import binocularsDisabledIcon from '../../icons/binoculars-bw.512.png';
import searchCatalogIcon from '../../icons/search-catalog.512.png';
import searchCatalogDisabledIcon from '../../icons/search-catalog-bw.512.png';
import crosshairIcon from '../../icons/crosshair.512.png';

// classes
import ToolButton from '../../tools/tool-button';
import { addCatalog } from './aladin';
import { Aladin } from './aladin';

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function VisualisationAladin( props:	{
							running: boolean,
							launchParams:	{} | null,
							params:	{
									viewDataFromAladinFootprint: any,
									updateState: any
									} | null
							} )
{
	
  	// loading component.
  	const [sLoadingComponent, setLoadingComponent] = useState<boolean>( true );
  	
  	// position. default to -1000.0 to ensure that the supplied parameters will always initially trigger a render.
  	const [sPos, setPos] = useState< { ra: number, dec: number } | null >( null );
  	
  	// current Aladin instance.
  	const [sAladin, setAladin] = useState<any>( null );
  	const [sAladinRefresh, setAladinRefresh] = useState<number>( 0 );
  	
  	// footprint has been selected.
  	const [sSelectedID, setSelectedID] = useState< {	id: string,
  								ra: number,
  								dec: number }[] >( [] );

	//	------------------------------------------------------------
	//
	//	Handler for button clicks
	//
	//	------------------------------------------------------------
  	
  	const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) =>
  	{
  	
		const button: HTMLButtonElement = event.currentTarget;
			
		// reload the SKAO RUcio catalog at the current position of the Aladin display.
		if (button.name === 'reloadCatalog' && sAladin !== null)
		{
					
			// deselect the selected object.
			if (sSelectedID.length > 0)
				setSelectedID( [] );
		
			// get the position at the centre of the Aladin display.	
			const position: Float64Array = sAladin.getRaDec();
			
			// add the SKAO Rucio catalog.
			addCatalog(	{
					aladin: sAladin,
					ra: position[ 0 ],
					dec: position[ 1 ],
					selectedID: [],
					setSelectedID: undefined
					} );
		
		}
		
		// view the data for the selected footprint in the 'Search catalogue' tab.
		if (button.name === 'viewData')
			
			// view this data in the search catalog tab.
			if (props.params !== null && sSelectedID.length > 0)
				props.params.viewDataFromAladinFootprint( { position: sSelectedID } );
  	
  	} // onClickHandler

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------
	
	if (props.running === true)
	{
	
		return	(
			<div style = {{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
				<Aladin	divElementID = "aladin-lite-div"
						aladin = {sAladin}
						setAladin = {setAladin}
						selectedID = {sSelectedID}
						setSelectedID = {setSelectedID}
						setAladinRefresh = {setAladinRefresh}
						setLoadingComponent = {setLoadingComponent}
						running = {true} />
				<div style = {{ flex: '0 0 5px' }} />
				<div style = {{ flex: '0 0 40px', backgroundColor: 'transparent', display: sLoadingComponent === false ? 'flex' : 'none', alignItems: 'center' }}>
		    			<button	className = "minimise"
		    					name = "reloadCatalog"
		    					type = "button"
		    					title = "Reload catalog at this position"
		    					style = {{padding: '0px 0px 0px 0px'}}
		    					onClick = {onClickHandler} >
		    				<img src = {crosshairIcon} alt = "" width = "40" />
		    			</button>
					<div style = {{ margin: '0px 10px 0px 10px', display: 'none' }}>View/download data</div>
					<div style = {{ flex: '0 0 10px' }} />
		    			<button	className = "minimise"
		    					name = "viewData"
		    					type = "button"
		    					title = "View/download data"
		    					style = {{padding: '0px 0px 0px 0px'}}
		    					onClick = {onClickHandler}
		    					disabled = {sSelectedID.length === 0} >
		    				<img src = {sSelectedID.length > 0 ? searchCatalogIcon : searchCatalogDisabledIcon} alt = "" width = "40" />
		    			</button>
					<div style = {{ margin: '0px 10px 0px 10px', display: 'none' }}>Reload catalog at this position</div>
				</div>
				<div className = 'loading-graphic' data-loading = {sLoadingComponent ? 'T' : 'F'}>
					<img className = "animated-gears" src = {gearsIcon} alt = "" width = "60" height = "60" />
					Loading
				</div>
			</div>
			)
		
	}
	else
	{
	
		// remove the Aladin script from the page body if it exists. We will re-add it next time the tool is run so that the
		// scriptLoaded() function will fire again. This is the only way that we can initialise Aladin once.
		//removeScript( { url: ALADIN_URL } );
	
		//return	(
		//	<></>
		//	)
		return	(
		
			<Aladin	divElementID = "aladin-lite-div"
					aladin = {sAladin}
					setAladin = {setAladin}
					setLoadingComponent = {setLoadingComponent}
					running = {false} />
		
			)
			
	}

} // VisualisationAladin
