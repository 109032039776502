import React, { useState, useEffect } from 'react';
import useLocalStorage from 'use-local-storage';
import '../../App.css';
import './data-management.css';
import CSS from 'csstype';
import { useTranslation } from "react-i18next";

// icons
import gearsIcon from '../../icons/gears.gif';
import tickIcon from '../../icons/tick.512.png';
import crossIcon from '../../icons/cross.256.png';
import questionMarkIcon from '../../icons/question-mark.256.png';
import moveFileIcon from '../../icons/move-file.256.png';
import downArrowDarkIcon from '../../icons/down-arrow-dark.256.png';
import upArrowDarkIcon from '../../icons/up-arrow-dark.256.png';
import downArrowLightIcon from '../../icons/down-arrow-light.256.png';
import upArrowLightIcon from '../../icons/up-arrow-light.256.png';
import jupyterIcon from '../../icons/jupyter.380.png';
import playIcon from '../../icons/play-square.512.png';

// types
import { JobItemType, JobType, SiteStorageAreas, JupyterHUBs } from '../../utils/types';
import { DataManagementPage } from './types';

// types relating to tasks.
import { InitiateDMSearch } from '../../utils/tasks';
import { RefreshDirective } from '../../utils/tasks';
import { TaskType } from '../../utils/tasks';
import { CurrentTask } from '../../utils/tasks';
import { TaskDirective } from '../../utils/tasks';

// functions

// classes
import { ToolButtonType } from '../../tools/tool-button';
import ToolButton from '../../tools/tool-button';
import { DisplayFileSize } from '../../utils/functions';

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------
	
// get default user preferences..
const DEFAULT_DARK = window.matchMedia( '(prefers-color-scheme: dark)' ).matches;

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function JobStatus( props:	{
						dataManagementJobs: JobType[],
						storageAreas: SiteStorageAreas[],
						taskExecutor: any,
						jobsLoading: boolean,
						initiateComputeSearchEvent: any,
						jupyterHUBs: JupyterHUBs[],
						launchNotebook: any
						} )
{

	// translation function
	const { t } = useTranslation();
  	
  	// data-management jobs.
	const [sJobIDExpanded, setJobIDExpanded] = useState< string[] >( [] );
	const [sJobRenderCount, setJobRenderCount] = useState< number >( 0 );
	
	// light/dark mode theme.
	const [sTheme, setTheme] = useLocalStorage( 'gateway_theme', DEFAULT_DARK ? 'dark' : 'light' );

	// build list of 'to' storage areas.
	var toStorageArea: string[] = [];
	for ( var i = 0; i < props.dataManagementJobs.length; i++ )
		toStorageArea.push( getStorageAreaFromID( { storageAreaUUID: props.dataManagementJobs[ i ].to_storage_area_uuid } ) );

	//	-------------------------------------------------
	//
	//	extract a list of JupyterHUB services for the
	//	given site.
	//
	//	-------------------------------------------------
		
	function getAssociatedServices( args:	{
						site: string,
						jupyterHUBs: JupyterHUBs[]
						} )
	{
	
		var jupyterHUBs:	{
					id: string,
					prefix: string,
					host: string,
					path: string,
					identifier: string
					}[] = [];
					
		// check if this site exists in the supplied list of sites.
		const index = args.jupyterHUBs.findIndex( (element) => element.site === args.site );
		if (index > -1)
			jupyterHUBs = args.jupyterHUBs[ index ].associated_services;
					
		// return something.
		return jupyterHUBs;
	
	} // getAssociatedServices

	//	-------------------------------------------------
	//
	//	search through the storages state array to find this uuid.
	//
	//	-------------------------------------------------
  	
  	function getStorageAreaFromID( args:	{
						storageAreaUUID: string
						} )
  	{
  	
  		var storageArea: string = args.storageAreaUUID;
  		
  		for ( var site = 0; site < props.storageAreas.length; site++ )
  			for ( var area = 0; area < props.storageAreas[ site ].storage_areas.length; area++ )
  				if (props.storageAreas[ site ].storage_areas[ area ].storage_id === args.storageAreaUUID)
  					storageArea = props.storageAreas[ site ].site + " -> " + props.storageAreas[ site ].storage_areas[ area ].identifier;
  	
  		// return something.
  		return storageArea;
  	
  	} // getStorageAreaFromID

	//	------------------------------------------------------------
	//
	//	search the compute API for compute resources to process
	//	these data.
	//
	//	------------------------------------------------------------

	const initiateComputeSearchHandler = ( event: React.MouseEvent<HTMLButtonElement>, pToSite: string ) =>
	{

		props.initiateComputeSearchEvent(	{
							site: pToSite,
							serviceType: 'jupyterhub'
							} );

	} // initiateComputeSearchHandler

	//	------------------------------------------------------------
	//
	//	search the data-management API for the files being
	//	transferred by this job ID.
	//
	//	------------------------------------------------------------

	const initiateDMSearchHandler = ( event: React.MouseEvent<HTMLButtonElement>, pNamespace: string, pJobID: string ) =>
	{
		
		// populate parameters for the DM search.
		const dmQuery: InitiateDMSearch =	{
							namespace: pNamespace,
							filename: '',
							jobID: pJobID,
							fileType: 'all',
							pageDisplayed: DataManagementPage.JobDetails,
							showPage: true
							};
		const currentTask: CurrentTask =	{
							taskType: TaskType.INITIATE_DM_SEARCH,
							parameters: dmQuery
							};
		const taskDirective: TaskDirective =	{
							refreshDirective: RefreshDirective.REFRESH,
							retryAfterRefresh: true
							};
			
		// run the data-management search.
		props.taskExecutor( 	{
					currentTask: currentTask,
					taskDirective: taskDirective
					} );

	} // initiateDMSearchHandler

	//	-------------------------------------------------
	//
	//	set the colour of the job status
	//
	//	-------------------------------------------------
  	
  	function itemColour( args:	{
  					status: string
  					} )
  	{
  	
  		var colour: string = 'black';
  		
  		if (args.status.toUpperCase() === 'READY')
  			colour = 'green';
  		if (args.status.toUpperCase() === 'ERROR')
  			colour = 'red';
  		if (args.status.toUpperCase() === 'MOVING')
  			colour = 'blue';
  	
  		// return something.
  		return colour;
  	
  	} // itemColour

	//	-------------------------------------------------
	//
	//	set the icon of the job status
	//
	//	-------------------------------------------------
  	
  	function itemIcon( args:	{
  					status: string
  					} )
  	{
  	
  		var icon: any = questionMarkIcon;
  		
  		if (args.status.toUpperCase() === 'READY')
  			icon = tickIcon;
  		if (args.status.toUpperCase() === 'ERROR')
  			icon = crossIcon;
  		if (args.status.toUpperCase() === 'MOVING')
  			icon = moveFileIcon;
  	
  		// return something.
  		return icon;
  	
  	} // itemIcon

	//	------------------------------------------------------------
	//
	//	Handler for DIV clicks.
	//
	//	------------------------------------------------------------
  	
  	const onClickDivHandler = (event: React.MouseEvent<HTMLDivElement>) =>
  	{

		const divElement: HTMLDivElement = event.currentTarget;
				
		// click to expand a job.
		if (divElement.id.length > 7)
			if (divElement.id.slice( 0, 7 ) === "expand_")
			{
		
				// get the ID and convert to numeric.
				const divIDstr: string = divElement.id.slice( 7 - divElement.id.length );
				var jobIndex: number = -1;
				try
				{
					jobIndex = Number( divIDstr );
				}
				catch
				{
				}
				
				// get a copy of the expanded jobs list.
				var jobIDs: string[] = sJobIDExpanded;
				
				// check if this job ID already exists in the expanded jobs list.
				const index: number = jobIDs.indexOf( props.dataManagementJobs[ jobIndex ].job_id );
				if (index > -1)
				
					// remove the job ID from the list of expanded jobs.
					jobIDs.splice( index, 1 );
				
				else
					
					// add the job ID to the list of expanded jobs.
					jobIDs.push( props.dataManagementJobs[ jobIndex ].job_id );
				
				// update the state.
				setJobIDExpanded( jobIDs );
				setJobRenderCount( sJobRenderCount + 1 );
					
			}
			
		// click to start a JupyterHUB service.
		if (divElement.id.length > 8)
			if (divElement.id.slice( 0, 8 ) === 'jupyter_')
			{
			
				// get the site name, by checking for an underscore.
				var site: string = "";
				var identifier: string = divElement.id.slice( 8 - divElement.id.length );
				if (identifier.indexOf( '_' ) > -1)
				{
					site = identifier.slice( 0, identifier.indexOf( '_' ) );
					identifier = identifier.slice( identifier.indexOf( '_' ) - identifier.length + 1 );
				}
				else
				{
					site = identifier;
					identifier = '';
				}
				
				// convert the remaining identifier to the numeric hub index.
				var hubIndex: number = -1;
				try
				{
					hubIndex = Number( identifier );
				}
				catch
				{
				}
				
				// find the JupyterHUB item in the array.
				const siteIndex = props.jupyterHUBs.findIndex( (element) => element.site === site );
				if (siteIndex > -1 && hubIndex > -1)
				{
				
					const jupyterHUB:	{
								id: string,
								prefix: string,
								host: string,
								path: string,
								identifier: string,
								port: number
								} = props.jupyterHUBs[ siteIndex ].associated_services[ hubIndex ];
				  	console.log( "jupyterHUB:" );
				  	console.log( jupyterHUB );
  					// open the notebook url in a new tab.
  					var url: string = jupyterHUB.prefix + '://' + jupyterHUB.host;
  					if (jupyterHUB.port > -1)
  						url = url + ':' + jupyterHUB.port;
  					if (jupyterHUB.path !== '' && jupyterHUB.path !== undefined)
  						url = url + jupyterHUB.path;

  					if (jupyterHUB.identifier.toUpperCase().indexOf( '(EMBED)' ) === -1)
	  					window.open( url, '_blank', 'noreferrer')
	  				else
	  					props.launchNotebook( { url: url } );
				
				}
				
			
			}
		
	} // onClickDivHandler

	//	------------------------------------------------------------
	//
	//	Handler for changes to the radio buttons.
	//
	//	------------------------------------------------------------
  	
  	const onClickHandler = (event: React.MouseEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.currentTarget;

		// refresh jobs is pressed, so get a list of jobs from the API.		
		if (inputBox.name === "refreshJobs")
		{
		  	
	  		// get the list of data-management jobs.
			const getDMJobsTask: CurrentTask = { taskType: TaskType.GET_DATA_MANAGEMENT_JOBS };
			const taskDirective: TaskDirective =	{
								refreshDirective: RefreshDirective.REFRESH,
								retryAfterRefresh: true
								};
			props.taskExecutor(	{
						currentTask: getDMJobsTask,
						taskDirective: taskDirective
						} );
	  	
	  	}
  	
  	} // onClickHandler

	//	--------------------------------------------------------------------------
	//
	//	C O M P O N E N T S
	//
	//	--------------------------------------------------------------------------

	//	-------------------------------------------------
	//
	//	Display a single item.
	//
	//	-------------------------------------------------

	function Item( args:	{
				item: JobItemType,
				type: string,
				expanded: boolean
				} )
	{
	
		return	(
	
			<tr hidden = {args.expanded === false} >
				<td style = {{ margin: '0px 5px 0px 5px', borderStyle: 'none', wordBreak: 'break-word' }} >
					{
					args.item.name
					}
				</td>
				<td style = {{ margin: '0px 5px 0px 5px', borderStyle: 'none', textAlign: 'center' }} >
					{
					args.type
					}
				</td>
				<td style = {{ margin: '0px 5px 0px 5px', borderStyle: 'none', textAlign: 'center' }} >
					{
					DisplayFileSize( { bytes: args.item.bytes } )
					}
				</td>
			</tr>
		
			)
	
	} // Item

	return	(
		
		<div	className = "data-job-status"
			style = {	{
					flex: '1 1 150px',
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					maxHeight: '100%'
					} } >
		
	    	    	<div className = "flex-15px"></div>
	    	    	<div style = {{ flex: '0 0 auto', display: 'flex', flexDirection: 'row', width: '100%' }}>
	    			<div className = "header-text" style = {{ flex: '1 1 0px', margin: '0 0 0 10px' }}>{t( "Data requests" )}</div>
    			</div>
	    	    	<div className = "flex-15px"></div>
	    	    	
	    	    	{/* the scrollbox container */}
	    	    	<div	style = {	{
						height: '0px',
						flex: '1 1 auto'
						} } >
		
				{/* the scrollbox */}
				<div	id = 'scrollbox'
					style = {	{
							width: "100%",
							height: "100%",
							overflowY: "auto",
							display: "flex",
							flexDirection: "column"
							} } >
			
					{
					props.dataManagementJobs.map
					(
						( item, index ) =>
						(
							<div	key = {index}
								style = {{ flex: '0 0 auto', width: '100%', display: 'flex', flexDirection: 'column' }} >
								<div	style = {{ flex: '0 0 auto', display: 'flex', flexDirection: 'row',
										cursor: 'pointer', alignItems: 'center' }}
									id = {'expand_' + index.toString()}
									onClick = {onClickDivHandler} >
									<div style = {{ flex: '0 0 5px' }} />
									
									{/* an up or down arrow that shows whether the job details are expanded */}
									<div style = {{ flex: '0 0 15px', fontSize: '30pt', textAlign: 'center', alignItems: 'center', marginTop: '0px' }} >
											<img	className = "job-status-icon-dark"
												src = {sJobIDExpanded.indexOf( item.job_id ) > -1 ? (sTheme === 'dark' ? upArrowDarkIcon : upArrowLightIcon) : (sTheme === 'dark' ? downArrowDarkIcon : downArrowLightIcon)}
												alt = ""
												width = "20"
												height = "20" />
									</div>
									<div style = {{ flex: '0 0 5px' }} />
									
									{/* the highlighted part of the job row */}
									<div	className = "job-row"
										style = {{ flex: '1 1 auto', display: 'flex', flexDirection: 'row',
											borderRadius: '10px', alignItems: 'center' }} >
										<div style = {{ flex: '0 0 5px' }} />
										<div style = {{ flex: '1 1 auto' }} >
											{item.containers.length + item.datasets.length + item.files.length} item(s) in namespace '{item.parent_namespace === '' ? '{unknown}' : item.parent_namespace}' to location '{toStorageArea[ index ]}'
										</div>
										<div style = {{ flex: '0 0 5px' }} />
										
										{/* the indicator of the job status, i.e. MOVING, READY, etc */}
										<div style = {{ flex: '0 0 140px', textAlign: 'center', backgroundColor: itemColour( { status: item.status } ), height: '30px', alignItems: 'center', borderRadius: '10px', display: 'flex', flexDirection: 'row' }} >
											<div style = {{ flex: '0 0 10px' }} />
											<div style = {{ flex: '0 0 20px', display: 'flex' }} >
												<img	src = {itemIcon( { status: item.status } )}
													alt = ""
													width = "20"
													height = "20" />
											</div>
											<div style = {{ flex: '1 1', color: 'white' }} >{item.status}</div>
											<div style = {{ flex: '0 0 10px' }} />
										</div>
										
										<div style = {{ flex: '0 0 5px' }} />
									</div>
									
								</div>
								<div style = {{ flex: '0 0 5px' }} />
								
								{/* the View data and Process data buttons. */}
								<div style = {{ flex: '0 0 auto', display: 'flex', flexDirection: 'row' }}>
									<div style = {{ flex: '0 0 30px' }} />
									<div style = {{ flex: '0 0 auto' }} >
										<button	type = "button"
												className = 'menu-button-text'
												title = "View data"
												style = {{ color: sTheme === 'dark' ? 'white' : 'black', flex: '0 0', cursor: 'pointer', margin: '0px', backgroundColor: 'transparent' }}
												onClick =	{
														(event) =>
														initiateDMSearchHandler(	/* event = */ event,
																		/* pNamespace = */ item.parent_namespace,
																		/* pJobID = */ item.job_id )
														}
												data-align = "R">View data</button>
									</div>
									<div style = {{ flex: '0 0 5px' }} />
									<div style = {{ display: item.status.toUpperCase() === 'READY' ? 'block' : 'none' }}>
										<button	type = "button"
												className = 'menu-button-text'
												title = "Process data"
												style = {{ color: sTheme === 'dark' ? 'white' : 'black', flex: '0 0', cursor: 'pointer', margin: '0px', backgroundColor: 'transparent' }}
												onClick =	{
														(event) =>
														initiateComputeSearchHandler(	/* event = */ event,
																		/* pToSite = */ item.to_site )
														}
												data-align = "R">Process data</button>
									</div>
									<div style = {{ flex: '0 0 5px' }} />
								</div>
								<div style = {{ flex: '0 0 5px' }} />
								
								{/* table showing all the containers being moved */}
								<div style = {{ display: 'flex', flexDirection: 'row' }} >
									<div style = {{ flex: '0 0 30px' }} />
									<div style = {{ flex: '1 1' }} >
										<table style = {{ width: '100%' }}>
											<tbody>
												{
												item.containers.map
												(
													( container, containerIndex ) =>
													(
													<Item	key = {containerIndex}
														item = {container}
														type = 'CONTAINER'
														expanded = {sJobIDExpanded.indexOf( item.job_id ) > -1} />
													)
												)
												}
											</tbody>
										</table>
									</div>
									<div style = {{ flex: '0 0 5px' }} />
								</div>
								
								{/* table showing all the datasets being moved */}
								<div style = {{ display: 'flex', flexDirection: 'row' }} >
									<div style = {{ flex: '0 0 30px' }} />
									<div style = {{ flex: '1 1' }} >
										<table style = {{ width: '100%' }}>
											<tbody>
												{
												item.datasets.map
												(
													( dataset, datasetIndex ) =>
													(
													<Item	key = {datasetIndex}
														item = {dataset}
														type = 'DATASET'
														expanded = {sJobIDExpanded.indexOf( item.job_id ) > -1} />
													)
												)
												}
											</tbody>
										</table>
									</div>
									<div style = {{ flex: '0 0 5px' }} />
								</div>
								
								{/* table showing all the files being moved */}
								<div style = {{ display: 'flex', flexDirection: 'row' }} >
									<div style = {{ flex: '0 0 30px' }} />
									<div style = {{ flex: '1 1' }} >
										<table style = {{ width: '100%' }}>
											<tbody>
												{
												item.files.map
												(
													( file, fileIndex ) =>
													(
													<Item	key = {fileIndex}
														item = {file}
														type = 'FILE'
														expanded = {sJobIDExpanded.indexOf( item.job_id ) > -1} />
													)
												)
												}
											</tbody>
										</table>
									</div>
									<div style = {{ flex: '0 0 5px' }} />
								</div>
								
								<div style = {{ flex: '0 0 10px', display: sJobIDExpanded.indexOf( item.job_id ) > -1 ? 'flex' : 'none' }} />
								<div style = {{ flex: '0 0 auto', display: sJobIDExpanded.indexOf( item.job_id ) > -1 ? 'flex' : 'none', flexDirection: 'row' }} >
									<div style = {{ flex: '0 0 30px' }} />
									<div style = {{ flex: '1 1 0px' }} >
										Expires at: {item.expires_at}
									</div>
								</div>
								<div style = {{ flex: '0 0 10px', display: sJobIDExpanded.indexOf( item.job_id ) > -1 ? 'flex' : 'none' }} />
								<div style = {{ flex: '0 0 auto', display: sJobIDExpanded.indexOf( item.job_id ) > -1 && item.status.toUpperCase() === 'READY' ? 'flex' : 'none', flexDirection: 'row' }} >
									<div style = {{ flex: '0 0 30px' }} />
									<div style = {{ flex: '1 1 0px' }} >
										Quick links to local JupyterHUBs:
									</div>
								</div>
								<div style = {{ flex: '0 0 10px', display: sJobIDExpanded.indexOf( item.job_id ) > -1 ? 'flex' : 'none' }} />
								{
									getAssociatedServices(	{
												site: item.to_site,
												jupyterHUBs: props.jupyterHUBs
												} ).map
									(
										( hub, hubindex ) =>
										(
										<div	key = {hubindex}
											className = "jupyter-hub-row"
											style = {	{
													flex: '0 0 auto',
													display: sJobIDExpanded.indexOf( item.job_id ) > -1 &&
															item.status.toUpperCase() === 'READY' ? 'flex' : 'none',
													flexDirection: 'row',
													margin: '0px 10px 10px 10px',
													borderRadius: '10px',
													cursor: 'pointer',
													alignItems: 'center'
													} }
											id = {'jupyter_' + item.to_site + '_' + hubindex.toString()}
											onClick = {onClickDivHandler} >
											<div style = {{ flex: '0 0 30px' }} />
											<div style = {{ flex: '0 0 80px', height: '60px', backgroundColor: 'white', textAlign: 'center', alignItems: 'center', margin: '10px 0px 10px 0px' }} >
												<img	style = {{ position: 'relative', left: '12px', top: '3px' }}
													src = {jupyterIcon}
													alt = ""
													width = "54" />
												<img	style = {{ position: 'relative', left: '-55px', top: '-31px' }}
													src = {playIcon}
													alt = ""
													width = "22" />
											</div>
											<div style = {{ flex: '0 0 10px' }} />
											<div	style = {{ flex: '1 1', display: 'flex', flexDirection: 'column', margin: '10px 0px 10px 0px' }} >
												<div style = {{ flex: '1 1', fontWeight: '800', margin: '0px 10px 0px 10px', display: 'flex', alignItems: 'center' }} >{hub.identifier !== undefined ? hub.identifier : '{No description}'}</div>
												<div style = {{ flex: '1 1', fontWeight: '400', margin: '0px 10px 0px 10px', display: 'flex', alignItems: 'center' }} >({hub.prefix}://{hub.host})</div>
											</div>
											<div style = {{ flex: '0 0 10px' }} />
										</div>
										)
									)
								}
								<div style = {{ flex: '0 0 5px' }} />
							</div>
						)
					)
					}
					
				</div>
				
			</div>
	    	    	<div className = "flex-15px"></div>
			<div className = "form-button-container">
	    	    		<ToolButton	key = {t("Refresh")}
	    	    				name = "refreshJobs"
	    	    				onClick = {onClickHandler}
	    	    				text = {t("Refresh")}
	    	    				type = {ToolButtonType.SECONDARY} />
	    	    				
	    	    		<div style = {{ width: '20px' }} />
				<div className = {props.jobsLoading === true ? "search-results-table-loading" : "search-results-table-loaded"} style = {{ padding: '0px 0px 0px 0px' }}>
					<img	className = "animated-gears"
						src = {gearsIcon}
						alt = ""
						width = "40"
						height = "40" />
					Loading
				</div>
	    	    	</div>
			
		</div>
	
		)

} // JobStatus
