import React, { useState, useEffect } from 'react';
import CSS from 'csstype';
import '../../App.css';
import './user-management.css';
import '../../tools/search-results/search-results.css';
import { useTranslation } from "react-i18next";

// images
import gearsIcon from '../../icons/gears.gif';
import eyeDark from '../../icons/eye-dark.512.png';
import tick from '../../icons/tick.512.png';
import pencil from '../../icons/pencil.256.png';
import cross from '../../icons/cross.256.png';
import plus from '../../icons/plus.256.png';

// functions
import { APIPrefix } from '../../utils/functions';

// classes
import { ToolButtonType } from '../../tools/tool-button';
import { ToolButtonWidth } from '../../tools/tool-button';
import { ToolButtonColour } from '../../tools/tool-button';
import ToolButton from '../../tools/tool-button';
import SearchResultsFooter from '../../tools/search-results/search-results-footer';

// types
import { UserDataType } from './types';
import { GroupDataType } from './types';
import { UserManagementPage } from './types';

//	--------------------------------------------------------------------------
//
//	T Y P E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	P R O P E R T I E S
//
//	--------------------------------------------------------------------------

//	--------------------------------------------------------------------------
//
//	H T M L   C U S T O M   C O M P O N E N T S
//
//	--------------------------------------------------------------------------

function UserMembershipTable( props:	{
					addMode: boolean,
					withPaging?: boolean,
					page?: number,
					pageSize?: number,
					rows?: number
					} )
{

	// translation function
	const { t } = useTranslation();

	// class-level constants.
  	const TABLE_COL: CSS.Properties =
  			{
  			padding: '3px 5px 3px 5px',
  			whiteSpace: 'nowrap',
  			textAlign: 'left'
  			}

	// class-level constants.
  	const TABLE_COL_EXPANDING: CSS.Properties =
  			{
  			padding: '3px 5px 3px 5px',
  			whiteSpace: 'nowrap',
  			textAlign: 'left',
  			width: '100%'
  			}
	
	// membership data.
	const [sMembershipData, setMembershipData] = useState< GroupDataType[] >( [] );
							
	const [sColumns, setColumns] = useState< string[] >( [] );
	const [sLoadingComponent, setLoadingComponent] = useState< boolean >( true );

	//	------------------------------------------------------------
	//
	//	Only certain columns should be displayed.
	//
	//	------------------------------------------------------------
	
	function filterColumns( args:	{
					column: string
					} )
	{
	
		return (args.column !== 'status' || props.addMode === true) && args.column !== 'members' && args.column !== 'id' && args.column !== 'created' && args.column !== 'lastUpdate' &&
				(args.column !== 'memberSince' || props.addMode === false);
	
	} // filterColumns

	//	------------------------------------------------------------
	//
	//	Load the dataset when the component is rendered
	//
	//	------------------------------------------------------------

	async function loadData()
	{
	
		//try
		//{

		//	var urlCommand: string = APIPrefix() + '/v1/data_management/list_data?';
			
			// token.
		//	urlCommand = urlCommand +	'namespace=' + props.namespace;
		//	if (props.filename !== '')
		//		urlCommand = urlCommand + '&filename=' + props.filename;

		//	try
		//	{
				
		//		const apiResult = await fetch( urlCommand, {headers: {'Content-Type': 'application/json'}, credentials: 'include'} );
		//		if (apiResult.status === 200)
		//		{
				
		//			const returnedJson = await apiResult.json();

					// get data list.
		//			var dataList: { scope: string, name: string, did_type: string, bytes: number, length: number }[] = [];
		//			if (returnedJson.data_list !== undefined)
		//				dataList = returnedJson.data_list;
					
					// update the state with the list of returned data items.
		//			setDataList( dataList );
					
		//		}
				
				// if the return code is 401 then either the data-management token or the gateway-backend
				// token has expired. we should renew them.
		//		if (apiResult.status === 401)
		//			props.renewTokens( {} );
				
		//	}
		//	catch (e)
		//	{
		//		console.log( e );
		//	}
			
      		//}
      		//catch (e)
      		//{
		//	console.log(e);
		//}
		
		var memberships: GroupDataType[] = [];
		
		var numMemberships: number = Math.floor( Math.random() * 7 ) + 2;
		if (props.withPaging === true && props.pageSize !== undefined && props.page !== undefined && props.rows !== undefined)
		{
			numMemberships = props.pageSize;
			if ((props.page * props.pageSize) + props.pageSize > props.rows)
				numMemberships = props.rows - (props.page * props.pageSize);
		}
		
		for ( var i: number = 0; i < numMemberships; i++ )
		{
					
			var id: number = Math.floor( Math.random() * 1000000000 );		
			var groupName: string = '';
			var description: string = '';
			var length: number = Math.floor( Math.random() * 12 ) + 5;
			for ( var j = 0; j < length; j++ )
			{
				var newChar = Math.floor( Math.random() * 26 );
				groupName = groupName + String.fromCharCode( 97 + newChar );
			}
			var words: number = Math.floor( Math.random() * 8 ) + 3;
			for ( var word: number = 0; word < words; word++ )
			{
				length = Math.floor( Math.random() * 5 ) + 2;
				for ( var j = 0; j < length; j++ )
				{
					var newChar = Math.floor( Math.random() * 26 );
					description = description + String.fromCharCode( 97 + newChar );
				}
				if (word < words - 1)
					description = description + ' ';
			}
			const created: string = new Date( Date.now() + ((-365 + Math.floor( Math.random() * 365 )) * (24*60*60*1000)) ).toLocaleString();
			const lastUpdate: string = new Date( Date.now() + ((-365 + Math.floor( Math.random() * 365 )) * (24*60*60*1000)) ).toLocaleString();
			var memberSince: string = new Date( Date.now() + ((-365 + Math.floor( Math.random() * 365 )) * (24*60*60*1000)) ).toLocaleString();
			var status = 'active';
			var status_id: number = Math.floor( Math.random() * 5 );
			if (status_id === 0)
			{
				status = 'awaiting approval';
				memberSince = 'awaiting approval';
			}
			
			var membership: GroupDataType =	{
								id: id,
								groupName: groupName,
								description: description,
								created: created,
								lastUpdate: lastUpdate,
								memberSince: memberSince,
								members: '',
								status: status
								};
			memberships.push( membership );
							
		}

		//
		//	extract a list of columns, and store them in state. this column list will be used when
		//	formatting the data into a table.
		//	-------------------------------------------------
		//
		
		var columns: string[] = [];
		
		// loop over all the rows.
		if (Array.isArray( memberships ))
			memberships.forEach
			(
				(element) =>
				{
				
					// get a list of keys for this object.
					var newColumns: string[] = Object.keys( element );
					
					// add these items if they don't already exist. we need to do this as a loop because we can't trust
					// that every row will have the same list of columns in it.
					for ( var i = 0; i < newColumns.length; i++ )
						if (columns.findIndex( element => element === newColumns[ i ] ) == -1)
							columns.push( newColumns[ i ] );
					
				}
			);
		else
		
			// get a list of keys for this object.
			columns = Object.keys( memberships );
			
		// set state.
		setMembershipData( memberships );
		setColumns( columns );
		setLoadingComponent( false );
		
	} // loadData
	
	useEffect	( () =>
			{
			
				// run the query and load the data asynchronously.
				loadData();
			
			}, []
			)

	//	------------------------------------------------------------
	//
	//	C O M P O N E N T S
	//
	//	------------------------------------------------------------

	//	------------------------------------------------------------
	//
	//	A HTML component that contains the action buttons for each
	//	row.
	//
	//	------------------------------------------------------------

	function Actions( args: { tableRow: object, index: number } )
	{
	
		var awaitingApproval: boolean = false;
		if ('status' in args.tableRow)
			if (args.tableRow["status"] === 'awaiting approval')
				awaitingApproval = true;
		
		if (awaitingApproval === true)
			return	(
	  			<div key = {t("View")} style = {{ display: "flex", flexDirection: "row", alignItems: "center", margin: "0px 0px 0px 0px" }}>
		    	    		<ToolButton	key = {t("Cancel application")}
		    	    				name = "cancelApplication_"
		    	    				onClick = {undefined}
		    	    				text = {t("Cancel application")}
		    	    				icon = {cross}
		    	    				type = {ToolButtonType.SECONDARY}
		    	    				width = {ToolButtonWidth.WIDE}
		    	    				colour = {ToolButtonColour.AMBER} />
	  			</div>
				)
  				
  		else if (props.addMode === false)
	  		return	(
	  			<div key = {t("View")} style = {{ display: "flex", flexDirection: "row", alignItems: "center", margin: "0px 0px 0px 0px" }}>
		    	    		<ToolButton	key = {t("Remove membership")}
		    	    				name = "removeMembership_"
		    	    				onClick = {undefined}
		    	    				text = {t("Remove membership")}
		    	    				icon = {cross}
		    	    				type = {ToolButtonType.SECONDARY}
		    	    				width = {ToolButtonWidth.WIDE}
		    	    				colour = {ToolButtonColour.RED} />
	  			</div>
	  			)
	  			
	  	else
	  		return	(
	  			<div key = {t("View")} style = {{ display: "flex", flexDirection: "row", alignItems: "center", margin: "0px 0px 0px 0px" }}>
		    	    		<ToolButton	key = {t("Apply for membership")}
		    	    				name = "applyForMembership_"
		    	    				onClick = {undefined}
		    	    				text = {t("Apply for membership")}
		    	    				icon = {plus}
		    	    				type = {ToolButtonType.SECONDARY}
		    	    				width = {ToolButtonWidth.WIDE}
		    	    				colour = {ToolButtonColour.GREEN} />
	  			</div>
	  			)

	} // Actions

	//	------------------------------------------------------------
	//
	//	Constructs a list of columns.
	//
	//	------------------------------------------------------------
	
	function TableColumns( )
	{
	
		return sColumns.map
		(
			(column: string) =>
			{
				return <col key = {column}/>
			}
		)
		
	} // TableColumns

	//	------------------------------------------------------------
	//
	//	Constructs a table header row from a list of column names.
	//
	//	------------------------------------------------------------
	
	function TableHeadings()
	{
	
		return	<>
		{
			sColumns.filter( (column) => filterColumns( { column: column } ) ).map
			(
				(column: string) =>
				{
					return <th style = {TABLE_COL} data-hidden = "true" key = {column}>{column}</th>
				}
			)
		}
		</>
	
	} // TableHeadings

	//	------------------------------------------------------------
	//
	//	A HTML component that takes a list of sources and renders
	//	them as a list of table rows.
	//
	//	------------------------------------------------------------
	
	function TableRows( args:	{
					membershipData: GroupDataType[]
					} )
	{
		
		return	<>
		{
			args.membershipData.map
			(
				(item, rowIndex) =>
				(
					<tr	key = {'row_' + rowIndex.toString()}
						className = "search-results-table-row" >
						
						{
							sColumns.filter( (column) => filterColumns( { column: column } ) ).map
							(
								(column, colIndex) =>
								(
									<td 	key = {'cell_' + colIndex.toString()}
										style = {	(
												(column === 'memberSince' && props.addMode === false) || (column === 'status' && props.addMode === true)
												?	TABLE_COL_EXPANDING
												:	TABLE_COL
												) } >
										{
										column === 'status' && item[ column as keyof typeof item ] === 'active'
										?	<ToolButton	key = {t( "Active" )}
													name = "status_"
													onClick = {undefined}
													text = {t( "Active" )}
													icon = {tick}
													type = {ToolButtonType.STATUS_ACTIVE} />
										:	item[ column as keyof typeof item ]
										}
									</td>
								)
							)
						}
						<td style = {TABLE_COL}>
							{Actions( { tableRow: item, index: rowIndex } )}
						</td>
						
					</tr>
				)
			)
		}
		</>

	} // TableRows

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------

	return	(
	    		
		<div className = "search-results-table-holder">
			
			<table	className = {sLoadingComponent === true ? "search-results-table-hide" : "search-results-table"}
				style = {{ width: '100%' }} >
				<colgroup>
					{TableColumns()}
					<col key = "Actions_col"/>
				</colgroup>
				<thead>
					<tr className = "search-results-table-row-header">
						{TableHeadings()}
						<th style = {TABLE_COL}>Action</th>
					</tr>
				</thead>
				<tbody>
					{
						TableRows(	{
								membershipData: sMembershipData
								} )
					}
				</tbody>
			</table>
			
			<div className = {sLoadingComponent === true ? "search-results-table-loading" : "search-results-table-loaded"}>
				<img	className = "animated-gears"
					src = {gearsIcon}
					alt = ""
					width = "60"
					height = "60" />
				Loading
			</div>
			
		</div>
		
		)

} // UserMembershipTable

//	--------------------------------------------------------------------------
//
//	C L A S S   D E F I N I T I O N
//
//	--------------------------------------------------------------------------

export default function UserManagementUserEditor( props:	{
								pageDisplayed: UserManagementPage,
								setSetUserProfileEditingEventHandler: any,
								displayPage: any
								} )
{

	// translation function
	const { t } = useTranslation();
	
	// user data.
	const [sUserData, setUserData] = useState< UserDataType | undefined >( undefined );
	const [sEditingUserData, setEditingUserData] = useState< UserDataType >(	{
											id: -1,
											name: '',
											username: '',
											email: '',
											created: '',
											endTime: '',
											lastUpdate: '',
											memberSince: '',
											status: 'active'
											} );
					
	const [sEditing, setEditing] = useState< boolean >( false );
	const [sUserID, setUserID] = useState< number >( -1 );
	const [sLoadingComponent, setLoadingComponent] = useState< boolean >( true );
	
	const [sTotal, setTotal] = useState< number >( 29 );
	const [sPage, setPage] = useState< number >( 0 );
	const [sPages, setPages] = useState< number >( 3 );
	const [sPageSize, setPageSize] = useState< number >( 10 );

	//	------------------------------------------------------------
	//
	//	Handler for changes to the input boxes.
	//
	//	------------------------------------------------------------
  	
  	const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
  	{
  	
  		const inputBox: HTMLInputElement = event.target;
  		
  		// update the state for any input boxes that have been changed.
  		if (inputBox.name === "username")
  		{
	  		var userData: UserDataType | undefined = sEditingUserData;
  			if (userData !== undefined)
	  			userData.username = inputBox.value;
	  		else
	  			userData =	{
	  					id: -1,
	  					username: inputBox.value,
						email: '',
						name: '',
						created: '',
						endTime: '',
						lastUpdate: '',
						memberSince: '',
						status: 'active'
						};
  			setEditingUserData( userData );
  		}
  	
  	} // inputHandler

	//	------------------------------------------------------------
	//
	//	Load the dataset when the component is rendered
	//
	//	------------------------------------------------------------

	async function loadData( args:	{
						userID: number
						} )
	{
	
		//try
		//{

		//	var urlCommand: string = APIPrefix() + '/v1/data_management/list_data?';
			
			// token.
		//	urlCommand = urlCommand +	'namespace=' + props.namespace;
		//	if (props.filename !== '')
		//		urlCommand = urlCommand + '&filename=' + props.filename;

		//	try
		//	{
				
		//		const apiResult = await fetch( urlCommand, {headers: {'Content-Type': 'application/json'}, credentials: 'include'} );
		//		if (apiResult.status === 200)
		//		{
				
		//			const returnedJson = await apiResult.json();

					// get data list.
		//			var dataList: { scope: string, name: string, did_type: string, bytes: number, length: number }[] = [];
		//			if (returnedJson.data_list !== undefined)
		//				dataList = returnedJson.data_list;
					
					// update the state with the list of returned data items.
		//			setDataList( dataList );
					
		//		}
				
				// if the return code is 401 then either the data-management token or the gateway-backend
				// token has expired. we should renew them.
		//		if (apiResult.status === 401)
		//			props.renewTokens( {} );
				
		//	}
		//	catch (e)
		//	{
		//		console.log( e );
		//	}
			
      		//}
      		//catch (e)
      		//{
		//	console.log(e);
		//}
						
		var id: number = -1;	
		var username: string = '';
		var email: string = '';
		var name: string = '';
		var created: string = '';
		var endTime: string = '';
		var lastUpdate: string = '';
		
		if (args.userID > -1)
		{
		
			id = Math.floor( Math.random() * 1000000000 );
			var length: number = Math.floor( Math.random() * 5 ) + 3;
			for ( var i = 0; i < length; i++ )
			{
				var newChar = Math.floor( Math.random() * 26 );
				username = username + String.fromCharCode( 97 + newChar );
			}
			username = username + '-';
			length = Math.floor( Math.random() * 5 ) + 2;
			for ( var i = 0; i < length; i++ )
			{
				var newChar = Math.floor( Math.random() * 26 );
				username = username + String.fromCharCode( 97 + newChar );
			}
			length = Math.floor( Math.random() * 5 ) + 2;
			for ( var i = 0; i < length; i++ )
			{
				var newChar = Math.floor( Math.random() * 26 );
				email = email + String.fromCharCode( 97 + newChar );
			}
			email = email + '@';
			length = Math.floor( Math.random() * 5 ) + 2;
			for ( var i = 0; i < length; i++ )
			{
				var newChar = Math.floor( Math.random() * 26 );
				email = email + String.fromCharCode( 97 + newChar );
			}
			email = email + '.com';
			var newChar = Math.floor( Math.random() * 26 );
			name = name + String.fromCharCode( 65 + newChar ) + '. ';
			newChar = Math.floor( Math.random() * 26 );
			name = name + String.fromCharCode( 65 + newChar );
			length = Math.floor( Math.random() * 5 ) + 2;
			for ( var i = 0; i < length; i++ )
			{
				var newChar = Math.floor( Math.random() * 26 );
				name = name + String.fromCharCode( 97 + newChar );
			}
			created = new Date( Date.now() + ((-365 + Math.floor( Math.random() * 365 )) * (24*60*60*1000)) ).toLocaleString();
			endTime = new Date( Date.now() + ((Math.floor( Math.random() * 365 )) * (24*60*60*1000)) ).toLocaleString();
			lastUpdate = new Date( Date.now() + ((-365 + Math.floor( Math.random() * 365 )) * (24*60*60*1000)) ).toLocaleString();
		
		}
		
		var user: UserDataType =	{
						id: id,
						username: username,
						email: email,
						name: name,
						created: created,
						endTime: endTime,
						lastUpdate: lastUpdate,
						memberSince: '',
						status: 'active'
						};
			
		// set state.
		setUserData( user );
		setLoadingComponent( false );
		
	} // loadData

	//	------------------------------------------------------------
	//
	//	Handler for select box and check box onChange event.
	//
	//	------------------------------------------------------------
	
	const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) =>
	{

		const inputBox = event.target;
		
		// update the selected value of the status listbox.
		if (inputBox.name === "status")
  		{
	  		var userData: UserDataType | undefined = sEditingUserData;
  			if (userData !== undefined)
	  			userData.status = inputBox.value;
	  		else
	  			userData =	{
	  					id: -1,
	  					username: '',
						email: '',
						name: '',
						created: '',
						endTime: '',
						lastUpdate: '',
						memberSince: '',
						status: inputBox.value
						};
  			setEditingUserData( userData );
  		}
				
	} // onChangeHandler

	//	------------------------------------------------------------
	//
	//	Handler for onClick events (buttons).
	//
	//	------------------------------------------------------------
  	
  	const onClickHandlerBtn = (event: React.MouseEvent<HTMLButtonElement>) =>
  	{
  	
  		const btn: HTMLButtonElement = event.currentTarget;

  		// if we've clicked on a button to download data.
  		if (btn.name.length > 16)
  			if (btn.name.slice( 0, 16 ) === "btnDownloadData_")
  			{
  			
  				// get the rest of the identifier, and convert to numeric.
				var rowID: string = btn.name.slice( 16 - btn.id.length );
  				let rowIDNumeric: number = -1;
  				try
  				{
  					rowIDNumeric = Number( rowID );
  				}
  				catch (e)
  				{
  				}
  				
  				// do something.......
  			
  			}
  		
  		if (btn.name === 'addGroups')
  		{
  			
  			props.displayPage(	{
  						displayPage: UserManagementPage.AddUserGroups,
  						userID: sUserID,
  						editing: (sEditing === true)
  						} );
  			
  		}
  		
  		if (btn.name === 'cancelUser')
  		{
  		
  			setEditing( false );
  			
  		}
  			
  		if (btn.name === 'editUser')
  		{
  			if (sUserData !== undefined)
	  			setEditingUserData( sUserData );
	  		else
	  			setEditingUserData(	{
	  						id: -1,
	  						name: '',
	  						username: '',
	  						email: '',
	  						created: '',
	  						endTime: '',
	  						lastUpdate: '',
	  						memberSince: '',
	  						status: 'active'
	  						} );
  			setEditing( true );
  		}
  			
  		if (btn.name === 'saveUser')
  			setEditing( false );
  			
  	} // onClickHandlerBtn

	//	-------------------------------------------------
	//
	//	function that handles a change in the desired page size
	//
	//	-------------------------------------------------
  	
	const pageSizeOnChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) =>
  	{

		var newPageSize: number = sPageSize;
		try
		{
			newPageSize = parseInt( event.target.value );
		}
		catch
		{
		}
		
		// calculate the row indexe of the first row.
		var firstRow: number = (sPageSize * sPage);
		
		// calculate the new page numbers, and update the state.
		var currentPage: number = sPage;
		var pages: number = sPages;
		currentPage = Math.floor( firstRow / newPageSize );
		pages = Math.ceil( sTotal / newPageSize );
		setPage( currentPage );
		setPages( pages );
  	
  		console.log( "page size changed to " + newPageSize.toString() );
  		
  		// update state with the selected value.
  		setPageSize( newPageSize );
  	
  	} // pageSizeOnChangeHandler

	//	------------------------------------------------------------
	//
	//	Handler for an event that sets the editing status and
	//	user ID of the page.
	//
	//	------------------------------------------------------------
  	
  	function setUserProfileEditingEvent( args:	{
  							editing: boolean,
  							userID: number
  							} )
  	{
  	
  		// load data.
  		if (args.userID > -1)
  			loadData( { userID: args.userID } );
  			
  		// set the editing status.
  		setUserID( args.userID );
  		setEditing( args.editing );
  	
  	} // setUserProfileEditingEvent

	//	------------------------------------------------------------
	//
	//	Handles an update page event from the results footer.
	//
	//	------------------------------------------------------------
  	
  	const updatePage = function( args:	{
  						page: number
	  					} )
  	{
  	
  		setPage( args.page );
  	
  	} // updatePage
	
	useEffect	( () =>
			{
  			
  				// set the event handler that updates the editing status.
  				if (props.setSetUserProfileEditingEventHandler !== undefined)
  					props.setSetUserProfileEditingEventHandler( setUserProfileEditingEvent );
			
			}, []
			)

	//	------------------------------------------------------------
	//
	//	C O M P O N E N T S
	//
	//	------------------------------------------------------------

	//	------------------------------------------------------------
	//
	//	Component code
	//
	//	------------------------------------------------------------

	return	(
	    		
	
		<div style = {{ flex: '1 1', width: '100%', display: (props.pageDisplayed === UserManagementPage.UserEditor || props.pageDisplayed === UserManagementPage.AddUserGroups ? 'flex' : 'none'), flexDirection: 'row' }}>
	
	    		<div	style = {{ flex: '0 0', display: 'flex', flexDirection: 'column', height: '100%' }}>
			
				{/*
				//
				//	user details box.
				//
				*/}
				<div	className = "user-group-details"
					style = {{ flex: '0 0', width: '300px', display: 'flex', flexDirection: 'row', alignItems: 'left',
							borderRadius: '10px', alignSelf: 'flex-start' }}
					data-maximised = "T" >
					
					<div style = {{ flex: '0 0 15px' }} />
					
					<div style = {{ flex: '1 1', display: 'flex', flexDirection: 'column' }} >
				
				    	    	<div className = "flex-15px"></div>
				    	    	<div className = "flex-row" style = {{ alignItems: 'center' }} >
				    	    		<div style = {{ flex: '0 0 60px', width: '60px', height: '60px', borderRadius: '30px', backgroundColor: '#e70068' }} />
				    	    		<div style = {{ flex: '0 0 15px' }} />
					    	    	<div	className = "label"
					    	    		style = {{ flex: '1 1 auto', display: sEditing === true ? 'none' : 'flex' }}
					    	    		data-large-font = "Y" >
					    	    		{sUserData !== undefined ? sUserData.name : ''}
					    	    	</div>
					    	    	<div	style = {{ flex: '1 1 auto', display: sEditing === true ? 'flex' : 'none', flexDirection: 'column' }} >
								<input	name = "name"
									type = "text"
									className = "inputBox"
									placeholder = {t("Name")}
									onChange = {inputHandler}
									maxLength = {60}
						    	    		data-large-font = "Y"
						    	    		value = {sEditingUserData.name} />
					    	    	</div>
			    			</div>
				    	    	<div className = "flex-15px"></div>
				    	    	
				    	    	{/* username */}
				    	    	<div className = "label" data-large-font = "Y" >Username</div>
				    	    	<div className = "flex-15px"></div>
				    	    	<div style = {{ flex: '0 0 7px', display: sEditing === true ? 'none' : 'flex' }} />
				    	    	<div	className = "label"
				    	    		style = {{ display: sEditing === true ? 'none' : 'flex' }} >
				    	    		{sUserData !== undefined ? sUserData.username : ''}
				    	    	</div>
				    	    	<div style = {{ flex: '0 0 7px', display: sEditing === true ? 'none' : 'flex' }} />
						<input	name = "username"
							type = "text"
							className = "inputBox"
							style = {{ display: sEditing === true ? 'flex' : 'none' }}
							placeholder = {t("Username")}
							onChange = {inputHandler}
							maxLength = {32}
							value = {sEditingUserData.username} />
				    	    	<div className = "flex-15px"></div>
				    	    	
				    	    	{/* email address */}
				    	    	<div className = "label" data-large-font = "Y" >Email</div>
				    	    	<div className = "flex-15px"></div>
				    	    	<div style = {{ flex: '0 0 7px', display: sEditing === true ? 'none' : 'flex' }} />
				    	    	<div	className = "label"
				    	    		style = {{ display: sEditing === true ? 'none' : 'flex' }} >{sUserData !== undefined ? sUserData.email : ''}</div>
				    	    	<div style = {{ flex: '0 0 7px', display: sEditing === true ? 'none' : 'flex' }} />
						<input	name = "emailAddress"
							type = "text"
							className = "inputBox"
							style = {{ display: sEditing === true ? 'flex' : 'none' }}
							placeholder = {t("Email")}
							onChange = {inputHandler}
							maxLength = {128}
							value = {sEditingUserData.email} />
				    	    	<div className = "flex-15px"></div>
				    	    	
				    	    	{/* status */}
				    	    	<div className = "label" data-large-font = "Y" >Status</div>
				    	    	<div className = "flex-15px"></div>
			    			<div	style = {{ flex: '0 0 auto', display: sEditing === true ? 'none' : 'flex', flexDirection: 'row' }} >
				    	    		<ToolButton	key = {t("Active")}
				    	    				name = "status"
				    	    				onClick = {undefined}
				    	    				text = {t("Active")}
				    	    				icon = {tick}
				    	    				type = {ToolButtonType.STATUS_ACTIVE} />
				    	    	</div>
					    	<select	name = "status"
					    			className = "listbox"
								style = {{ display: sEditing === true ? 'flex' : 'none' }}
					    			multiple = {false}
					    			onChange = {onChangeHandler}
					    			value = {sEditingUserData.status}
					    			data-placeholder-shown = "F">
					    		<option label = {t("Active")} value = "active" > Active </option>
					    		<option label = {t("Inactive")} value = "inactive" > Inactive </option>
						</select> 
				    	    	<div style = {{ flex: '0 0 30px' }}></div>
						
			    			{/*
			    			//
			    			//	buttons
			    			//
			    			*/}
			    			<div	style = {{ flex: '0 0 auto', display: sEditing === true ? 'none' : 'flex', flexDirection: 'row' }} >
				    	    		<ToolButton	key = {t("Edit user")}
				    	    				name = "editUser"
				    	    				onClick = {onClickHandlerBtn}
				    	    				text = {t("Edit user")}
				    	    				icon = {pencil}
				    	    				type = {ToolButtonType.SECONDARY} />
			    	    		</div>
			    	    		<div	style = {{ flex: '0 0 auto', display: sEditing === true ? 'flex' : 'none', flexDirection: 'row' }} >
				    	    		<ToolButton	key = {t("Save")}
				    	    				name = "saveUser"
				    	    				onClick = {onClickHandlerBtn}
				    	    				text = {t("Save")}
				    	    				icon = {tick}
				    	    				type = {ToolButtonType.SECONDARY} />
				    	    		<div style = {{ flex: '1 1' }} />
				    	    		<ToolButton	key = {t("Cancel")}
				    	    				name = "cancelUser"
				    	    				onClick = {onClickHandlerBtn}
				    	    				text = {t("Cancel")}
				    	    				icon = {cross}
				    	    				type = {ToolButtonType.SECONDARY} />
			    	    		</div>
				    	    	<div className = "flex-15px"></div>
			    	    	
			    	    	</div>
					
					<div style = {{ flex: '0 0 15px' }} />
			    		
				</div>
		    	
		    	</div>
			<div className = "transparent-vertical-separator"></div>
					
			{/*
			///	Display a list of user memberships for this current user.
			*/}
			<div className = "user-management-table" style = {{ display: props.pageDisplayed === UserManagementPage.UserEditor ? 'flex' : 'none', flexDirection: 'row' }} >
			
				<div style = {{ flex: '0 0 15px' }} />
				
				<div style = {{ flex: '1 1', display: 'flex', flexDirection: 'column' }} >
				
					<div style = {{ flex: '0 0 15px' }} />
					
					<div style = {{ display: 'flex', flexDirection: 'row', flex: '0 0 auto' }} >
				    	    	<div className = "label" data-large-font = "Y" >{t( 'User memberships' )}:</div>
				    	    	<div style = {{ flex: '1 1' }} />
				    	    	<div style = {{ flex: '0 0 auto' }}>
				    	    		<ToolButton	key = {t("Add user to group(s)")}
				    	    				name = "addGroups"
				    	    				onClick = {onClickHandlerBtn}
				    	    				text = {t("Add user to group(s)")}
				    	    				icon = {plus}
				    	    				type = {ToolButtonType.SECONDARY}
				    	    				width = {ToolButtonWidth.WIDE}
				    	    				colour = {ToolButtonColour.GREEN} />
				    	    	</div>
					</div>
				
					<div style = {{ flex: '0 0 15px' }} />
					
					<div style = {{ flex: '1 1' }} >
			
						{/*
						//
						//	run a membership query for this user, and display the results in a table.
						//
						*/}
						<UserMembershipTable	key = 'a'
									addMode = {false} />
					
					</div>
				
					<div style = {{ flex: '0 0 15px' }} />
				
				</div>
			
				<div style = {{ flex: '0 0 15px' }} />
			
			</div>
					
			{/*
			///	Add one or more groups to the user's group memberships.
			*/}
			<div className = "user-management-table" style = {{ display: props.pageDisplayed === UserManagementPage.AddUserGroups ? 'flex' : 'none', flexDirection: 'row' }} >
			
				<div style = {{ flex: '0 0 15px' }} />
				
				<div style = {{ flex: '1 1', display: 'flex', flexDirection: 'column' }} >
				
					<div style = {{ flex: '0 0 15px' }} />
	    			
			    		<div style = {{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
				    	
						{/* and then we have the results-per-page dropdown */}
						<div	key = {sPageSize.toString()}
							className = "search-results-per-page">{t("Results per page:")}</div>
							
						<select	className = "search-results-per-page-dropdown"
								name = "searchResultsPerPage"
								onChange = {pageSizeOnChangeHandler} >
							<option value = "10">10</option>
							<option value = "25">25</option>
							<option value = "50">50</option>
						</select>
						
						<div style = {{ flex: '0 0 10px' }} />
					
					</div>
					
					<div style = {{ flex: '1 1', display: 'flex', flexDirection: 'column' }} >
			
						{/*
						//
						//	run a membership query for this user, and display the results in a table.
						//
						*/}
						<div	className = "search-results-table-holder-visible" >
							<UserMembershipTable	key = {sPage.toString() + sPages.toString() + sTotal.toString() + sPageSize.toString()}
										addMode = {true}
										withPaging = {true}
										page = {sPage}
										pageSize = {sPageSize}
										rows = {sTotal} />
						</div>
					
					</div>
		
					{/* the search-results footer contains the navigation buttons for moving between results pages */}
					<SearchResultsFooter	key = {sPages.toString() + sTotal.toString() + sPageSize.toString()}
								page = {sPage}
								pageSize = {sPageSize}
								pages = {sPages}
								rows = {sTotal}
								updatePage = {updatePage} />
				
					<div style = {{ flex: '0 0 15px' }} />
				
				</div>
			
				<div style = {{ flex: '0 0 15px' }} />
			
			</div>
		
		</div>
		
		)

} // UserManagementUserEditor
